import axios from 'axios'
import { Dispatch } from 'redux'
import { RootState } from '../reducers'
import * as request from './../lib/request'
import { NotificationBarVariant } from './../components/Elements'
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION'
interface ShowNotificationAction {
  type: typeof SHOW_NOTIFICATION
  notification: {
    message: string
    type: NotificationBarVariant
  }
}
interface CloseNotificationAction {
  type: typeof CLOSE_NOTIFICATION
}
export type AppActionTypes = ShowNotificationAction | CloseNotificationAction
export const showNotification =
  (message: string, type: NotificationBarVariant) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: SHOW_NOTIFICATION, notification: { message, type } })
  }
export const closeNotification = () => async (dispatch: Dispatch) => {
  dispatch({ type: CLOSE_NOTIFICATION })
}
