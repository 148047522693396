import {
  DealActionTypes,
  DEAL_REQUESTED,
  DEAL_ERROR,
  DEAL_RECEIVED,
  DEALS_REQUESTED,
  DEALS_RECEIVED,
  DEAL_UPDATE_REQUESTED,
  DEAL_UPDATED,
  DEALS_ERROR,
  DealArrayItem,
  DEAL_CHANGELOG_REQUESTED,
  DealChangeLog,
  DEAL_CHANGELOG_RECEIVED,
} from '../actions/deal'
interface DealState {
  deals: DealArrayItem[]
  prepaidRoyalties: number
  isFetching: boolean
  error: string | null
  changeLog: DealChangeLog[]
}
const initialState: DealState = {
  deals: [],
  prepaidRoyalties: 0,
  isFetching: false,
  changeLog: [],
  error: null,
}
export default function (
  state: DealState = initialState,
  action: DealActionTypes
): DealState {
  switch (action.type) {
    case DEAL_REQUESTED:
    case DEALS_REQUESTED:
    case DEAL_UPDATE_REQUESTED:
    case DEAL_CHANGELOG_REQUESTED:
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    case DEALS_RECEIVED:
      return {
        ...state,
        deals: action.deals,
        isFetching: false,
        error: null,
      }
    case DEAL_CHANGELOG_RECEIVED:
      return {
        ...state,
        changeLog: action.changeLog,
        isFetching: false,
        error: null,
      }
    case DEAL_RECEIVED:
      return {
        ...state,
        isFetching: false,
        prepaidRoyalties: action.prepaidRoyalties,
        error: null,
      }
    case DEAL_UPDATED:
      return {
        ...state,
        isFetching: false,
        deals: state.deals.map((deal) => {
          if (deal.dealId === action.deal.dealId) {
            return action.deal
          } else {
            return deal
          }
        }),
        error: null,
      }
    case DEAL_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case DEALS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    default:
      return state
  }
}
