import React, { useState, useEffect } from 'react'
import { RootState } from '../reducers'
import { useSelector, useDispatch } from 'react-redux'
import { fetchUserSelf } from '../actions/user'
import { Redirect } from 'react-router-dom'
import { closeNotification, showNotification } from '../actions/app'
import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  TableBody,
  Button,
  Grid,
  CircularProgress,
} from '@material-ui/core'
import {
  DealArrayItem,
  getAllDealPrepaids,
  updateDealObject,
  updateDealPrepaidRoyalties,
} from '../actions/deal'
import { StyledText } from './Elements'
import PrepaidEditModal, { PrepaidEditObject } from './EditModal'

interface SearchParams {
  organizationName: string
  dealId: string
}

const PrepaidManagement: React.FC = () => {
  const dispatch = useDispatch()
  const [selectedDeal, setSelectedDeal] = useState<DealArrayItem | null>(null)
  const [searchParams, setSearchParams] = useState<SearchParams>({
    organizationName: '',
    dealId: '',
  })
  const [filteredDeals, setFilteredDeals] = useState<DealArrayItem[]>([])
  const error = useSelector((state: RootState) => state.deals.error)
  const deals = useSelector((state: RootState) => state.deals.deals)
  const isFetching = useSelector((state: RootState) => state.deals.isFetching)

  useEffect(() => {
    dispatch(fetchUserSelf())
    dispatch(getAllDealPrepaids())
  }, [dispatch])

  const accessLevel = useSelector((state: RootState) => state.auth.userRole)

  useEffect(() => {
    if (error) {
      dispatch(showNotification(error, 'error'))
    } else {
      dispatch(closeNotification())
    }
  }, [error])

  useEffect(() => {
    const { organizationName, dealId } = searchParams
    const filteredData = deals.filter((deal) => {
      if (organizationName && dealId) {
        return (
          deal.organizationName
            .toLowerCase()
            .includes(organizationName.toLowerCase()) &&
          deal.dealId.toString().startsWith(dealId)
        )
      } else if (organizationName) {
        return deal.organizationName
          .toLowerCase()
          .includes(organizationName.toLowerCase())
      } else if (dealId) {
        return deal.dealId.toString().startsWith(dealId)
      }

      // Return all if no arguments
      return true
    })

    setFilteredDeals(filteredData)
  }, [deals, searchParams])

  if (accessLevel !== 'admin') {
    return <Redirect to="/forms" />
  }

  const startEditMode = (deal: DealArrayItem) => {
    setSelectedDeal(deal)
  }

  const handleModalClose = () => {
    setSelectedDeal(null)
  }

  const handleUpdate = async (
    dealEditObject: PrepaidEditObject,
    dealId: number
  ) => {
    const { prepaidRoyalties, comment, invoiceNumber } = dealEditObject

    if (!prepaidRoyalties || prepaidRoyalties <= 0) {
      dispatch(
        showNotification(
          'Prepaid royalties amount must be greater than 0 and must not be empty',
          'error'
        )
      )
      return
    }

    const deal = deals.find((deal) => deal.dealId === dealId)
    let newPrepaidsValue: number

    if (deal?.prepaidRoyalties) {
      newPrepaidsValue =
        Number(deal.prepaidRoyalties) + Number(prepaidRoyalties)
    } else {
      newPrepaidsValue = prepaidRoyalties
    }

    const updateObject: updateDealObject = {
      dealId: dealId,
      prepaidRoyalties: newPrepaidsValue,
      comment: comment,
      invoiceNumber: invoiceNumber,
    }

    dispatch(updateDealPrepaidRoyalties(updateObject))
    setSelectedDeal(null)
    dispatch(
      showNotification(
        `Deal's ${dealId} prepaid royalties updated successfully`,
        'success'
      )
    )
    const closeTimeout = setTimeout(() => {
      dispatch(closeNotification())
      clearTimeout(closeTimeout)
    }, 2000)
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target

    setSearchParams((prevState) => {
      return {
        ...prevState,
        [name]: name === 'dealId' ? +value : value,
      }
    })
  }

  return (
    <div
      style={{
        padding: '1.5rem',
      }}
    >
      <StyledText
        variant="headline3"
        color="primary"
        style={{ fontSize: 24, marginTop: '1rem' }}
      >
        Prepaid royalties management
      </StyledText>
      {isFetching && (
        <Grid item style={{ height: '100%' }}>
          <Grid container justify="center" alignContent="center">
            <CircularProgress
              color="primary"
              style={{
                width: '100px',
                height: '100px',
                marginTop: '300px',
                alignSelf: 'center',
              }}
            />
          </Grid>
        </Grid>
      )}
      {!isFetching && (
        <div
          style={{
            marginTop: '2rem',
          }}
        >
          <TableContainer component={Paper} style={{ width: '100%' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div
                      style={{
                        display: 'flex',
                        gap: '1rem',
                      }}
                    >
                      Deal ID
                      <input
                        type="text"
                        placeholder="Search by ID"
                        name="dealId"
                        onChange={handleSearch}
                      />
                    </div>
                  </TableCell>
                  <TableCell>Deal name</TableCell>
                  <TableCell>Prepaid royalties</TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: 'flex',
                        gap: '1rem',
                      }}
                    >
                      Organization
                      <input
                        type="text"
                        placeholder="Search by organization"
                        name="organizationName"
                        onChange={handleSearch}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDeals && filteredDeals.length ? (
                  filteredDeals.map((deal) => (
                    <TableRow
                      key={deal.dealId}
                      onClick={() => startEditMode(deal)}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <TableCell>{deal.dealId}</TableCell>
                      <TableCell>{deal.dealName || '-'}</TableCell>
                      <TableCell>{deal.prepaidRoyalties || '-'}</TableCell>
                      <TableCell>{deal.organizationName}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>No results</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      {selectedDeal && (
        <PrepaidEditModal
          dealData={selectedDeal}
          onClose={handleModalClose}
          handleSubmit={handleUpdate}
        />
      )}
    </div>
  )
}

export default PrepaidManagement
