import axios from 'axios'
// package.proxy doesn't affect axios / fetch so when using react-dev-server
// backend API URL needs to be defined by .env
const DEV_BACKEND_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_BACKEND_URL
    : ''
if (process.env.NODE_ENV === 'development' && !DEV_BACKEND_URL) {
  throw new Error(
    'In development enviroment you need to define REACT_APP_DEV_BACKEND_URL in /frontend/.env'
  )
}
export async function post(
  path: string,
  body?: any,
  token?: string | undefined
) {
  try {
    const res = await axios.post(
      DEV_BACKEND_URL + path,
      body,
      token
        ? {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : undefined
    )
    if (!res) {
      throw new Error('No response from backend server')
    }
    const { data } = res
    return data
  } catch (error) {
    // error.response.data contains body of response when receiving http error code
    // error.message contains "Network error" when backend is unreachable
    return {
      success: false,
      error:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    }
  }
}
export async function get(path: string, token?: string | undefined) {
  try {
    const res = await axios.get(
      DEV_BACKEND_URL + path,
      token
        ? {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : undefined
    )
    if (!res) {
      throw new Error('No response from backend server')
    }
    const { data } = res
    return data
  } catch (error) {
    // error.response.data contains body of response when receiving http error code
    // error.message contains "Network error" when backend is unreachable
    return {
      success: false,
      error:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    }
  }
}
