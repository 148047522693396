import { Dispatch } from 'redux'
import * as request from './../lib/request'
import { RootState } from '../reducers'
export const USER_REQUESTED = 'USER_REQUESTED'
export const USER_RECEIVED = 'USER_RECEIVED'
export const USER_ERROR = 'USER_ERROR'
interface UserRequestedAction {
  type: typeof USER_REQUESTED
}
interface UserReceivedAction {
  type: typeof USER_RECEIVED
  email: string
  role: string
}
interface UserErrorAction {
  type: typeof USER_ERROR
  error: string
}
export const fetchUserSelf =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({ type: USER_REQUESTED })
    try {
      const { success, data, error } = await request.get(
        '/api/user/self',
        getState().auth.token
      )
      if (!success) {
        throw new Error(error)
      }
      dispatch({
        type: USER_RECEIVED,
        email: data.email,
        role: data.role,
      })
    } catch (err) {
      dispatch({ type: USER_ERROR, error: err.message })
    }
  }
export const sendPasswordResetEmail =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({ type: USER_REQUESTED })
    try {
      const { success, data, error } = await request.get(
        '/api/user/self',
        getState().auth.token
      )
      if (!success) {
        throw new Error(error)
      }
      dispatch({
        type: USER_RECEIVED,
        email: data.email,
        role: data.role,
      })
    } catch (err) {
      dispatch({ type: USER_ERROR, error: err.message })
    }
  }
export type UserActionTypes =
  | UserRequestedAction
  | UserReceivedAction
  | UserErrorAction
