import { combineReducers } from 'redux'
import authReducer from './auth'
import userReducer from './user'
import reportsReducer from './reports'
import settingsReducer from './settings'
import appReducer from './app'
import dealReducer from './deals'
const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  reports: reportsReducer,
  settings: settingsReducer,
  app: appReducer,
  deals: dealReducer,
})
export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
