import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from './../reducers'
import {
  Container,
  Grid,
  Typography,
  makeStyles,
  AppBar,
} from '@material-ui/core'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom'
import Login from './../components/Login'
import Forgot from './../components/Forgot'
import ResetPassword from '../components/ResetPassword'
import RoyaltyReportForm from '../components/RoyaltyReportForm'
import PrivateRoute from './../PrivateRoute'
import MainAppBar from './../components/MainAppBar'
import ReportOverview from '../components/ReportOverview'
import { defaultTheme, ThemeContext } from './../wirepas_theme'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { NotificationBar } from '../components/Elements'
import { closeNotification } from '../actions/app'
import PrepaidManagement from '../components/PrepaidManagement'
const useStyles = makeStyles((theme) => ({}))
interface AppProps {}
const App: React.SFC<{ style?: CSSProperties }> = (props) => {
  const token = useSelector((state: RootState) => state.auth.token)
  const role = useSelector((state: RootState) => state.auth.userRole)
  /*const showNotification = useSelector((state : RootState) => state.app.showNotification)
  const notificationMessage = useSelector((state : RootState) => state.app.notificationMessage)
  const notificationType = useSelector((state : RootState) => state.app.notificationType)
  const dispatch = useDispatch()
  const handleCloseNotification = () => {
    dispatch(closeNotification())
  }
  const [notificationPositionRelative, setNotificationPositionRelative]  = useState<boolean>(true)
  useEffect(()=>{
    const handleScroll = () => {
      const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
      console.log(winScroll)
      if(winScroll > 65) {
        setNotificationPositionRelative(false)
      } else {
        setNotificationPositionRelative(true)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])*/
  return (
    <ThemeContext.Provider value={defaultTheme}>
      <div style={props.style}>
        <Router>
          {token && <div style={{ height: '5rem' }}></div>}
          {token && <MainAppBar />}
          <Switch>
            <Route exact path="/login">
              {token ? <Redirect to="/forms" /> : <Login />}
            </Route>
          </Switch>
          <Switch>
            <Route exact path="/reset/:token" component={ResetPassword} />
            <Route exact path="/forgot">
              {token ? <Redirect to="/forms" /> : <Forgot />}
            </Route>

            <PrivateRoute
              path="/forms/:dealId/:year/:quarter"
              component={RoyaltyReportForm}
            />
            <PrivateRoute exact path="/forms" component={ReportOverview} />
            <PrivateRoute
              path="/prepaid_management"
              component={PrepaidManagement}
            />
            <Redirect to="/login" />
          </Switch>
        </Router>
      </div>
    </ThemeContext.Provider>
  )
}
export default App
