import React, { useState } from 'react'
import { StyledText, ReportStatus } from './Elements'
import { Button, Grid, makeStyles } from '@material-ui/core'
import tr from './../translations'
import { useSelector } from 'react-redux'
import { RootState } from '../reducers'
import ChangeLogModal from './ChangeLogModal'
import { DealChangeLog } from '../actions/deal'
const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(3),
  },
}))
interface RoyaltyFormHeaderAdminProps {
  organization: string
  dealTitle: string
  reportReminderCount: any
  dealContactName: string
  dealContactEmail: string
  reportingStatus: string
  year: number
  quarter: number
  changeLog: Array<DealChangeLog>
}
export default function RoyaltyFormHeaderUser(
  props: RoyaltyFormHeaderAdminProps
) {
  const {
    changeLog,
    organization,
    dealTitle,
    reportReminderCount,
    dealContactName,
    dealContactEmail,
    reportingStatus,
    year,
    quarter,
  } = props
  const supportContactPhone = useSelector(
    (state: RootState) => state.settings.supportContactPhone
  )
  const supportContactEmail = useSelector(
    (state: RootState) => state.settings.supportContactEmail
  )
  const [isLogOpen, setIsLogOpen] = useState<boolean>(false)
  const classes = useStyles()
  const openChangelog = () => {
    setIsLogOpen(true)
  }
  const closeChangelog = () => {
    isLogOpen && setIsLogOpen(false)
  }
  const changeLogVisible = changeLog && changeLog.length ? true : false
  return (
    <Grid container direction="column">
      <Grid item className={classes.header}>
        <StyledText
          variant="headline3"
          color="ongrey"
          style={{ fontSize: '1.5rem' }}
        >
          {tr('_Royalty_report_for')} {year}/Q{quarter}
        </StyledText>
      </Grid>
      <Grid item className={classes.header}>
        <StyledText variant="body" color="ongrey">
          Dear Customer, <br />
          please note, the Wirepas Royalty Reporting Tool should only be used on
          a PC and not on a mobile device.
        </StyledText>
      </Grid>
      <Grid item className={classes.header}>
        <StyledText variant="body" color="ongrey">
          Please fill the number of units programmed in the cells below by
          selecting your royalty type from the dropdown field on the left. The
          last three months are reported under “Actuals” and will be invoiced as
          per your contract.
        </StyledText>
      </Grid>
      <Grid item className={classes.header}>
        <StyledText variant="body" color="ongrey">
          Under “Forecast” please provide your best estimate of the future units
          to be programmed, these numbers are non-binding.
        </StyledText>
      </Grid>
      <Grid item className={classes.header}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {/* Contact person details */}
          <Grid container direction="column">
            {[
              ['Organization name:', organization],
              ['Contact name:', dealContactName],
              ['Contact email:', dealContactEmail],
              [
                <React.Fragment>
                  <div style={{ height: '1rem' }} />
                  Report status
                </React.Fragment>,
                <React.Fragment>
                  <div style={{ height: '1rem' }} />
                  <ReportStatus chip={true} status={reportingStatus} />
                </React.Fragment>,
              ],
              changeLogVisible
                ? [
                    <React.Fragment>
                      <div style={{ height: '1rem' }} />
                      Prepaid Royalty Changelog
                    </React.Fragment>,
                    <React.Fragment>
                      <div style={{ height: '1rem' }} />
                      <Button onClick={openChangelog}>
                        <StyledText variant="button">Open changelog</StyledText>
                      </Button>
                    </React.Fragment>,
                  ]
                : [],
            ].map((field) => {
              return (
                <Grid item>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      style={{
                        padding: '0.05rem',
                        width: '175px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <StyledText variant="body" color="ongrey">
                        {field[0]}
                      </StyledText>
                    </div>
                    <div
                      style={{
                        padding: '0.05rem',
                        marginLeft: '50px',
                        width: '175px',
                      }}
                    >
                      <StyledText variant="body" color="ongrey">
                        {field[1]}
                      </StyledText>
                    </div>
                  </div>
                </Grid>
              )
            })}
          </Grid>
          {/* Support person details */}
          <Grid container direction="column">
            <StyledText variant="body" color="ongrey">
              If encountering a problem please contact us:
            </StyledText>
            <div style={{ height: '1rem' }} />
            {[
              ['Contact email:', supportContactEmail],
              ['Contact phone:', supportContactPhone],
            ].map((field) => {
              return (
                <Grid item>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      style={{
                        padding: '0.05rem',
                        width: '175px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <StyledText variant="body" color="ongrey">
                        {field[0]}
                      </StyledText>
                    </div>
                    <div
                      style={{
                        padding: '0.05rem',
                        marginLeft: '50px',
                        width: '175px',
                      }}
                    >
                      <StyledText variant="body" color="ongrey">
                        {field[1]}
                      </StyledText>
                    </div>
                  </div>
                </Grid>
              )
            })}
          </Grid>
        </div>
      </Grid>
      <Grid item style={{ height: '1rem' }} />
      {isLogOpen && <ChangeLogModal onClose={closeChangelog} />}
    </Grid>
  )
}
