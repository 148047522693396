const LOG_LEVEL_DEBUG = 2
const LOG_LEVEL_INFO = 1
const LOG_LEVEL_ERROR = 0
const logLevelMap: any = {
  error: LOG_LEVEL_ERROR,
  info: LOG_LEVEL_INFO,
  debug: LOG_LEVEL_DEBUG,
}
const LOG_LEVEL =
  process.env.NODE_ENV === 'production' ? LOG_LEVEL_ERROR : LOG_LEVEL_DEBUG
class _Logger {
  debug(...args: any[]): void {
    if (LOG_LEVEL >= LOG_LEVEL_DEBUG) {
      console.log(...args)
    }
  }
}
export const Logger = new _Logger()
