import React from 'react'
interface WirepasLogoProps {
  fill: string
  width?: string
  height?: string
}
const WirepasLogo: React.SFC<WirepasLogoProps> = (props) => {
  const { fill, width, height } = props
  return (
    <svg
      viewBox="0 0 880 850"
      width={width ? width : 'auto'}
      height={height ? height : 'auto'}
      fill={fill}
    >
      <g>
        <g>
          <path
            className="st0"
            d="M597.1,472c-2-33,5.6-65.9,22.5-95.2l106.1-183.7l-128.5-74.2L491.2,302.6c-36.8,63.7-50.2,136.5-38.9,208.2
			L597.1,472z"
          />
          <path
            className="st0"
            d="M282.9,472c2-33-5.6-65.9-22.5-95.2L154.3,193.1l128.5-74.2l106.1,183.7c36.8,63.7,50.2,136.5,38.9,208.2
			L282.9,472z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M318.9,596.4c-7.8,0-13.8,6-13.8,13.7c0,7.7,6.1,13.7,13.8,13.7c7.6,0,13.8-6.1,13.8-13.7
			C332.7,602.5,326.5,596.4,318.9,596.4z"
          />
          <rect x="307.5" y="631.6" className="st0" width="23" height="90" />
          <path
            className="st0"
            d="M449.8,629.1c-26.3,0-45.4,19.9-45.4,47.3c0,28.3,19.2,47.3,47.9,47.3c15.2,0,27.7-4.3,35.3-11.8l0.1-0.1
			v-21.2l-0.8,0.7c-9.2,8-20.4,12.6-30.8,12.6c-17.4,0-29-8.7-31.1-23.3h65.5l0.1-0.4c1.9-15.2-1.7-28.7-10-38.2
			C473.1,633.6,462.5,629.1,449.8,629.1z M425.8,665.2c2.8-11.6,11.1-18.6,22.3-18.6c10.9,0,19,7.6,19.9,18.6H425.8z"
          />
          <path
            className="st0"
            d="M551.7,629.1c-13.2,0-24,5.8-29.8,16v-13.4h-23v125h23v-48.9c5.8,10.2,16.6,16,29.8,16
			c25.3,0,44.3-20.4,44.3-47.3C596,649.5,577,629.1,551.7,629.1z M572.7,676.4c0,15-10.9,26.3-25.4,26.3s-25.4-11.3-25.4-26.3
			c0-15,10.9-26.3,25.4-26.3S572.7,661.5,572.7,676.4z"
          />
          <path
            className="st0"
            d="M636.9,629.1c-11.6,0-23.1,4.1-30.9,10.7l-0.2,0.1v20.4l0.8-0.7c7.7-7,17.1-11.2,26.6-11.2
			c14.3,0,23.3,7.4,23.3,19.4v5.1c-6.1-4.4-14.7-7-23.3-7c-19,0-32.3,11.7-32.3,28.4c0,17.1,13.1,29.5,31.1,29.5
			c10.4,0,19.8-5.3,24.5-13.6v11.5h23v-54.9C679.5,644.2,662.3,629.1,636.9,629.1z M656.4,687.4v0.5c-0.3,10.4-8.1,18.3-17.9,18.3
			c-9,0-15.5-5.2-15.5-12.5c0-7.1,6.5-12.3,15.5-12.3C644.8,681.5,651.3,683.6,656.4,687.4z"
          />
          <path
            className="st0"
            d="M753.2,637.5v21.6c-7.3-5.8-19.3-9.6-30.4-9.6c-7.4,0-12.1,3-12.1,7.8c0,3.9,2.7,6.4,9.8,8.1l11.5,2.7
			c18.9,4.2,25.5,14.3,25.5,27.4c0,17.5-13.1,27.8-36.8,27.8c-10.6,0-22.8-4.1-32.9-10.6l0.2-21.8c9.6,7.8,23.2,12.7,34.9,12.7
			c8,0,12.5-3.4,12.5-8.5c0-4.4-2.9-7.1-10-8.8l-10.6-2.5c-19.5-4.4-25.8-14.5-25.8-26c0-20.9,16.8-28.3,34.9-28.3
			C735,629.6,746.1,632.7,753.2,637.5z"
          />
          <path
            className="st0"
            d="M364.8,647.5v-15.9h-23v90h23V680c0-32,30.4-31.5,38.9-25.6v-21.9C398.6,628.7,373,624.5,364.8,647.5z"
          />
          <polygon
            className="st0"
            points="277,598.3 251.3,680.9 225.2,598.3 199.9,598.3 173.8,680.9 148.1,598.3 122.5,598.3 162.1,721.4 
			162.1,721.6 185.8,721.6 212.5,638 239.2,721.6 262.9,721.6 302.5,598.3 		"
          />
        </g>
      </g>
    </svg>
  )
}
export default WirepasLogo
