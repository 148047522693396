import { createMuiTheme } from '@material-ui/core'
import React, { useContext } from 'react'
import BackgroundImage from './background.jpg'
const NEW_BRAND_PRIMARY = '#111112' // Logos, text, buttons on light background
const NEW_BRAND_WHITE = '#FCF2E6'
const NEW_BRAND_HIGHLIGHT = '#FF8C7E'

const customTheme = {
  //@ts-ignore
  fonts: {
    default: {
      regular: 'Wallop',
      semiBold: 'Wallop Bold',
    },
  },
  palette: {
    primary: {
      main: NEW_BRAND_PRIMARY,
    },
  },
  colors: {
    mainLogo: NEW_BRAND_PRIMARY, // Text and buttons
    primary: NEW_BRAND_PRIMARY,
    secondary: NEW_BRAND_WHITE,
    highlight: NEW_BRAND_HIGHLIGHT,
    button: NEW_BRAND_PRIMARY,
    paper: NEW_BRAND_WHITE,
    textLight: 'white',
    textPrimary: '#414141',
    textSecondary: NEW_BRAND_WHITE,
    alternateButton: 'rgb(68,68,68)',
    background: '#462029', // Replaces background image on login and password reset pages
    gray90: '#343836', // BRAND_POLAR_NIGHT with 90% tint
    gray80: '#4A4E4D', // BRAND_POLAR_NIGHT with 80% tint
    gray70: '#616463', // BRAND_POLAR_NIGHT with 70% tint
    gray60: '#777A79', // BRAND_POLAR_NIGHT with 60% tint
    gray50: '#8E9190', // BRAND_POLAR_NIGHT with 50% tint
    gray40: '#A5A7A6', // BRAND_POLAR_NIGHT with 40% tint
    gray30: '#BBBDBC', // BRAND_POLAR_NIGHT with 30% tint
    gray20: '#D2D3D2', // BRAND_POLAR_NIGHT with 20% tint
    gray10: '#E8E9E9', // BRAND_POLAR_NIGHT with 10% tint,
    textGrey: '#A5A7A6',
    textOnGrey: '#414141',
    actuals: '#FF715B', // Background of the table header cell
    forecast: '#FFB000',
    actualsHeader: '#FF705B', // Column label text
    forecastHeader: '#CD830E',
    notificationOk: 'rgb(28,149,113)',
    notificationError: '#FF3416', //"rgb(216, 41, 25)",
    buttonDisabled: 'rgb(229, 229, 229)',
    tableCellBackgroundDefault: '#FCF2E6',
    tableCellBackgroundOdd: '#fdf6ed',
    tableCellBackgroundHighlight: '#F7DAB8',
    topBannerBackground: '#FCF2E6',
    ongoingTableHeader: '#FFB000',
    tableHeaderAdminFilters: '#FF8C7E',
    tableHeaderAdmin1: '#FF3416',
    userReportOverviewTableHeader: '#004d40',
    adminSubmitColor: 'black',
    brandWhite: '#FCF3E8',
    helpBubble: '#6E7170',
  },
}
//@ts-ignore
export const defaultTheme: Theme | typeof customTheme =
  createMuiTheme(customTheme)
const BRAND_NEON_GREEN = '#34FF77'
const BRAND_POLAR_NIGHT = '#1D2220'
const BRAND_WHITE = '#FFFFFF'
export const oldTheme = {
  colors: {
    mainLogo: BRAND_POLAR_NIGHT,
    primary: BRAND_POLAR_NIGHT,
    secondary: BRAND_WHITE,
    highlight: BRAND_NEON_GREEN,
    button: BRAND_NEON_GREEN,
    paper: BRAND_WHITE,
    textLight: 'white',
    textPrimary: BRAND_POLAR_NIGHT,
    textSecondary: BRAND_WHITE,
    gray90: '#343836', // BRAND_POLAR_NIGHT with 90% tint
    gray80: '#4A4E4D', // BRAND_POLAR_NIGHT with 80% tint
    gray70: '#616463', // BRAND_POLAR_NIGHT with 70% tint
    gray60: '#777A79', // BRAND_POLAR_NIGHT with 60% tint
    gray50: '#8E9190', // BRAND_POLAR_NIGHT with 50% tint
    gray40: '#A5A7A6', // BRAND_POLAR_NIGHT with 40% tint
    gray30: '#BBBDBC', // BRAND_POLAR_NIGHT with 30% tint
    gray20: '#D2D3D2', // BRAND_POLAR_NIGHT with 20% tint
    gray10: '#E8E9E9', // BRAND_POLAR_NIGHT with 10% tint,
    textGrey: '#A5A7A6',
    textOnGrey: 'rgb(97, 100, 100)',
    actuals: 'rgb(100,218,155)',
    forecast: 'rgb(116,172,182)',
    actualsHeader: 'rgb(129, 215, 160)',
    forecastHeader: 'rgb(134,189,170)',
    notificationOk: 'rgb(28,149,113)',
    notificationError: 'rgb(216, 41, 25)',
    buttonDisabled: 'rgb(229, 229, 229)',
    tableHeaderAdmin1: 'rgb(69, 147, 115)',
    tableHeaderAdminFilters: 'rgb(173, 210, 198)',
    tableCellBackgroundDefault: 'rgb(242,243,243)',
    ongoingTableHeader: 'rgb(94,200,122)',
    userReportOverviewTableHeader: '#004d40',
    adminSubmitColor: 'black',
  },
  backgroundImage: BackgroundImage,
}
export const ThemeContext =
  React.createContext<typeof defaultTheme>(defaultTheme)
export const useTheme = () => useContext(ThemeContext)
