/** @jsx jsx */
import React, { useState, useEffect } from 'react'
import {
  Typography,
  makeStyles,
  Grid,
  FormControl,
  TextField,
  Paper,
  Button,
  Input,
  InputLabel,
  FormHelperText,
  Toolbar,
  AppBar,
  useTheme,
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import tr from './../translations'
import { requestToken } from './../actions/auth'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { RootState } from './../reducers'
import { css, jsx } from '@emotion/core'
import WirepasLogo from '../wirepas-logo'
import { useParams, useHistory } from 'react-router-dom'
import * as request from './../lib/request'
import { requestLogout } from './../actions/auth'
import { defaultTheme } from './../wirepas_theme'
import { StyledText } from './Elements'
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    backgroundColor: defaultTheme.colors.background,
    //backgroundImage: `url(${defaultTheme.backgroundImage})`,
    //backgroundSize: "cover",
    resize: 'both',
  },
  centered: {
    margin: 'auto',
  },
  loginPaper: {
    width: '22rem',
    margin: 'auto',
    padding: theme.spacing(6.5),
    backgroundColor: defaultTheme.colors.paper,
  },
  formField: {},
}))
type LoginFormData = {
  email: string
  password: string
  repeatPassword: string
}
const validateNewUserToken = async (newUserToken: string) => {
  try {
    const res = await request.get(`/api/account/${newUserToken}`)
    const { success, data, error } = res
    if (!success) {
      throw new Error(error)
    }
    return data.email
  } catch (err) {
    throw new Error(err.message)
  }
}
enum PageState {
  VALIDATING_TOKEN,
  TOKEN_VALIDATION_FAILED,
  TOKEN_VALIDATION_SUCCESSFUL,
  PASSWORD_CHANGE_REQUESTED,
  PASSWORD_CHANGE_FAILED,
  PASSWORD_CHANGE_SUCCESSFUL,
}
export default function ResetPassword() {
  const classes = useStyles()
  //const dispatch = useDispatch()
  const { register, handleSubmit, control } = useForm<LoginFormData>()
  const history = useHistory()
  const { token } = useParams()
  const dispatch = useDispatch()
  const onSubmit = async (data: LoginFormData) => {
    setStatus(PageState.PASSWORD_CHANGE_REQUESTED)
    if (data.password !== data.repeatPassword) {
      setPasswordError('Passwords do not match')
      setStatus(PageState.PASSWORD_CHANGE_FAILED)
    } else {
      // Update password
      const { success, data: any } = await request.post(
        `/api/account/${token}`,
        {
          password: data.password,
        }
      )
      if (!success) {
        setPasswordError('Could not update password')
        setStatus(PageState.PASSWORD_CHANGE_FAILED)
      } else {
        setStatus(PageState.PASSWORD_CHANGE_SUCCESSFUL)
        dispatch(requestLogout())
        setTimeout(() => {
          history.push('/login')
        }, 3000)
      }
    }
  }
  const [accountEmail, setAccountEmail] = useState<string | null>(null)
  const [accountValidationError, setAccountValidationError] = useState<
    string | null
  >(null)
  const [passwordError, setPasswordError] = useState<string | null>(null)
  const [status, setStatus] = useState<PageState>(PageState.VALIDATING_TOKEN)
  useEffect(() => {
    setStatus(PageState.VALIDATING_TOKEN)
    validateNewUserToken(token as string).then(
      (validatedEmail) => {
        setStatus(PageState.TOKEN_VALIDATION_SUCCESSFUL)
        setAccountEmail(validatedEmail)
      },
      (error: Error) => {
        setAccountValidationError(error.message)
        setStatus(PageState.TOKEN_VALIDATION_FAILED)
      }
    )
  }, [])
  const theme = defaultTheme
  return (
    <div className={classes.root}>
      <Paper className={classes.loginPaper}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" spacing={1}>
            <Grid item style={{ textAlign: 'center' }}>
              <WirepasLogo width="300px" fill={theme.colors.mainLogo} />
            </Grid>
            {status == PageState.VALIDATING_TOKEN && (
              <Grid item>
                <StyledText variant="body2" color="primary">
                  {'Please wait while validating account creation token'}
                </StyledText>
              </Grid>
            )}
            {status == PageState.TOKEN_VALIDATION_FAILED && (
              <Grid item>
                <StyledText variant="body2" color="primary">
                  {'Cannot validate account creation token: ' +
                    accountValidationError}
                </StyledText>
              </Grid>
            )}
            {status == PageState.PASSWORD_CHANGE_REQUESTED}
            {status == PageState.PASSWORD_CHANGE_SUCCESSFUL && (
              <Grid item>
                <StyledText variant="body2" color="primary">
                  {
                    'Password changed successfully. Redirecting to login page in 3 seconds.'
                  }
                </StyledText>
              </Grid>
            )}
            {(status == PageState.TOKEN_VALIDATION_SUCCESSFUL ||
              status == PageState.PASSWORD_CHANGE_FAILED) && (
              <React.Fragment>
                <Grid item>
                  <StyledText variant="headline3" color="primary">
                    Royalty reporting tool
                  </StyledText>
                </Grid>
                <Grid item>
                  <StyledText variant="body2" color="primary">
                    {accountEmail &&
                      'Set password for account linked to ' + accountEmail}
                  </StyledText>
                </Grid>
                <Grid item>
                  <Controller
                    as={
                      <TextField
                        label={tr('_password')}
                        placeholder={tr('_password')}
                        type="password"
                        fullWidth
                      ></TextField>
                    }
                    name="password"
                    control={control}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    as={
                      <TextField
                        label={tr('_repeat_password')}
                        placeholder={tr('_repeat_password')}
                        type="password"
                        fullWidth
                        error={Boolean(passwordError)}
                        helperText={passwordError ? passwordError : ''}
                      ></TextField>
                    }
                    name="repeatPassword"
                    control={control}
                  />
                </Grid>
                <Grid item>
                  <div style={{ height: '2rem' }} />
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    style={{
                      backgroundColor: defaultTheme.colors.button,
                      padding: '1rem',
                    }}
                  >
                    <StyledText variant="button" color="highlight">
                      {tr('_create_password')}
                    </StyledText>
                  </Button>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </form>
      </Paper>
    </div>
  )
}
