import { Dispatch } from 'redux'
import * as request from './../lib/request'
import { RootState } from '../reducers'
export const DEAL_REQUESTED = 'DEAL_REQUESTED'
export const DEALS_REQUESTED = 'DEALS_REQUESTED'
export const DEAL_RECEIVED = 'DEAL_RECEIVED'
export const DEALS_RECEIVED = 'DEALS_RECEIVED'
export const DEAL_ERROR = 'DEAL_ERROR'
export const DEALS_ERROR = 'DEALS_ERROR'
export const DEAL_UPDATE_REQUESTED = 'DEAL_UPDATE_REQUESTED'
export const DEAL_UPDATED = 'DEAL_UPDATED'
export const DEAL_CHANGELOG_REQUESTED = 'DEAL_CHANGELOG_REQUESTED'
export const DEAL_CHANGELOG_RECEIVED = 'DEAL_CHANGELOG_RECEIVED'
export interface DealArrayItem {
  dealId: number
  dealName: string
  prepaidRoyalties: number
  organizationName: string
}
export interface DealChangeLog {
  invoiceNumber: number
  comment: string
  updatedAt: number
  updatedBy: string
  prevPrepaidRoyalties: number
  newPrepaidRoyalties: number
}
interface DealChangeLogReceivedAction {
  type: typeof DEAL_CHANGELOG_RECEIVED
  changeLog: DealChangeLog[]
}
interface DealChangeLogRequestedAction {
  type: typeof DEAL_CHANGELOG_REQUESTED
}
interface DealUpdatedAction {
  type: typeof DEAL_UPDATED
  deal: DealArrayItem
}
interface DealUpdateRequestedAction {
  type: typeof DEAL_UPDATE_REQUESTED
}
interface DealRequestedAction {
  type: typeof DEAL_REQUESTED
}
interface DealsRequestedAction {
  type: typeof DEALS_REQUESTED
}
interface DealReceivedAction {
  type: typeof DEAL_RECEIVED
  prepaidRoyalties: number
}
interface DealsReceivedAction {
  type: typeof DEALS_RECEIVED
  deals: DealArrayItem[]
}
interface DealErrorAction {
  type: typeof DEAL_ERROR
  error: string
}
interface DealsErrorAction {
  type: typeof DEALS_ERROR
  error: string
}

const createDealUpdatedAction = (deal: DealArrayItem) => {
  return {
    type: DEAL_UPDATED,
    deal: deal,
  }
}

const createDealsReceivedAction = (deals: DealArrayItem[]) => {
  return {
    type: DEALS_RECEIVED,
    deals: deals,
  }
}

const createDealReceivedAction = (prepaidRoyalties: number) => {
  return {
    type: DEAL_RECEIVED,
    prepaidRoyalties: prepaidRoyalties,
  }
}

const createChangeLogReceivedAction = (changeLog: DealChangeLog[]) => {
  return {
    type: DEAL_CHANGELOG_RECEIVED,
    changeLog: changeLog,
  }
}

export const getDealPrepaids =
  (dealId: number) => async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({ type: DEAL_REQUESTED })
    try {
      const token = getState().auth.token
      const res = await request.get(
        `/api/deals/prepaid-royalties/${dealId}`,
        token
      )
      const { success, error, data } = res

      if (!success) {
        throw new Error(error)
      }

      dispatch(createDealReceivedAction(data))
    } catch (error) {
      dispatch({ type: DEAL_ERROR, error: error.message })
    }
  }

export interface updateDealObject {
  dealId: number
  comment?: string
  invoiceNumber?: number
  prepaidRoyalties: number
}

export const updateDealPrepaidRoyalties =
  (updatedDealObject: updateDealObject) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({ type: DEAL_UPDATE_REQUESTED })
    try {
      const token = getState().auth.token
      const res = await request.post(
        `/api/deals/prepaid-royalties/`,
        updatedDealObject,
        token
      )
      const { success, error, data } = res

      if (!success) {
        throw new Error(error)
      }

      dispatch(createDealUpdatedAction(data))
    } catch (error) {
      dispatch({ type: DEALS_ERROR, error: error.message })
    }
  }

export const getAllDealPrepaids =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({ type: DEALS_REQUESTED })
    try {
      const token = getState().auth.token
      const res = await request.get('/api/deals/prepaid-royalties', token)
      const { success, error, data } = res

      if (!success) {
        throw new Error(error)
      }

      dispatch(createDealsReceivedAction(data))
    } catch (error) {
      dispatch({ type: DEALS_ERROR, error: error.message })
    }
  }

export const getDealChangeLog =
  (dealId?: number) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({ type: DEAL_CHANGELOG_REQUESTED })
    try {
      const token = getState().auth.token
      const url = dealId
        ? `/api/deals/change-log/${dealId}`
        : '/api/deals/change-log'
      const res = await request.get(url, token)
      const { success, error, data } = res

      if (!success) {
        throw new Error(error)
      }

      dispatch(createChangeLogReceivedAction(data))
    } catch (error) {
      dispatch({ type: DEALS_ERROR, error: error.message })
    }
  }

export type DealActionTypes =
  | DealRequestedAction
  | DealReceivedAction
  | DealErrorAction
  | DealsErrorAction
  | DealsRequestedAction
  | DealsReceivedAction
  | DealUpdateRequestedAction
  | DealUpdatedAction
  | DealChangeLogRequestedAction
  | DealChangeLogReceivedAction
