import * as request from './../lib/request'
import { Dispatch } from 'redux'
export const REQUEST_TOKEN = 'REQUEST_TOKEN'
export const RECEIVE_TOKEN = 'RECEIVE_TOKEN'
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED'
export const LOG_OUT = 'LOG_OUT'
export const REQUEST_NEW_USER_TOKEN_VALIDATION =
  'REQUEST_NEW_USER_TOKEN_VALIDATION'
export const NEW_USER_TOKEN_VALIDATION_SUCCESS =
  'NEW_USER_TOKEN_VALIDATION_SUCCESS'
export const NEW_USER_TOKEN_VALIDATION_FAILURE =
  'NEW_USER_TOKEN_VALIDATION_FAILURE'
interface RequestTokenAction {
  type: typeof REQUEST_TOKEN
  email: string
  password: string
}
interface ReceiveTokenAction {
  type: typeof RECEIVE_TOKEN
  token: string
  userEmail: string
  userRole: string
}
interface AuthenticationFailedAction {
  type: typeof AUTHENTICATION_FAILED
  error: string
}
interface LogoutAction {
  type: typeof LOG_OUT
}
export const requestToken =
  (email: string, password: string) => async (dispatch: Dispatch) => {
    dispatch({ type: REQUEST_TOKEN })
    try {
      const response = await request.post('/api/login', {
        email: email,
        password: password,
      })
      if (!response) {
        throw new Error('No response from backend')
      }
      const { success, data, error } = response
      if (!success) {
        throw new Error(error)
      }
      if (data.token) {
        dispatch({
          type: RECEIVE_TOKEN,
          token: data.token,
          userEmail: data.userEmail,
          userRole: data.userRole,
        })
      } else {
        throw new Error('No token in response')
      }
    } catch (ex) {
      dispatch({ type: AUTHENTICATION_FAILED, error: ex })
    }
  }
export const requestLogout = () => async (dispatch: Dispatch) => {
  localStorage.clear()
  dispatch({ type: LOG_OUT })
}
export type AuthActionTypes =
  | RequestTokenAction
  | ReceiveTokenAction
  | AuthenticationFailedAction
  | LogoutAction
