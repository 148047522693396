import {
  AuthActionTypes,
  REQUEST_TOKEN,
  RECEIVE_TOKEN,
  AUTHENTICATION_FAILED,
  LOG_OUT,
} from './../actions/auth'
import tr from './../translations'
import { Logger } from '../lib/logger'
interface IAuthState {
  fetching: boolean
  token?: string
  userEmail?: string
  userRole?: string
  error: string | null
}
const initialState: IAuthState = {
  fetching: false,
  error: null,
}
export default function authReducer(
  state: IAuthState = initialState,
  action: AuthActionTypes
): IAuthState {
  Logger.debug(action)
  switch (action.type) {
    case REQUEST_TOKEN:
      return {
        ...state,
        error: null,
        fetching: true,
      }
    case RECEIVE_TOKEN:
      return {
        ...state,
        fetching: false,
        token: action.token,
        error: null,
        userEmail: action.userEmail,
        userRole: action.userRole,
      }
    case AUTHENTICATION_FAILED:
      return {
        ...state,
        fetching: false,
        error:
          typeof action.error === 'string'
            ? action.error
            : (action.error as any) instanceof Error
            ? (action.error as Error).message
            : JSON.stringify(action.error),
      }
    case LOG_OUT:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
