import * as request from './request'
import { RoyaltyReport, RoyaltyEntry } from './../actions/reports'
import { Logger as logger } from './logger'
import { RoyaltyFormRow } from '../components/RoyaltyReportForm'
import { FieldValues } from 'react-hook-form'
export enum ReportSendStatus {
  Sending,
  Success,
  Error,
  Idle,
}
export async function submitReport(report: RoyaltyReport, token: string) {
  const { pipedriveDealId, year, quarter } = report
  const { data, success, error } = await request.post(
    `/api/forms/${pipedriveDealId}/${year}/${quarter}`,
    report,
    token
  )
  if (!success) {
    throw new Error(error)
  }
}
export function calculateYearlyEstimate(row: RoyaltyEntry) {
  if (
    !row ||
    !row.entries ||
    !Array.isArray(row.entries) ||
    row.entries.length < 12
  ) {
    return Number.NaN
  }
  let sum: number = 0
  for (let irow = 0; irow < 12; ++irow) {
    const quantity = row.entries[irow]
    if (quantity === undefined || quantity === null || Number.isNaN(quantity)) {
      return Number.NaN
    }
    sum += quantity
  }
  return sum
}
interface IRoyaltyType {
  productId: number
  name: string
  productCode: string
}
export type ReportingStatus =
  | 'not_started'
  | 'started'
  | 'received'
  | 'invoiced'
  | 'not_invoiced'
export const fetchRoyaltyTypesForDeal = async (
  dealId: number,
  authToken: string
) => {
  try {
    const res = await request.get(`/api/royaltyTypes/${dealId}`, authToken)
    if (!res) {
      throw new Error('No response from backend')
    }
    const { success, data, error } = res
    if (!success) {
      throw new Error(error)
    }
    return data as IRoyaltyType[]
  } catch (ex) {
    return [] as IRoyaltyType[]
  }
}
function ensureInt(value: any) {
  try {
    return typeof value === 'string' ? parseInt(value as any) : value
  } catch (err) {
    return 0
  }
}
export const calculateQuarterVolume = (
  entries: RoyaltyEntry[],
  quarter: number
) => {
  try {
    let sum = 0
    for (let entry of entries) {
      let volumes = entry.entries
      for (let imonth = (quarter - 1) * 3; imonth < quarter * 3; ++imonth) {
        if (volumes[imonth]) {
          sum += ensureInt(volumes[imonth])
        }
      }
    }
    return sum
  } catch (err) {
    return Number.NaN
  }
}
export const getLastSubmitTimestamp = (report: RoyaltyReport) => {
  try {
    const reportArray = report.submitHistory.slice()
    const sortedHistory = reportArray.sort((a, b) => {
      return a.timestamp < b.timestamp ? 1 : b.timestamp < a.timestamp ? -1 : 0
    })
    return sortedHistory[0].timestamp
  } catch (err) {
    return 0
  }
}
export const getLastSubmitAccessLevel = (report: RoyaltyReport) => {
  try {
    const reportArray = report.submitHistory.slice()
    const sortedHistory = reportArray.sort((a, b) => {
      return a.timestamp < b.timestamp ? 1 : b.timestamp < a.timestamp ? -1 : 0
    })
    return sortedHistory[0].accessLevel
  } catch (err) {
    return undefined
  }
}
function determineLatestQuarter(reports: RoyaltyReport[]) {
  let latestQuarter
  let latestYear
  for (let report of reports) {
    if (
      latestQuarter === undefined ||
      latestYear === undefined ||
      Number(latestQuarter) / 10 + Number(latestYear) <
        Number(report.year) + Number(report.quarter) / 10
    ) {
      latestYear = report.year
      latestQuarter = report.quarter
    }
  }
  return { latestQuarter, latestYear }
}
export interface QuarterReportData {
  year: number
  quarter: number
  reportStatusCountMap: Map<string, number>
  actualsTotal: number
}
export function buildReportingPeriodId(year: any, quarter: any) {
  return `${year}/Q${quarter}`
}
export function buildEmptyReportData(
  year: number,
  quarter: number
): QuarterReportData {
  const reportStatusCountMap = new Map<string, number>()
  ;['not_started', 'started', 'received', 'invoiced', 'not_invoiced'].forEach(
    (status: string) => {
      reportStatusCountMap.set(status, 0)
    }
  )
  return {
    year,
    quarter,
    reportStatusCountMap,
    actualsTotal: 0,
  }
}
export function calculateReportingPeriodStatistics(reports: RoyaltyReport[]) {
  logger.debug(reports)
  const reportPeriodMap = new Map<string, QuarterReportData>()
  try {
    for (let report of reports) {
      const reportingPeriodId = buildReportingPeriodId(
        report.year,
        report.quarter
      )
      if (!reportPeriodMap.get(reportingPeriodId)) {
        reportPeriodMap.set(
          reportingPeriodId,
          buildEmptyReportData(report.year, report.quarter)
        )
      }
      const reportingEntry = reportPeriodMap.get(reportingPeriodId)
      if (reportingEntry) {
        const reportStatusCountMap = reportingEntry.reportStatusCountMap
        if (
          typeof reportStatusCountMap.get(report.reportingStatus) === 'number'
        ) {
          reportStatusCountMap.set(
            report.reportingStatus,
            (reportStatusCountMap.get(report.reportingStatus) || 0) + 1
          )
        } else {
          reportStatusCountMap.set(report.reportingStatus, 1)
        }
        let localActualsSum = 0
        // Calculate actuals sum for all actuals entries (first 3 months),
        // including those that are saved but not submitted.
        for (let entry of report.entries) {
          for (let imonth = 0; imonth < 3; ++imonth) {
            if (entry.entries[imonth]) {
              localActualsSum += ensureInt(entry.entries[imonth])
            }
          }
        }
        reportingEntry.actualsTotal += localActualsSum
      }
    }
  } catch (err) {}
  return reportPeriodMap
}
type SummaryCalculateMode = 'actuals' | 'n1' | 'n2' | 'n3' | 'n4' | 'n5'
export const summaryCalculate = (
  reports: RoyaltyReport[],
  mode: SummaryCalculateMode
): number => {
  if (!reports || !reports.length) return 0
  let quarter: number

  switch (mode) {
    case 'actuals':
      quarter = 1
      break
    case 'n1':
      quarter = 2
      break
    case 'n2':
      quarter = 3
      break
    case 'n3':
      quarter = 4
      break
    case 'n4':
      quarter = 5
      break
    case 'n5':
      quarter = 6
      break
    default:
      return 0
  }

  return reports.reduce((acc: number, report: RoyaltyReport) => {
    const reportStatus = report.reportingStatus
    if (!(reportStatus === 'received' || reportStatus === 'started')) return acc

    for (const volume of report.entries) {
      for (let i = (quarter - 1) * 3; i < quarter * 3; ++i) {
        if (volume.entries[i]) {
          acc += Number(volume.entries[i])
        }
      }
    }
    return acc
  }, 0)
}

export const calculateUsedPrepaids = (rows: RoyaltyFormRow[]): number => {
  let totalUsedPrepaids = 0

  if (!rows || !rows.length) return totalUsedPrepaids

  for (const row of rows) {
    const royaltyType = row.royaltyType.toLowerCase()

    if (!royaltyType.includes('prepaid')) continue

    for (let imonth = 0; imonth < 3; imonth++) {
      totalUsedPrepaids += Number(row.entries[imonth])
    }
  }

  return totalUsedPrepaids
}

export const calculateRowActuals = (
  fieldValues: FieldValues,
  numOfRows: number
) => {
  let rowActualsTotal = 0

  if (!fieldValues || !Object.keys(fieldValues).length) return rowActualsTotal

  for (let irow = 0; irow < numOfRows; ++irow) {
    const royaltyType = fieldValues[`row ${irow} royaltyType`].toLowerCase()

    if (!royaltyType.includes('prepaid')) continue

    for (let imonth = 0; imonth < 3; ++imonth) {
      const fieldName = `row ${irow} month ${imonth}`
      rowActualsTotal += Number(fieldValues[fieldName])
    }
  }

  return rowActualsTotal
}
