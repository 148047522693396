import {
  SettingsActionTypes,
  REQUESTING_SETTINGS,
  SETTINGS_RECEIVED,
  SETTINGS_ERROR,
} from '../actions/settings'
interface SettingsState {
  supportContactEmail: string
  supportContactPhone: string
  isFetching: boolean
  error?: string
}
const initialState: SettingsState = {
  supportContactEmail: '-',
  supportContactPhone: '-',
  isFetching: false,
}
export default function (
  state: SettingsState = initialState,
  action: SettingsActionTypes
) {
  switch (action.type) {
    case REQUESTING_SETTINGS:
      return {
        ...state,
        isFetching: true,
      }
    case SETTINGS_RECEIVED:
      return {
        ...state,
        supportContactEmail: action.settings.supportContactEmail,
        supportContactPhone: action.settings.supportContactPhone,
        error: undefined,
        isFetching: false,
      }
    case SETTINGS_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      }
    default:
      return state
  }
}
