import { Dispatch } from 'redux'
import * as request from './../lib/request'
import { RootState } from '../reducers'
import { Logger } from '../lib/logger'
export const REPORTS_REQUESTED = 'REPORTS_REQUESTED'
export const REPORTS_RECEIVED = 'REPORTS_RECEIVED'
export const REPORTS_ERROR = 'REPORTS_ERROR'
export const UPDATE_REPORT_FILTERS = 'UPDATE_REPORT_FILTERS'
export const CLEAR_REPORT_FILTERS = 'CLEAR_REPORT_FILTERS'
// Data
export interface RoyaltyEntry {
  quarter: number
  projectName: string
  royaltyType: string
  unitPrice: string
  entries: number[]
}
export interface RoaltyReportSubmitHistoryEntry {
  email: string
  timestamp: number
  accessLevel: 'admin' | 'sales' | 'user'
}
export interface RoyaltyReport {
  pipedriveDealId: number
  quarter: number
  year: number
  dealTitle: string
  organizationName: string
  contactName: string
  contactEmail: string
  reminderCount: number
  lastReminderDate: Date
  answered: boolean
  entries: RoyaltyEntry[]
  procountorInvoiceId: number
  comments: string
  reportingStatus: string
  submitHistory: RoaltyReportSubmitHistoryEntry[]
}
export interface Projection {
  licenseName: string
  projectedQuantity: number
}
export interface IDealLicenseProjection {
  organizationName: string
  dealTitle: string
  estimate: number
}
// Actions
interface ReportsRequestedAction {
  type: typeof REPORTS_REQUESTED
}
export interface ReportingSettings {
  reportingYear: number
  reportingQuarter: number
}
interface ReportsReceivedAction {
  type: typeof REPORTS_RECEIVED
  reports: RoyaltyReport[]
  projections: Projection[]
  detailedProjections: Map<string, IDealLicenseProjection[]>
  reportingSettings?: ReportingSettings
}
interface ReportsErrorAction {
  type: typeof REPORTS_ERROR
  error: string
}
function createReportsReceivedAction(
  reports: RoyaltyReport[],
  projections: Projection[],
  detailedProjections: Map<string, IDealLicenseProjection[]>,
  reportingSettings: ReportingSettings
): ReportsReceivedAction {
  Logger.debug('updating reports', reports)
  return {
    type: REPORTS_RECEIVED,
    reports,
    projections,
    detailedProjections,
    reportingSettings,
  }
}
export const fetchReports =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({ type: REPORTS_REQUESTED })
    try {
      const token = getState().auth.token
      const res = await request.get('/api/reports', token)
      console.log('result for reports', res)
      if (!res) {
        throw new Error('No response from backend')
      }
      const { success, data, error } = res
      if (!success) {
        throw new Error(error)
      }
      dispatch(
        createReportsReceivedAction(
          res.data.reports,
          res.data.totalEstimatesForLicenses,
          res.data.detailedEstimatesForLicenses,
          res.data.reportingSettings
        )
      )
    } catch (ex) {
      dispatch({ type: REPORTS_ERROR, error: ex.message })
    }
  }
type SortDirection = 'ascending' | 'descending'
export interface ReportFilters {
  checkedQuarters: Record<any, boolean>
  checkedYears: Record<any, boolean>
  checkedStatuses: Record<any, boolean>
  sortField: string
  sortDirection: SortDirection
  searchString: string
}
export const updateReportFilters = (newFilters: Partial<ReportFilters>) => {
  return {
    type: UPDATE_REPORT_FILTERS,
    reportFilters: newFilters,
  } as const
}
export const clearReportFilters = () => {
  return {
    type: CLEAR_REPORT_FILTERS,
  } as const
}
export type ReportsActionTypes =
  | ReportsRequestedAction
  | ReportsReceivedAction
  | ReportsErrorAction
  | ReturnType<typeof updateReportFilters>
  | ReturnType<typeof clearReportFilters>
