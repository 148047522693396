import {
  SettingsActionTypes,
  REQUESTING_SETTINGS,
  SETTINGS_RECEIVED,
  SETTINGS_ERROR,
} from '../actions/settings'
import {
  SHOW_NOTIFICATION,
  CLOSE_NOTIFICATION,
  AppActionTypes,
} from '../actions/app'
import { NotificationBarVariant } from '../components/Elements'
interface AppState {
  showNotification: boolean
  notificationMessage: string
  notificationType: NotificationBarVariant
}
const initialState: AppState = {
  showNotification: false,
  notificationMessage: '',
  notificationType: 'success',
}
export default function (
  state: AppState = initialState,
  action: AppActionTypes
): AppState {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return {
        ...state,
        showNotification: true,
        notificationMessage: action.notification.message,
        notificationType: action.notification.type,
      }
    case CLOSE_NOTIFICATION:
      return {
        ...state,
        showNotification: false,
      }
    default:
      return state
  }
}
