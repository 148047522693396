import {
  UserActionTypes,
  USER_REQUESTED,
  USER_ERROR,
  USER_RECEIVED,
} from '../actions/user'
export interface UserState {
  email: string | null
  role: string | null
  isFetching: boolean
  error: string | null
}
const initialState: UserState = {
  email: null,
  role: null,
  isFetching: false,
  error: null,
}
export default function (
  state: UserState = initialState,
  action: UserActionTypes
) {
  switch (action.type) {
    case USER_REQUESTED:
      return {
        ...state,
        isFetching: false,
        error: null,
      }
    case USER_RECEIVED:
      return {
        email: action.email,
        role: action.role,
        error: null,
        isFetching: false,
      }
    case USER_ERROR:
      return {
        email: null,
        role: null,
        isFetching: false,
        error: action.error,
      }
    default:
      return state
  }
}
