import React, { useState, useEffect } from 'react'
import { DealArrayItem } from '../actions/deal'
import { StyledText } from './Elements'
import { Button, TextField, TextareaAutosize } from '@material-ui/core'

export interface PrepaidEditObject {
  prepaidRoyalties: number
  comment?: string
  invoiceNumber?: number
}

interface EditModalProps {
  dealData: DealArrayItem
  onClose: () => void
  handleSubmit: (editObject: PrepaidEditObject, dealId: number) => void
}

const overlayStyle: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
}

const modalContentStyle: React.CSSProperties = {
  backgroundColor: 'white',
  padding: '2rem',
  borderRadius: '5px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  width: '50%',
}

const PrepaidEditModal: React.FC<EditModalProps> = ({
  dealData,
  onClose,
  handleSubmit,
}) => {
  const [formData, setFormData] = useState<PrepaidEditObject>({
    prepaidRoyalties: 0,
    comment: '',
    invoiceNumber: undefined,
  })

  const checkIfNonNumeric = (key: string, value: string): string => {
    const numbersRegex = /^\d+$/
    let result: string

    if (!(key === 'prepaidRoyalties' || key === 'invoiceNumber')) return value

    if (!numbersRegex.test(value)) {
      result = value.slice(0, -1)
      return result
    }

    return value
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { name: key, value } = event.target

    value = checkIfNonNumeric(key, value)

    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [key]: value,
      }
    })
  }

  useEffect(() => {
    return () => {
      setFormData({
        prepaidRoyalties: 0,
        comment: '',
        invoiceNumber: undefined,
      })
    }
  }, [])

  return (
    <div style={overlayStyle}>
      <div style={modalContentStyle}>
        <StyledText variant="headline2">
          {`Deal #${dealData.dealId} - Prepaid royalties management`}
        </StyledText>
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            gap: '1rem',
            marginTop: '1rem',
          }}
        >
          <div>
            <TextField
              label="# to Increment Prepaid Royalties"
              variant="standard"
              fullWidth
              required
              name="prepaidRoyalties"
              value={formData.prepaidRoyalties}
              onChange={handleChange}
            />
          </div>
          <div>
            <TextField
              label="Comment"
              variant="standard"
              fullWidth
              multiline
              name="comment"
              value={formData.comment}
              onChange={handleChange}
            />
          </div>
          <div>
            <TextField
              label="Invoice Number"
              variant="standard"
              fullWidth
              name="invoiceNumber"
              value={formData.invoiceNumber}
              onChange={handleChange}
            />
          </div>
        </form>
        <div
          style={{
            display: 'flex',
            flex: 1,
            gap: '1rem',
            marginTop: '1rem',
          }}
        >
          <Button
            style={{
              margin: '0',
            }}
            onClick={onClose}
          >
            <StyledText variant="button">Cancel</StyledText>
          </Button>
          <Button
            style={{
              margin: '0',
            }}
            onClick={() => handleSubmit(formData, dealData.dealId)}
          >
            <StyledText variant="button">Submit</StyledText>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PrepaidEditModal
