/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../reducers'
import {
  makeStyles,
  Grid,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  withStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button,
  Container,
} from '@material-ui/core'
import { RoyaltyReport, fetchReports } from '../actions/reports'
import CircularProgress from '@material-ui/core/CircularProgress'
import tr from '../translations'
import { Link, useHistory } from 'react-router-dom'
import { calculateYearlyEstimate } from '../lib/report'
import { defaultTheme } from '../wirepas_theme'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { StyledText, SortDirectionArrow, AlternatingTableRow } from './Elements'
import { defaultTheme as wirepasTheme } from '../wirepas_theme'
import { css, jsx } from '@emotion/core'
import Chip from '@material-ui/core/Chip'
import { fetchSettings } from '../actions/settings'
import { Logger } from '../lib/logger'
import { report_testdata } from './report_testdata'
const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    width: '100%',
  },
  title: {
    padding: theme.spacing(3),
  },
}))
const HeaderCell = withStyles((theme) => ({
  head: {
    backgroundColor: wirepasTheme.colors.tableHeaderAdmin1,
    color: wirepasTheme.colors.secondary,
    fontWeight: 'bold',
  },
  body: {},
}))(TableCell)
export default function ReportOverview(props: any) {
  const dispatch = useDispatch()
  const reports = useSelector((state: RootState) => state.reports.reports)
  const projections = useSelector(
    (state: RootState) => state.reports.projections
  )
  const detailedProjections = useSelector(
    (state: RootState) => state.reports.detailedProjections
  )
  const isFetching = useSelector((state: RootState) => state.reports.isFetching)
  const error = useSelector((state: RootState) => state.reports.error)
  const userRole = useSelector((state: RootState) => state.auth.userRole)
  const [sortedReports, setSortedReports] = useState<RoyaltyReport[]>()
  const classes = useStyles()
  const supportContactEmail = useSelector(
    (state: RootState) => state.settings.supportContactEmail
  )
  const supportContactPhone = useSelector(
    (state: RootState) => state.settings.supportContactPhone
  )
  const sortReports = (
    reports: RoyaltyReport[],
    getComparison: (report: RoyaltyReport) => any,
    sortDirection: 'ascending' | 'descending' = 'ascending'
  ) => {
    let comp1 = -1
    let comp2 = 1
    if (sortDirection === 'descending') {
      comp1 = 1
      comp2 = -1
    }
    setSortedReports(
      reports.sort((a, b) =>
        getComparison(a) < getComparison(b)
          ? comp1
          : getComparison(a) > getComparison(b)
          ? comp2
          : 0
      )
    )
  }
  useEffect(() => {
    dispatch(fetchSettings())
    dispatch(fetchReports())
  }, [dispatch])
  const [showNotification, setShowNotification] = useState<boolean>(false)
  useEffect(() => {
    if (error) {
      setShowNotification(true)
    } else {
      setShowNotification(false)
    }
  }, [error])
  const history = useHistory()
  const handleLink = (path: string) => {
    history.push(path)
  }
  const [sortField, setSortField] = useState<string>('reportingStatus')
  const [sortDirection, setSortDirection] = useState<
    'ascending' | 'descending'
  >('ascending')
  const handleSortingChange = (newFieldName: string) => {
    if (sortField === newFieldName) {
      setSortDirection(
        sortDirection === 'ascending' ? 'descending' : 'ascending'
      )
    }
    setSortField(newFieldName)
  }
  const [searchString, setSearchString] = useState<string>('')
  const handleChangeSearchString = (e: any) => {
    setSearchString(e.target.value)
  }
  // Handle filter changes
  useEffect(() => {
    const filteredReports = reports.filter((rep: RoyaltyReport) => {
      if (searchString !== undefined && searchString.length > 0) {
        const searchStringLower = searchString.toLowerCase()
        if (
          !(
            rep.organizationName.toLowerCase().includes(searchStringLower) ||
            rep.dealTitle.toLowerCase().includes(searchStringLower)
          )
        ) {
          return false
        }
      }
      return true
    })
    sortReports(
      filteredReports,
      (report: RoyaltyReport) => {
        //@ts-ignore
        return report[sortField]
      },
      sortDirection
    )
  }, [searchString, sortField, sortDirection, reports])
  return (
    <div className={classes.root}>
      <div
        style={{
          width: '100%',
          boxSizing: 'border-box',
          backgroundColor: defaultTheme.colors.topBannerBackground,
          padding: '1rem',
        }}
      >
        <Container>
          <StyledText
            variant="headline3"
            color="ongrey"
            style={{ fontSize: '1.5rem' }}
          >
            Royalty reports
          </StyledText>

          <div style={{ height: '1rem' }} />
          <StyledText
            variant="body"
            color="ongrey"
            style={{ fontFamily: 'Montserrat SemiBold' }}
          >
            Welcome to your own homepage of the Wirepas royalty reporting tool.
          </StyledText>
          <div style={{ height: '1rem' }} />
          <StyledText variant="body" color="ongrey">
            As set forth in the Software License Agreement (SLA) you are obliged
            to report each month the number of manufactured units programmed
            with the Wirepas Massive software.
          </StyledText>
          <div style={{ height: '1rem' }} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              boxSizing: 'border-box',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ margin: 0, padding: 0, width: '100%' }}>
              <StyledText variant="body" color="ongrey">
                From this view you can find:
                <br />
                &nbsp;&nbsp;- already submitted reports from earlier quarters
                and
                <br />
                &nbsp;&nbsp;- reports which are open and awaiting your input
              </StyledText>
            </div>
            <div style={{ margin: 0, padding: 0, width: '100%' }}>
              <StyledText variant="body" color="ongrey">
                If encountering a problem please contact us:
              </StyledText>
              <div style={{ height: '1rem' }} />
              {[
                ['Contact email:', supportContactEmail],
                ['Contact phone:', supportContactPhone],
              ].map((field) => {
                return (
                  <Grid item>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <div
                        style={{
                          padding: '0.05rem',
                          width: '175px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <StyledText variant="body" color="ongrey">
                          {field[0]}
                        </StyledText>
                      </div>
                      <div
                        style={{
                          padding: '0.05rem',
                          marginLeft: '50px',
                          width: '175px',
                        }}
                      >
                        <StyledText variant="body" color="ongrey">
                          {field[1]}
                        </StyledText>
                      </div>
                    </div>
                  </Grid>
                )
              })}
            </div>
          </div>
          <div style={{ height: '1rem' }} />
          <StyledText
            variant="body"
            color="ongrey"
            style={{
              fontStyle: 'italic',
              fontFamily: defaultTheme.fonts.default.regular,
            }}
          >
            Note - "started" status means that report filling has started and
            saved but not yet submitted.
          </StyledText>
        </Container>
      </div>
      <Container>
        <Grid container spacing={2} direction="column" justify="center">
          <Grid item>
            <div style={{ height: '1rem' }} />
          </Grid>
          <Grid item>
            <Table>
              <TableHead>
                <TableRow>
                  {/* <HeaderCell onClick={handleSortByOrganization}>
                    <div style={{ display: "flex" }}>
                      <StyledText variant="headline3" color="secondary">
                        Organization
                      </StyledText>
                      <div style={{ width: "1rem" }}></div>
                      <ExpandMoreIcon />
                    </div>
                  </HeaderCell> */}
                  <HeaderCell onClick={() => handleSortingChange('quarter')}>
                    <div style={{ display: 'flex' }}>
                      <StyledText variant="headline3" color="secondary">
                        Quarter
                      </StyledText>
                      <div style={{ width: '0.5rem' }}></div>
                      <SortDirectionArrow
                        direction={
                          sortField === 'quarter' ? sortDirection : 'ascending'
                        }
                        enabled={sortField === 'quarter'}
                      />
                    </div>
                  </HeaderCell>
                  <HeaderCell
                    onClick={() => handleSortingChange('reportingStatus')}
                  >
                    <div style={{ display: 'flex' }}>
                      <StyledText variant="headline3" color="secondary">
                        Status
                      </StyledText>
                      <div style={{ width: '0.5rem' }}></div>
                      <SortDirectionArrow
                        direction={
                          sortField === 'reportingStatus'
                            ? sortDirection
                            : 'ascending'
                        }
                        enabled={sortField === 'reportingStatus'}
                      />
                    </div>
                  </HeaderCell>
                </TableRow>
              </TableHead>
              {!isFetching && !error && (
                <TableBody>
                  {sortedReports &&
                    sortedReports.map((report: RoyaltyReport) => {
                      let reportStatusText = ''
                      let reportStatusColor = 'red'
                      switch (report.reportingStatus) {
                        case 'not_started':
                          reportStatusText = 'Not started'
                          reportStatusColor = 'red'
                          break
                        case 'started':
                          reportStatusText = 'Started'
                          reportStatusColor = 'rgb(255,207, 101)'
                          break
                        case 'received':
                          reportStatusText = 'Sent'
                          reportStatusColor = 'rgb(55, 207, 126)'
                          break
                      }
                      const linkPath = `/forms/${report.pipedriveDealId}/${report.year}/${report.quarter}`
                      return (
                        <React.Fragment>
                          <AlternatingTableRow>
                            {/* <TableCell onClick={() => handleLink(linkPath)}>
                              <StyledText variant="body" color="primary">
                                {report.organizationName}
                              </StyledText>
                            </TableCell> */}
                            <TableCell onClick={() => handleLink(linkPath)}>
                              <StyledText variant="body" color="primary">
                                {report.year}/Q{report.quarter}
                              </StyledText>
                            </TableCell>
                            <TableCell onClick={() => handleLink(linkPath)}>
                              <Chip
                                variant="outlined"
                                style={{
                                  borderColor: reportStatusColor,
                                  backgroundColor: 'white',
                                }}
                                label={
                                  <StyledText
                                    variant="body"
                                    color="primary"
                                    style={{ color: reportStatusColor }}
                                  >
                                    {reportStatusText}
                                  </StyledText>
                                }
                              ></Chip>
                              {/* <StyledText variant="headline3" color="primary" style={{color: reportStatusColor}}>{reportStatusText}</StyledText> */}
                            </TableCell>
                          </AlternatingTableRow>
                          {/* <DetailsTableRow>
                        <TableCell colSpan={5}>
                          <Typography>Details</Typography>
                        </TableCell>
                      </DetailsTableRow> */}
                        </React.Fragment>
                      )
                    })}
                </TableBody>
              )}
            </Table>
          </Grid>
          {isFetching && (
            <Grid item style={{ height: '100vh' }}>
              <Grid container justify="center">
                <CircularProgress
                  color="primary"
                  style={{
                    marginTop: 'auto',
                    alignSelf: 'center',
                    padding: '1rem',
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  )
}
