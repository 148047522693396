/** @jsx jsx */
import React, { useState, useEffect, useReducer } from 'react'
import {
  Typography,
  makeStyles,
  Grid,
  FormControl,
  TextField,
  Paper,
  Button,
  Input,
  InputLabel,
  FormHelperText,
  Toolbar,
  AppBar,
  useTheme,
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import tr from './../translations'
import { requestToken } from './../actions/auth'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { RootState } from './../reducers'
import { css, jsx } from '@emotion/core'
import WirepasLogo from '../wirepas-logo'
import * as request from './../lib/request'
import { defaultTheme } from './../wirepas_theme'
import { StyledText, NotificationBar, NotificationBarVariant } from './Elements'
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    backgroundColor: defaultTheme.colors.background,
    //backgroundImage: `url(${defaultTheme.backgroundImage})`,
    //backgroundSize: 'cover'
  },
  centered: {
    margin: 'auto',
  },
  loginPaper: {
    width: '22rem',
    margin: 'auto',
    padding: theme.spacing(8),
  },
  formField: {},
}))
type LoginFormData = {
  email: string
  password: string
}
interface ForgotState {
  isWorking: boolean
  notificationMessage?: string
  notificationType?: NotificationBarVariant
}
const initialState: ForgotState = {
  isWorking: false,
  notificationMessage: '',
}
const reducer = (state: any, action: any): ForgotState => {
  switch (action.type) {
    case 'TRY_SEND_EMAIL':
      return {
        isWorking: true,
        notificationMessage: '',
      }
    case 'ERROR':
      return {
        isWorking: false,
        notificationType: 'error',
        notificationMessage: action.error,
      }
    case 'SUCCESS':
      return {
        isWorking: false,
        notificationType: 'success',
        notificationMessage: 'Password reset email sent',
      }
    case 'CLOSE_NOTIFICATION':
      return {
        ...state,
        notificationType: '',
      }
    default:
      return { ...state }
  }
}
export default function Forgot() {
  const classes = useStyles()
  const token = useSelector((state: RootState) => state.auth.token)
  const loginError = useSelector((state: RootState) => state.auth.error)
  const { register, handleSubmit, control, getValues } =
    useForm<LoginFormData>()
  const onSubmit = async (data: LoginFormData) => {
    const { email } = getValues()
    dispatch({ type: 'TRY_SEND_EMAIL', email })
    try {
      const { data, success, error } = await request.post(
        '/api/passwordReset',
        { email }
      )
      if (!success) {
        throw new Error(error)
      }
      dispatch({ type: 'SUCCESS' })
    } catch (err) {
      dispatch({ type: 'ERROR', error: err.message })
    }
  }
  const theme = useTheme()
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <div className={classes.root}>
      {state.notificationMessage && state.notificationType && (
        <NotificationBar
          relative={false}
          variant={state.notificationType}
          onClose={() => {
            dispatch({ type: 'CLOSE_NOTIFICATION' })
          }}
        >
          {state.notificationMessage}
        </NotificationBar>
      )}
      <Paper className={classes.loginPaper}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" spacing={1}>
            <Grid item style={{ textAlign: 'center' }}>
              <WirepasLogo width="300px" fill={defaultTheme.colors.mainLogo} />
            </Grid>
            {state.isWorking && (
              <Grid item>
                <StyledText variant="body" color="primary">
                  Sending password reset email
                </StyledText>
              </Grid>
            )}
            {!state.isWorking && (
              <React.Fragment>
                <Grid item>
                  <StyledText variant="body" color="primary">
                    Please provide the email address of the invoicing contact
                    person for your company.
                  </StyledText>
                </Grid>
                <Grid item>
                  <Controller
                    as={
                      <TextField
                        label={tr('_email')}
                        placeholder={tr('_email')}
                        type="text"
                        fullWidth
                      ></TextField>
                    }
                    name="email"
                    control={control}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    style={{
                      backgroundColor: defaultTheme.colors.primary,
                      padding: '1rem',
                      fontFamily: defaultTheme.fonts.default.regular,
                    }}
                  >
                    <StyledText variant="button" color="highlight">
                      Send verification email
                    </StyledText>
                  </Button>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </form>
      </Paper>
    </div>
  )
}
