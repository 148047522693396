/** @jsx jsx */
import React, { useState, useEffect, PropsWithChildren } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  makeStyles,
  Grid,
  Button,
  TextareaAutosize,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
} from '@material-ui/core'
import tr from './../translations'
import { useForm } from 'react-hook-form'
import { css, jsx } from '@emotion/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import { RootState } from '../reducers'
import { useSelector, useDispatch } from 'react-redux'
import { fetchUserSelf } from '../actions/user'
import * as request from './../lib/request'
import { fetchReports } from '../actions/reports'
import { Logger, Logger as logger } from './../lib/logger'
import { defaultTheme, defaultTheme as wirepasTheme } from './../wirepas_theme'
import {
  StyledText,
  NotificationBarVariant,
  OptionalTooltip,
  AutoresizingSelect,
  AutoresizingInputField,
} from './Elements'
import HelpIcon from '@material-ui/icons/HelpOutline'
import { fetchSettings } from '../actions/settings'
import { showNotification, closeNotification } from '../actions/app'
import {
  calculateRowActuals,
  calculateUsedPrepaids,
  fetchRoyaltyTypesForDeal,
} from './../lib/report'
import IconPlus from '@material-ui/icons/AddOutlined'
import IconMinus from '@material-ui/icons/Remove'
import RoyaltyFormHeaderAdmin from './RoyaltyFormHeaderAdmin'
import RoyaltyFormHeaderUser from './RoyaltyFormHeaderUser'
import { Map as ImmutableMap } from 'immutable'
import { isOptionDisabled } from 'react-select/src/builtins'
import { mdiApplicationEditOutline } from '@mdi/js'
import MdiIcon from '@mdi/react'
import { getDealChangeLog, getDealPrepaids } from '../actions/deal'
//import Select from 'react-select'
const Table: React.SFC<PropsWithChildren<{}>> = (props) => (
  <table style={{ margin: '0 auto' }} cellSpacing="0" cellPadding="0">
    {props.children}
  </table>
)
const TableRow: React.SFC<PropsWithChildren<{}>> = (props) => (
  <tr style={{ margin: 0, padding: 0 }}>{props.children}</tr>
)
const TableCell: React.SFC<PropsWithChildren<{}>> = (props) => (
  <td style={{ display: 'table-cell', padding: 0 }}>{props.children}</td>
)
const COL_WIDTH_PROJECT_NAME = '10rem'
const COL_WIDTH_DIVIDER = 0 //"1rem"
const COL_WIDTH_PRODUCT_CODE = undefined //"100%"
const COL_WIDTH_VALUE = '4rem' // For actuals/forecast values for each month
const NUM_REPORTING_MONTHS = 24
const quarters = ['1st quarter', '2nd quarter', '3rd quarter', '4th quarter']
interface FieldOptions {
  register: any
  name: any
  onChange: any
  readOnly: any
  width?: string
  error?: any | undefined
  altWidth?: boolean
  noBorder?: boolean
  onlyDigits?: boolean
  useAutoresizingInputField?: boolean
}
const createField = (options: FieldOptions) => {
  const {
    register,
    name,
    onChange,
    readOnly,
    width,
    error,
    altWidth,
    noBorder,
    useAutoresizingInputField,
  } = options
  const style: any = {
    fontSize: 16,
    height: '100%',
    margin: '0.05rem',
    minHeight: '2rem',
    boxSizing: 'border-box',
    fontFamily: defaultTheme.fonts.default.regular,
    padding: '0.25rem', //padding: "0.5rem", // Padding must be same on this and above
    width: altWidth ? '100%' : width,
    //eborder: error === undefined ? 'inherit' : '2px solid red'
  }
  // For productCode field
  if (noBorder) {
    style.border = 'none'
    style['&:focus'] = {
      outline: 'none',
      border: 'none',
    }
    style.textAlign = 'center'
  }
  const fieldProps = {
    name,
    //value={fields[name]}
    ref: register,
    readOnly,
    // // @ts-ignore
    // css={css`
    //   font-size: 13px;
    //   padding-left: 0.25rem;
    //   margin-right: 0.05rem;
    //   height: 24px;
    //   width: 100%;
    //   font-family: Montserrat;
    //   line-height: 120%;
    //   &:focus {
    //     outline: none !important;
    //     border:1px solid darkgray;
    //   }
    //   &:hover {
    //     outline: solid 1px gray;
    //   }
    // `}
    style,
    onChange,
  }
  if (useAutoresizingInputField) {
    return (
      <AutoresizingInputField
        innerRegister={{ register, name }}
        {...fieldProps}
        css={
          noBorder
            ? css`
                &:focus {
                  outline: none;
                }
              `
            : ''
        }
      ></AutoresizingInputField>
    )
  } else {
    return (
      <input
        {...fieldProps}
        css={
          noBorder
            ? css`
                &:focus {
                  outline: none;
                }
              `
            : ''
        }
      ></input>
    )
  }
}
const createDropdownField = (
  register: any,
  control: any,
  name: any,
  onChange: any,
  isDisabled: boolean,
  selectionValues: any[],
  displayAdapter: (value: any) => any
) => {
  return (
    <div
      style={{
        margin: 0,
        padding: '0.05rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* <Select options={options} ref={register}
        disabled={userRole === "admin"}
        css={{
          fontSize: 13,
          boxSizing: "border-box",
          fontFamily: "Montserrat",
          margin: 0,
          padding: 0,
          //padding: "0.25rem", // Padding must be same on this and above
          width: "100%",
          //maxWidth: "12rem",
        }}
        onChange={onChange}>

      </Select> */}
      {/* <Controller
        as={<Select options={options}
        disabled={userRole === "admin"}
        css={{
          fontSize: 13,
          boxSizing: "border-box",
          fontFamily: "Montserrat",
          margin: 0,
          padding: 0,
          //padding: "0.25rem", // Padding must be same on this and above
          width: "100%",
          //maxWidth: "12rem",
        }}
        onChange={onChange}> */}

      {/* <Controller
        as={
          <Select options={options}
        disabled={userRole === "admin"}
        css={{
          fontSize: 13,
          boxSizing: "border-box",
          fontFamily: "Montserrat",
          margin: 0,
          padding: 0,
          //padding: "0.25rem", // Padding must be same on this and above
          width: "100%",
          //maxWidth: "12rem",
        }}
        >

      </Select>
        }
        onChange={onChange}
        name={name}
        control={control}
      /> */}
      <AutoresizingSelect
        name={name}
        //value={fields[name]}
        innerRegister={{ register, name }}
        disabled={isDisabled}
        style={{
          fontSize: '16px',
          minHeight: '2rem',
          boxSizing: 'border-box',
          fontFamily: defaultTheme.fonts.default.regular,
          margin: 0,
          padding: '0.25rem', // Padding must be same on this and above
          width: '100%',
          backgroundColor: 'white',
          //maxWidth: "12rem",
        }}
        defaultValue={
          selectionValues && selectionValues.length > 0 && selectionValues[0]
        }
        onChange={onChange}
      >
        {selectionValues.map((value) => {
          return (
            <option
              style={{
                fontSize: 12,
                boxSizing: 'border-box',
                fontFamily: defaultTheme.fonts.default.regular,
              }}
              value={value}
            >
              {displayAdapter(value)}
            </option>
          )
        })}
      </AutoresizingSelect>
    </div>
  )
}
function royaltyTypeDisplayAdapter(
  value: any,
  royaltyTypes: undefined | any[]
) {
  if (!royaltyTypes) {
    return value
  }
  const rt = royaltyTypes.find((x) => x.name == value)
  return rt ? rt.name : value
}
const useStyles = makeStyles((theme) => ({
  tableCell: {
    padding: 0,
    borderBottom: 'none',
  },
  tableHeaderCell: {
    borderBottom: 'none',
    //padding:0,
    margin: 0,
    padding: '0.25rem',
    width: '5rem',
  },
  headerCellEstimate: {
    border: 'none',
    backgroundColor: wirepasTheme.colors.forecast,
    padding: theme.spacing(1),
  },
  headerCellActual: {
    border: 'none',
    backgroundColor: wirepasTheme.colors.actuals,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  headerCellPrevious: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  focusedDataField: {
    outline: 'none',
  },
  dataField: {
    fontSize: '16px',
    height: '50px',
    width: '100%',
    border: 'none', //"solid 1px "+theme.palette.primary.main,
    textAlign: 'center',
    '&:focus': {
      outline: 'none',
    },
  },
  textAreaField: {
    fontSize: '16px',
    height: '50px',
    width: '100%',
    border: 'none', //"solid 1px "+theme.palette.primary.main,
    textAlign: 'center',
    '&:focus': {
      outline: 'none',
    },
  },
  root: {
    width: '100%',
    height: '100%',
  },
  header: {
    marginTop: theme.spacing(3),
  },
  textEstimate: {
    fontWeight: 'bold',
  },
  textActual: {
    fontWeight: 'bold',
  },
  formField: {
    border: 'none',
    '&$focus': {
      backgroundColor: 'black',
    },
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  input: {
    textAlign: 'center',
  },
  commentField: {
    minHeight: '100px',
    width: '100%',
  },
}))
export interface RoyaltyFormRow {
  projectName: string
  royaltyType: string
  unitPrice: number
  entries: number[]
  yearlyEstimate: number
}
interface RoyaltyReportFormProps {
  confirmLeave?: () => boolean
}
const digit_re = /^[\d.,]+$/
function validateForm(
  values: Record<string, any>,
  rowLimit: number
): Map<string, string> {
  let ret: Map<string, string> = new Map()
  for (let key in values) {
    try {
      // Only volume fields are validated
      if (!key.includes('month')) {
        continue
      }
      const startToken = key?.match(/row (\d+)/)
      if (startToken === null) {
        continue
      }
      const row = parseInt(startToken[1])
      if (
        (row < rowLimit && values[key] === 'undefined') ||
        values[key] === ''
      ) {
        ret.set(key, 'Please fill in all numbers of units, even if it was “0”.')
      } else if (row < rowLimit && !digit_re.test(values[key])) {
        ret.set(key, 'Only digits allowed')
      }
    } catch (err) {}
  }
  return ret
}
export default function RoyaltyReportForm() {
  const classes = useStyles()
  const updateNotification = (
    message: string,
    type: NotificationBarVariant
  ) => {
    dispatch(showNotification(message, type))
  }
  const { year, quarter, dealId } = useParams<any>()
  const authToken = useSelector((state: RootState) => state.auth.token)
  const userRole = useSelector((state: RootState) => state.auth.userRole)
  const prepaidRoyalties =
    useSelector((state: RootState) => state.deals.prepaidRoyalties) || 0
  const prepaidChangeLog = useSelector(
    (state: RootState) => state.deals.changeLog
  )
  const [formHasUnsavedChanges, setFormHasUnsavedChanges] =
    useState<boolean>(false)
  const months: string[] = [
    tr('_jan'),
    tr('_feb'),
    tr('_mar'),
    tr('_apr'),
    tr('_may'),
    tr('_jun'),
    tr('_jul'),
    tr('_aug'),
    tr('_sep'),
    tr('_oct'),
    tr('_nov'),
    tr('_dec'),
  ]
  const { register, getValues, setValue, reset, control, watch } = useForm()
  const [NUM_ROWS, setNUM_ROWS] = useState<number>(1)
  const quarterNum = parseInt(quarter as string)
  const yearNum = parseInt(year as string)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchUserSelf())
    dispatch(closeNotification())
    dispatch(getDealPrepaids(Number(dealId)))
    dispatch(getDealChangeLog(Number(dealId)))
  }, [dispatch])
  // General report data (title, organization...) for displaying to user
  const [dealOrganization, setDealOrganization] = useState('')
  const [dealTitle, setDealTitle] = useState('')
  const [reportReminderCount, setReportReminderCount] = useState('')
  const [, setReportAnswered] = useState('')
  const [dealContactName, setDealContactName] = useState('')
  const [dealContactEmail, setDealContactEmail] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [reportingStatus, setReportingStatus] = useState('')
  const [confirmationDialogTitle, setConfirmationDialogTitle] = useState('')
  const [adminOverride, setAdminOverride] = useState<boolean>(false)
  const [prepaidProductSelected, setPrepaidProductSelected] =
    useState<boolean>(false)
  const [dealPrepaidCounterMax, setDealPrepaidCounterMax] = useState<number>(0)
  const [dealPrepaidCounterCurrent, setDealPrepaidCounterCurrent] =
    useState<number>(0)
  const [currentActualsSum, setCurrentActualsSum] = useState<number>(0)
  // Fetch support contact person email / phone
  useEffect(() => {
    dispatch(fetchSettings())
  }, [])
  // Hack for updating react-form-hooks values
  const [royaltyTypes, setRoyaltyTypes] =
    useState<Array<{ productId: number; name: string; productCode: string }>>()
  const [validationErrors, setValidationErrors] =
    useState<Map<string, string>>()
  //const [] = useState<ImmutableMap<string,string>>(ImmutableMap())
  async function fetchReport(
    dealId: number | string,
    year: number | string,
    quarter: number | string
  ) {
    setIsFetching(true)
    try {
      const { data, success, error } = await request.get(
        `/api/forms/${dealId}/${year}/${quarter}`,
        authToken
      )
      if (!success) {
        throw new Error(error)
      }
      if (!data.report.entries) data.report.entries = []
      logger.debug('report data', data)
      if (data && data.report) {
        const dbvalues: any = {}
        //   let kvarray : any[] = []
        //   if(data.report && data.report.products && Array.isArray(data.report.products)) {
        //   data.report.products.forEach((product : any) => {
        //     logger.debug('product', product.name, product.productCode)
        //     if(product.name && product.productCode) {
        //       kvarray.push(product.name)
        //       kvarray.push(product.productCode)
        //     }
        //   })
        //   logger.debug('immutable map', ImmutableMap.of.apply(kvarray))
        //   setProductInfo(ImmutableMap.of.apply(kvarray))
        // } else {
        //   setProductInfo(ImmutableMap())
        // }
        setDealOrganization(data.report.organizationName)
        setDealTitle(data.report.dealTitle)
        setReportReminderCount(data.report.reminderCount)
        setReportAnswered(data.report.answered)
        setReportingStatus(data.report.reportingStatus)
        setDealContactName(data.report.contacts[0].contactName)
        setDealContactEmail(data.report.contacts[0].contactEmail)
        setDealPrepaidCounterMax(data.report.prepaidCounterMax)
        setDealPrepaidCounterCurrent(data.report.prepaidCounterCurrent)
        logger.debug('data report entries', data.report.entries)
        logger.debug('data report prefilled', data.prefill)
        let hasNonEmptyCells = false
        for (let iline = 0; iline < data.report.entries.length; iline++) {
          for (
            let icell = 0;
            icell < data.report.entries[iline].entries.length;
            icell++
          ) {
            const cellvalue = data.report.entries[iline].entries[icell]
            if (
              !(
                cellvalue === undefined ||
                cellvalue === null ||
                cellvalue === ''
              )
            ) {
              hasNonEmptyCells = true
              break
            }
          }
        }
        if (!hasNonEmptyCells && Array.isArray(data.prefill.entries)) {
          data.report.entries = data.prefill.entries
        }
        const newNumRows = data.report.entries.length
          ? data.report.entries.length
          : 1
        setNUM_ROWS(newNumRows)
        const altRoyaltyTypes = new Set<string>()
        for (let irow = 0; irow < data.report.entries.length; ++irow) {
          const rowId = `row ${irow} `
          const dbrow = data.report.entries[irow]
          dbvalues[rowId + 'projectName'] = dbrow.projectName
          dbvalues[rowId + 'royaltyType'] = dbrow.royaltyType
          if (typeof dbrow.royaltyType === 'string' && dbrow.royaltyType) {
            altRoyaltyTypes.add(dbrow.royaltyType)
          }
          dbvalues[rowId + 'unitPrice'] = dbrow.unitPrice
          dbvalues[rowId + 'projectName'] = dbrow.projectName
          for (let imonth = 0; imonth < NUM_REPORTING_MONTHS; ++imonth) {
            dbvalues[rowId + 'month ' + imonth] =
              dbrow.entries[imonth] !== undefined ? dbrow.entries[imonth] : ''
          }
          setTimeout(
            () =>
              onUncontrolledInputChange({
                target: { name: rowId + 'month 0' },
                source: 'reset',
              }),
            1
          )
        }
        dbvalues['commentsField'] =
          data && data.report && data.report.comments
            ? data.report.comments
            : ''
        reset(dbvalues)
        await _fetchRoyaltyTypesForDeal(Array.from(altRoyaltyTypes))
        updateSubtotals(newNumRows)
        updateProductCodes(royaltyTypes)
      }
    } catch (err) {
      setReportError(err.message)
      dispatch(showNotification(err.message, 'error'))
    }
    setIsFetching(false)
  }
  async function _fetchRoyaltyTypesForDeal(altRoyaltyTypes: string[]) {
    try {
      const pipedriveRoyaltyTypes =
        (await fetchRoyaltyTypesForDeal(
          parseInt('' + dealId),
          '' + authToken
        )) || []

      // Add empty option
      const royaltyTypeList = [
        {
          productId: -1,
          name: 'Royalty - ',
          productCode: '',
        },
        ...pipedriveRoyaltyTypes,
      ]
      if (process.env.REACT_APP_USE_MOCK_DATA) {
        royaltyTypeList.push({
          name: 'Royalty - Example very long royalty type name aaaaaaa last',
          productCode: '86712367812687213',
          productId: 767,
        })
      }
      const oldRoyaltyTypes = altRoyaltyTypes.map((r) => ({
        productId: 0,
        name: 'Royalty - ' + r,
        productCode: '',
      }))
      // <select> can't display values that aren't in its options so we have
      // to add previously entered values in case they are no longer defined in
      // Pipedrive.
      for (let oldType of oldRoyaltyTypes) {
        if (!royaltyTypeList.find((t) => t.name === oldType.name)) {
          royaltyTypeList.push(oldType)
        }
      }
      // royaltyTypeList.push({
      //   productId: 0, name: 'Royalty - Debug', productCode: 'x'
      // })
      setRoyaltyTypes(royaltyTypeList)
    } catch (err) {
      logger.debug(err.message)
    }
  }
  useEffect(() => {
    async function _fetcher() {
      if (dealId !== undefined && year !== undefined && quarter !== undefined) {
        logger.debug('fetch report', dealId, year, quarter)
        //await _fetchRoyaltyTypesForDeal()
        await fetchReport(dealId, year, quarter)
      }
    }
    _fetcher()
  }, [dealId, year, quarter])
  const [, setReportError] = useState<string>('')
  const [dialogResolveReject, setDialogResolveReject] = useState<any[]>([])
  const handleDialogConfirm = () => {
    const [resolve] = dialogResolveReject
    if (resolve) {
      resolve(true)
      setDialogResolveReject([])
    }
  }
  const handleDialogCancel = () => {
    const [resolve] = dialogResolveReject
    if (resolve) {
      resolve(false)
      setDialogResolveReject([])
    }
  }
  const handleGenerateInvoice = async () => {
    await handleSubmit(false, {
      invoicingStatus: 'invoiced',
    })
  }
  const handleSkipInvoicing = async () => {
    await handleSubmit(false, {
      invoicingStatus: 'not_invoiced',
    })
  }
  const handleSave = async () => {
    await handleSubmit(false)
  }
  const handleRevert = async () => {
    await handleSubmit(false, { revert: true })
  }
  const history = useHistory()
  const handleCancel = async () => {
    if (formHasUnsavedChanges) {
      setConfirmationDialogTitle(
        'The report has some unsaved changes. Do you want to quit without saving?'
      )
      const isConfirm = await new Promise<boolean>((resolve, reject) => {
        setDialogResolveReject([resolve, reject])
      })
      if (isConfirm) {
        history.push('/reports')
        dispatch(fetchReports())
      }
    } else {
      history.push('/reports')
      dispatch(fetchReports())
    }
  }
  const handleEnableAdminEditing = () => {
    setAdminOverride(true)
  }
  const handleSubmit = async (
    isFinal: boolean,
    extraParams: {
      invoicingStatus?: 'invoiced' | 'not_invoiced'
      revert?: boolean
    } = {}
  ) => {
    const values = getValues()
    if (isFinal) {
      const validationResults = validateForm(values, NUM_ROWS)
      setValidationErrors(validationResults)
      if (validationResults.size !== 0) {
        dispatch(
          showNotification(
            'Please fix validation errors before submitting',
            'error'
          )
        )
        return
      }
      setConfirmationDialogTitle(
        'Please confirm you wish to submit your report. Modifications are disabled after submitting.'
      )
      const isConfirm = await new Promise<boolean>((resolve, reject) => {
        setDialogResolveReject([resolve, reject])
      })
      if (!isConfirm) {
        return
      }
    } else if (extraParams && extraParams.revert) {
      setConfirmationDialogTitle(
        'Please confirm you wish to revert the report status from submitted back to started. This will enable you to edit the contents of an already submitted report.'
      )
      const isConfirm = await new Promise<boolean>((resolve, reject) => {
        setDialogResolveReject([resolve, reject])
      })
      if (!isConfirm) {
        return
      }
    }
    setReportError('')
    dispatch(closeNotification())
    setIsFetching(true)
    try {
      const results: RoyaltyFormRow[] = []
      for (let irow = 0; irow < NUM_ROWS; ++irow) {
        const rowId = `row ${irow} `
        // Changed: estimate column removed
        // if (
        //   values[rowId + "yearlyEstimate"] == "" ||
        //   values[rowId + "yearlyEstimate"] === undefined ||
        //   values[rowId + "yearlyEstimate"] === null
        // ) {
        //   continue;
        // }
        try {
          let thisrow: RoyaltyFormRow = {
            projectName: values[rowId + 'projectName'],
            royaltyType: values[rowId + 'royaltyType'],
            unitPrice: values[rowId + 'unitPrice'],
            yearlyEstimate: values[rowId + 'yearlyEstimate'],
            entries: [],
          }
          let entries = new Array(NUM_REPORTING_MONTHS)
          for (let imonth = 0; imonth < NUM_REPORTING_MONTHS; ++imonth) {
            entries[imonth] = values[rowId + 'month ' + imonth]
          }
          thisrow.entries = entries
          logger.debug('valid row', thisrow)
          results.push(thisrow)
        } catch (ex) {}
      }
      logger.debug('submitting', results)
      let requestUrl
      let body = {}
      if (extraParams.invoicingStatus) {
        requestUrl = `/api/forms/${dealId}/${year}/${quarter}/invoice?invoiceStatus=${extraParams.invoicingStatus}`
      } else {
        requestUrl = `/api/forms/${dealId}/${year}/${quarter}?isFinal=${isFinal}`
        body = {
          entries: results,
          usedPrepaids: prepaidProductSelected
            ? calculateUsedPrepaids(results)
            : null,
          comments: values.commentsField || '',
        }
      }
      const { data, success, error } = await request.post(
        requestUrl,
        body,
        authToken
      )
      logger.debug('invoice response', data, success, error)
      if (!success) {
        dispatch(showNotification(error, 'error'))
        setReportError(error)
      } else {
        Logger.debug('extraParams', extraParams)
        if (extraParams.invoicingStatus === 'invoiced') {
          updateNotification('Invoice generated', 'success')
        } else if (extraParams.invoicingStatus === 'not_invoiced') {
          updateNotification('Invoicing status updated', 'success')
        } else if (isFinal) {
          updateNotification(
            'Royalty report sent. Thank you for your declaration',
            'success'
          )
        } else {
          updateNotification('Royalty report saved', 'success')
        }
      }
      // Changed: now not sending directly to procountor
      //if(data && data.invoice && !data.invoice.success) {
      //  setReportError(`Error while generating invoice: ${data.invoice.error}`)
      //}
      setFormHasUnsavedChanges(false)
    } catch (err) {
      updateNotification(
        `Could not save form data due to internal server error. (${err.message})`,
        'error'
      )
    }
    setIsFetching(false)
    if (dealId !== undefined && year !== undefined && quarter !== undefined) {
      logger.debug('fetch report', dealId, year, quarter)
      dispatch(getDealPrepaids(Number(dealId))) // Get up-to-date prepaids amount
      fetchReport(dealId, year, quarter)
    }
  }
  const calculateQuarterSum = (
    values: any,
    quarterIndex: number,
    numRowsOverride?: number
  ) => {
    const numRows = numRowsOverride !== undefined ? numRowsOverride : NUM_ROWS
    let sum = 0
    const startIndex = quarterIndex * 3
    for (let icol = startIndex; icol < startIndex + 3; ++icol) {
      for (let irow = 0; irow < numRows; ++irow) {
        const fieldName = `row ${irow} month ${icol}`
        if (values[fieldName] === undefined || values[fieldName] === '') {
          continue
        }
        sum += parseFloat(values[fieldName])
      }
    }
    return sum
  }
  const calculateMonthlySum = (
    values: any,
    monthIndex: number,
    numRowsOverride?: number
  ) => {
    const numRows = numRowsOverride !== undefined ? numRowsOverride : NUM_ROWS
    let sum = 0
    for (let irow = 0; irow < numRows; ++irow) {
      const fieldName = `row ${irow} month ${monthIndex}`
      if (values[fieldName] === undefined || values[fieldName] === '') {
        continue
      }
      sum += parseFloat(values[fieldName])
    }
    return sum
  }
  const updateSubtotals = (numRowsOverride?: number, row?: number) => {
    const numQuarters = Math.floor(NUM_REPORTING_MONTHS / 3)
    for (let iquarter = 0; iquarter < numQuarters; ++iquarter) {
      setTimeout(() => {
        const quarterSum = calculateQuarterSum(
          getValues(),
          iquarter,
          numRowsOverride
        )
        setValue(
          `quarter${iquarter} sum`,
          calculateQuarterSum(getValues(), iquarter, quarterSum)
        )
        // Update actuals sum for calculating prepaid counter
        if (iquarter === 0) {
          const isPrepaid = determinePrepaidRoyalty(
            `row ${row} royaltyType`,
            getValues()
          )

          if (isPrepaid) {
            const rowActuals = calculateRowActuals(getValues(), NUM_ROWS)

            setValue(
              `row ${row} prepaidRoyalties`,
              prepaidRoyalties - rowActuals
            )
          }
          setCurrentActualsSum(quarterSum)
        }
      }, 10)
    }
    for (let imonth = 0; imonth < NUM_REPORTING_MONTHS; ++imonth) {
      setTimeout(
        () =>
          setValue(
            `month${imonth} sum`,
            calculateMonthlySum(getValues(), imonth, numRowsOverride)
          ),
        10
      )
    }
  }
  //
  const isEditingAllowed =
    userRole !== 'sales' &&
    (!(reportingStatus === 'received' || userRole === 'admin') ||
      (userRole === 'admin' && adminOverride && reportingStatus !== 'received'))
  const startMonth = (quarterNum - 1) * 3
  const startYear = year ? parseInt(year) : 0
  const royaltyString = 'Royalty - '
  const productString = 'Product - '
  const royaltyTypeOptions: string[] = royaltyTypes
    ? royaltyTypes
        .map((type) => type.name)
        .filter((name) => name !== undefined && name.startsWith(royaltyString))
        .map((name) => name.slice(royaltyString.length))
    : []
  const productNameOptions: string[] = royaltyTypes
    ? royaltyTypes
        .map((type) => type.name)
        .filter((name) => name !== undefined && name.startsWith(productString))
        .map((name) => name.slice(productString.length))
    : []
  const handleAddRow = () => {
    if (isEditingAllowed) {
      setNUM_ROWS(NUM_ROWS + 1)
      setTimeout(() => {
        const validationResults = validateForm(getValues(), NUM_ROWS)
        setValidationErrors(validationResults)
      }, 10)
    }
  }
  const handleFillEmptyWithZero = () => {
    const valuesNow = getValues()
    for (let irow = 0; irow < NUM_ROWS; ++irow) {
      for (let imonth = 0; imonth < NUM_REPORTING_MONTHS; ++imonth) {
        const fieldName = `row ${irow} month ${imonth}`
        if (valuesNow[fieldName] === undefined || valuesNow[fieldName] === '') {
          setTimeout(() => setValue(fieldName, 0), 10)
        }
      }
    }
  }
  const handleRemoveLastRow = () => {
    if (isEditingAllowed) {
      if (NUM_ROWS > 1) {
        setNUM_ROWS(NUM_ROWS - 1)
        setTimeout(() => {
          const validationResults = validateForm(getValues(), NUM_ROWS)
          setValidationErrors(validationResults)
        }, 10)
      }
    }
  }
  // Update: always show column
  const hideProductNameColumn = false //productNameOptions.length === 0
  const numColsBeforeMonths =
    hideProductNameColumn || !prepaidProductSelected ? 3 : 4
  const fieldValues = getValues()
  const getProductCode = (fieldId: string, fieldValues: any) => {
    try {
      //@ts-ignore
      const code = royaltyTypes?.find(
        (type: any) => type.name == 'Royalty - ' + fieldValues[fieldId]
      ).productCode
      return code
    } catch (err) {
      return ''
    }
  }
  useEffect(() => {
    updateProductCodes(royaltyTypes)
  }, [royaltyTypes])
  const determinePrepaidRoyalty = (
    fieldId: string,
    fieldValues: any
  ): boolean => {
    const product: string = fieldValues[fieldId]
    if (!product) return false
    return product.toLowerCase().includes('prepaid')
  }
  const updateProductCodes = (royaltyTypes: any) => {
    const fieldValues = getValues()
    for (let irow = 0; irow < NUM_ROWS; ++irow) {
      const productCode = getProductCode(`row ${irow} royaltyType`, fieldValues)
      const isPrepaid = determinePrepaidRoyalty(
        `row ${irow} royaltyType`,
        fieldValues
      )
      if (productCode === undefined) {
        continue
      }
      setTimeout(() => setValue(`row ${irow} productCode`, productCode), 10)
      if (isPrepaid) {
        setPrepaidProductSelected(true)
        let prepaids = prepaidRoyalties

        if (reportingStatus !== 'received') {
          const rowActuals = calculateRowActuals(getValues(), NUM_ROWS)
          prepaids -= rowActuals
        }

        setTimeout(() => setValue(`row ${irow} prepaidRoyalties`, prepaids), 10)
      }
    }
  }
  const checkForOtherPrepaidProducts = (
    fieldValues: Record<string, any>
  ): boolean => {
    for (const key of Object.values(fieldValues)) {
      const lKey = key.toLowerCase()

      if (lKey.includes('prepaid')) {
        return true
      }
    }
    return false
  }
  const onUncontrolledInputChange = (event: any) => {
    const eventSplit = event.target.name.split(' ') // -> Fieldname
    const row: number = parseInt(eventSplit[1])
    const fieldName = eventSplit[2]
    const values = getValues()

    if (fieldName === 'royaltyType') {
      const productCode = getProductCode(`row ${row} royaltyType`, values)
      const isPrepaid = determinePrepaidRoyalty(
        `row ${row} royaltyType`,
        values
      )

      setValue(`row ${row} productCode`, productCode)

      const hasPrepaids = checkForOtherPrepaidProducts(values)

      if (hasPrepaids) {
        setPrepaidProductSelected(true)
      } else {
        setPrepaidProductSelected(false)
      }

      if (isPrepaid) {
        setValue(`row ${row} prepaidRoyalties`, prepaidRoyalties)
      } else {
        setTimeout(() => {
          setValue(`row ${row} prepaidRoyalties`, null)
        }, 20)
      }
    }

    const validationResults = validateForm(getValues(), NUM_ROWS)

    setValidationErrors(validationResults)
    updateSubtotals(undefined, row)

    if (event.source !== 'reset') {
      setFormHasUnsavedChanges(true)
    }
  }
  const showSubmit =
    userRole !== 'admin' ||
    (userRole === 'admin' && reportingStatus !== 'received')
  // Do we show buttons for invoiced / not invoiced?
  // ['received', 'invoiced', 'not_invoiced'].includes(reportingStatus)
  const showInvoicingControls = userRole !== 'user'
  // If we show invoicing related buttons, should they be greyed out?
  const disableInvoicingControls = reportingStatus !== 'received'
  return (
    <div className={classes.root}>
      <Dialog
        open={dialogResolveReject.length === 2}
        onClose={handleDialogCancel}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <StyledText variant="body" color="primary">
            {confirmationDialogTitle}
          </StyledText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogConfirm}>
            <StyledText variant="body" color="primary">
              Confirm
            </StyledText>
          </Button>
          <Button onClick={handleDialogCancel}>
            <StyledText variant="body" color="primary">
              Cancel
            </StyledText>
          </Button>
        </DialogActions>
      </Dialog>

      <Grid
        container
        direction="column"
        style={{ margin: 0, padding: 0, overflow: 'hidden' }}
      >
        {isFetching && (
          <Grid item style={{ height: '100vh' }}>
            <Grid container justify="center" alignContent="center">
              <CircularProgress
                color="primary"
                style={{
                  width: '100px',
                  height: '100px',
                  marginTop: '300px',
                  alignSelf: 'center',
                }}
              />
            </Grid>
          </Grid>
        )}
        {!isFetching && (
          <React.Fragment>
            <div
              style={{
                backgroundColor: defaultTheme.colors.topBannerBackground,
                boxSizing: 'border-box',
                width: '100',
              }}
            >
              <Container>
                {userRole === 'admin' ? (
                  <RoyaltyFormHeaderAdmin
                    year={yearNum}
                    quarter={quarterNum}
                    organization={dealOrganization}
                    dealTitle={dealTitle}
                    reportReminderCount={reportReminderCount}
                    dealContactName={dealContactName}
                    dealContactEmail={dealContactEmail}
                    reportingStatus={reportingStatus}
                    handleGenerateInvoice={handleGenerateInvoice}
                    handleSkipInvoicing={handleSkipInvoicing}
                    showInvoicingControls={[
                      'received',
                      'invoiced',
                      'not_invoiced',
                    ].includes(reportingStatus)}
                    changeLog={prepaidChangeLog}
                  />
                ) : (
                  <RoyaltyFormHeaderUser
                    year={yearNum}
                    quarter={quarterNum}
                    organization={dealOrganization}
                    dealTitle={dealTitle}
                    reportReminderCount={reportReminderCount}
                    dealContactName={dealContactName}
                    dealContactEmail={dealContactEmail}
                    reportingStatus={reportingStatus}
                    changeLog={prepaidChangeLog}
                  />
                )}
              </Container>
            </div>
            {/* Validation error display */}
            {userRole !== 'admin' &&
              validationErrors &&
              validationErrors.size > 0 && (
                <div
                  style={{
                    width: '100%',
                    background: wirepasTheme.colors.notificationError,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  {Array.from(validationErrors.entries())
                    .slice(0, 3)
                    .map((entry) => {
                      return (
                        <div
                          style={{
                            width: '100%',
                            background: wirepasTheme.colors.notificationError,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '0.25rem',
                          }}
                        >
                          <StyledText variant="body" color="secondary">
                            {entry[0] + ': ' + entry[1]}
                          </StyledText>
                        </div>
                      )
                    })}
                  {validationErrors && validationErrors.size > 3 && (
                    <div
                      style={{
                        width: '100%',
                        background: wirepasTheme.colors.notificationError,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '0.25rem',
                      }}
                    >
                      <StyledText variant="body" color="secondary">
                        {`+ ${
                          validationErrors.size - 3
                        } more validation errors`}
                      </StyledText>
                    </div>
                  )}
                </div>
              )}
            <Container>
              <div>
                <Grid item style={{ overflowX: 'scroll' }}>
                  <Table>
                    <TableRow>
                      <td
                        style={{
                          padding: '1rem',
                          margin: 0,
                          textAlign: 'center',
                          width: '100%',
                        }}
                        colSpan={numColsBeforeMonths}
                      ></td>
                      <td
                        style={{
                          padding: '1rem',
                          margin: 0,
                          textAlign: 'center',
                        }}
                        colSpan={3}
                      >
                        <Tooltip
                          title={
                            <StyledText variant="body" color="secondary">
                              Number of units which were programmed with Wirepas
                              Massive software during the last three months.
                            </StyledText>
                          }
                          arrow
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                            }}
                          >
                            <StyledText
                              variant="headline3"
                              style={{
                                fontSize: 24,
                                color: wirepasTheme.colors.actualsHeader,
                              }}
                            >
                              Actuals
                            </StyledText>
                            <div style={{ width: '0.2rem' }} />
                            <HelpIcon
                              fontSize="small"
                              css={{ color: wirepasTheme.colors.helpBubble }}
                            />
                          </div>
                        </Tooltip>
                      </td>
                      <td
                        style={{
                          padding: '1rem',
                          margin: 0,
                          textAlign: 'center',
                        }}
                        colSpan={9}
                      >
                        <Tooltip
                          title={
                            <StyledText variant="body" color="secondary">
                              Your estimation of the number of units to be
                              programmed for the next three quarters. <br />
                              Values under forecast are non-binding.
                            </StyledText>
                          }
                          arrow
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                            }}
                          >
                            <StyledText
                              variant="headline3"
                              style={{
                                fontSize: 24,
                                color: wirepasTheme.colors.forecastHeader,
                              }}
                            >
                              Forecast
                            </StyledText>
                            <div style={{ width: '0.2rem' }} />
                            <HelpIcon
                              fontSize="small"
                              css={{ color: wirepasTheme.colors.textPrimary }}
                            />
                          </div>
                        </Tooltip>
                      </td>
                    </TableRow>
                    <TableRow>
                      <td
                        style={{ padding: 0, margin: 0, textAlign: 'center' }}
                        colSpan={numColsBeforeMonths}
                      />
                      {new Array(4).fill(undefined).map((_, index) => {
                        return (
                          <td
                            style={{
                              padding: '1rem',
                              margin: 0,
                              textAlign: 'center',
                              backgroundColor:
                                index === 0
                                  ? wirepasTheme.colors.actuals
                                  : wirepasTheme.colors.forecast,
                            }}
                            colSpan={3}
                          >
                            <StyledText variant="body" color="primary">
                              {quarters[(startMonth / 3 + index) % 4]}
                            </StyledText>
                          </td>
                        )
                      })}
                      {/* Extra 12 months forecast */}
                      {new Array(4).fill(undefined).map((_, index) => {
                        return (
                          <td
                            style={{
                              padding: '1rem',
                              margin: 0,
                              textAlign: 'center',
                              backgroundColor: wirepasTheme.colors.forecast,
                            }}
                            colSpan={3}
                          >
                            <StyledText variant="body" color="primary">
                              {
                                quarters[
                                  (((startMonth + 12) % 12) / 3 + index) % 4
                                ]
                              }
                            </StyledText>
                          </td>
                        )
                      })}
                    </TableRow>
                    <TableRow>
                      <td
                        style={{
                          padding: '1rem',
                          margin: 0,
                          textAlign: 'center',
                        }}
                        colSpan={numColsBeforeMonths}
                      />
                      {new Array(NUM_REPORTING_MONTHS)
                        .fill(undefined)
                        .map((_, index) => {
                          return (
                            <td
                              style={{
                                padding: 0,
                                margin: 0,
                                textAlign: 'center',
                                backgroundColor:
                                  index < 3
                                    ? wirepasTheme.colors.actuals
                                    : wirepasTheme.colors.forecast,
                              }}
                            >
                              <StyledText variant="headline3" color="primary">
                                {months[(startMonth + index) % 12]}
                              </StyledText>
                            </td>
                          )
                        })}
                    </TableRow>
                    <TableRow>
                      <td
                        style={{
                          padding: '1rem',
                          margin: 0,
                          textAlign: 'center',
                        }}
                      >
                        <StyledText variant="body" color="primary">
                          Royalty type
                        </StyledText>
                      </td>
                      {!hideProductNameColumn && (
                        <td
                          style={{
                            padding: '1rem',
                            margin: 0,
                            textAlign: 'center',
                            width: COL_WIDTH_PROJECT_NAME,
                          }}
                          colSpan={1}
                        >
                          <OptionalTooltip
                            show={true}
                            text="Name of the end customer or the specific end customer project the devices have been shipped to"
                          >
                            <div
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              <StyledText variant="body" color="primary">
                                End customer / project
                              </StyledText>
                              <div style={{ width: '0.2rem' }} />
                              <HelpIcon
                                fontSize="small"
                                css={{ color: wirepasTheme.colors.textPrimary }}
                              />
                            </div>
                          </OptionalTooltip>
                        </td>
                      )}
                      {
                        /* userRole === "admin" */ true && (
                          <td
                            style={{
                              padding: '0.25rem',
                              margin: 0,
                              textAlign: 'center',
                              //boxSizing: 'border-box',
                              width: COL_WIDTH_PRODUCT_CODE,
                            }}
                            colSpan={1}
                          >
                            <div style={{ boxSizing: 'border-box' }}>
                              <StyledText variant="body" color="primary">
                                Product code
                              </StyledText>
                            </div>
                          </td>
                        )
                      }
                      {prepaidProductSelected && (
                        <td
                          style={{
                            padding: '0.25rem',
                            textAlign: 'center',
                            display: 'block',
                            width: '100px',
                          }}
                          colSpan={1}
                        >
                          <div style={{ boxSizing: 'border-box' }}>
                            <StyledText variant="body" color="primary">
                              Prepaid royalties
                            </StyledText>
                          </div>
                        </td>
                      )}
                      {/* {userRole !== "admin" && (
                        <td>
                          <div style={{ width: COL_WIDTH_DIVIDER }}></div>
                        </td>
                      )} */}
                      {new Array(NUM_REPORTING_MONTHS)
                        .fill(undefined)
                        .map((_, index) => {
                          return (
                            <td
                              style={{
                                padding: 0,
                                margin: 0,
                                textAlign: 'center',
                                backgroundColor:
                                  index < 3
                                    ? wirepasTheme.colors.actuals
                                    : wirepasTheme.colors.forecast,
                              }}
                            >
                              <StyledText variant="body" color="primary">
                                {Math.floor(
                                  (startMonth + index) / 12 + startYear
                                )}
                              </StyledText>
                            </td>
                          )
                        })}
                    </TableRow>
                    {new Array(NUM_ROWS).fill(undefined).map((_, index) => {
                      const rowId = `row ${index} `
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {createDropdownField(
                              register,
                              control,
                              rowId + 'royaltyType',
                              onUncontrolledInputChange,
                              false, // !Boolean(isEditingAllowed),
                              royaltyTypeOptions,
                              (value: any) =>
                                royaltyTypeDisplayAdapter(value, royaltyTypes)
                            )}
                          </TableCell>
                          {!hideProductNameColumn && (
                            <td
                              style={{
                                width: 'auto', // COL_WIDTH_PROJECT_NAME,
                                boxSizing: 'border-box',
                              }}
                            >
                              {createField({
                                register,
                                name: rowId + 'projectName',
                                onChange: onUncontrolledInputChange,
                                readOnly: !Boolean(isEditingAllowed),
                                width: 'unset',
                                useAutoresizingInputField: true,
                              })}
                            </td>
                          )}
                          {/* For product code column */}
                          {/* {userRole !== "admin" && (
                            <td>
                              <div style={{ width: COL_WIDTH_DIVIDER }}></div>
                            </td>
                          )} */}
                          {
                            /* userRole === "admin" */ true && (
                              <td
                                style={{
                                  width: COL_WIDTH_PRODUCT_CODE,
                                  boxSizing: 'border-box',
                                }}
                              >
                                {/*                               <div
                                style={{
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <StyledText variant="body" color="primary">
                                  {getProductCode(rowId + "royaltyType")}
                                </StyledText>
                              </div> */}
                                {createField({
                                  register,
                                  name: rowId + 'productCode',
                                  onChange: onUncontrolledInputChange,
                                  readOnly: true,
                                  width: 'unset',
                                  noBorder: true, // no border
                                })}
                              </td>
                            )
                            // createField(
                            //   register,
                            //   rowId + "productCode",
                            //   onUncontrolledInputChange,
                            //   !Boolean(isEditingAllowed),
                            //   COL_WIDTH_PRODUCT_CODE,
                            //   false
                            // )
                          }
                          {prepaidProductSelected && (
                            <td
                              style={{
                                boxSizing: 'border-box',
                              }}
                            >
                              {createField({
                                register,
                                name: rowId + 'prepaidRoyalties',
                                onChange: onUncontrolledInputChange,
                                readOnly: true,
                                width: '100px',
                                noBorder: true, // no border
                              })}
                            </td>
                          )}
                          {new Array(NUM_REPORTING_MONTHS)
                            .fill(undefined)
                            .map((_, colIndex: number) => {
                              return (
                                <TableCell key={colIndex}>
                                  <Tooltip
                                    title={
                                      <StyledText
                                        variant="body"
                                        color="secondary"
                                      >
                                        Please fill in number of units
                                        <br />
                                        Section should contain at least "0"
                                      </StyledText>
                                    }
                                  >
                                    {createField({
                                      register,
                                      name: rowId + 'month ' + colIndex,
                                      onChange: onUncontrolledInputChange,
                                      readOnly: !Boolean(isEditingAllowed),
                                      width: COL_WIDTH_VALUE,
                                      error: validationErrors?.get(
                                        rowId + 'month ' + colIndex
                                      ),
                                    })}
                                  </Tooltip>
                                </TableCell>
                              )
                            })}
                          {isEditingAllowed && index === NUM_ROWS - 1 && (
                            <td colSpan={3} style={{ width: '5rem' }}>
                              <Button
                                variant="text"
                                disableElevation
                                disabled={!Boolean(isEditingAllowed)}
                                style={{
                                  marginLeft: '0.1rem',
                                  //backgroundColor: "transparent",
                                  color: wirepasTheme.colors.primary,
                                }}
                                onClick={handleRemoveLastRow}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    //width: "10rem",
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}
                                >
                                  <IconMinus
                                    css={{
                                      visibility:
                                        NUM_ROWS === 1 ? 'hidden' : 'visible',
                                      color: isEditingAllowed
                                        ? 'primary'
                                        : 'grey',
                                    }}
                                  />
                                  {/* <div style={{ width: "0.5rem" }} />
                              <StyledText variant="body" color="primary">
                                Remove row
                              </StyledText> */}
                                </div>
                              </Button>
                            </td>
                          )}
                        </TableRow>
                      )
                    })}
                    <TableRow>
                      <td colSpan={numColsBeforeMonths} />
                      {isEditingAllowed && (
                        <React.Fragment>
                          <td colSpan={3} style={{ width: '5rem' }}>
                            {/* Add row */}
                            <Button
                              variant="text"
                              disableElevation
                              disabled={!Boolean(isEditingAllowed)}
                              style={{
                                //marginLeft: "0.1rem",
                                //width: "12rem",
                                //backgroundColor: wirepasTheme.colors.highlight,
                                color: wirepasTheme.colors.primary,
                              }}
                              onClick={handleAddRow}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}
                              >
                                <IconPlus
                                  style={{
                                    color: isEditingAllowed
                                      ? 'primary'
                                      : 'grey',
                                  }}
                                />
                                <div style={{ width: '0.5rem' }} />
                                <StyledText
                                  variant="body"
                                  color={isEditingAllowed ? 'primary' : 'grey'}
                                >
                                  Add row
                                </StyledText>
                                <div style={{ width: '0.5rem' }} />
                              </div>
                            </Button>
                          </td>

                          <td colSpan={5} style={{ width: '9rem' }}>
                            {/* Fill empty with 0 */}
                            <Button
                              variant="text"
                              disableElevation
                              disabled={!Boolean(isEditingAllowed)}
                              style={{
                                //marginLeft: "0.1rem",
                                //width: "12rem",
                                //backgroundColor: wirepasTheme.colors.highlight,
                                color: wirepasTheme.colors.primary,
                              }}
                              onClick={handleFillEmptyWithZero}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}
                              >
                                <MdiIcon
                                  path={mdiApplicationEditOutline}
                                  size={0.7}
                                  horizontal
                                  vertical
                                  rotate={90}
                                  color="black"
                                />
                                <div style={{ width: '0.5rem' }} />
                                <StyledText
                                  variant="body"
                                  color={isEditingAllowed ? 'primary' : 'grey'}
                                >
                                  Fill empty with 0
                                </StyledText>
                                <div style={{ width: '0.5rem' }} />
                              </div>
                            </Button>
                          </td>
                        </React.Fragment>
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <div style={{ height: '1rem' }}></div>
                      </TableCell>
                    </TableRow>
                    {/* Monthly sums */}
                    <TableRow>
                      <td colSpan={numColsBeforeMonths}>
                        <StyledText variant="headline3"></StyledText>
                      </td>
                      {new Array(NUM_REPORTING_MONTHS)
                        .fill(undefined)
                        .map((_, index) => {
                          return (
                            <td
                              key={index}
                              colSpan={1}
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              {/* {createField(
                              register,
                              `quarter${index} sum`,
                              onUncontrolledInputChange,
                              true,
                              "calc(3.5rem * 3)"
                            )} */}
                              <div style={{ padding: '0.1rem', margin: 0 }}>
                                <input
                                  name={`month${index} sum`}
                                  //value={fields[name]}
                                  ref={register}
                                  readOnly={true}
                                  // // @ts-ignore
                                  // css={css`
                                  //   font-size: 13px;
                                  //   padding-left: 0.25rem;
                                  //   margin-right: 0.05rem;
                                  //   height: 24px;
                                  //   width: 100%;
                                  //   font-family: Montserrat;
                                  //   line-height: 120%;
                                  //   &:focus {
                                  //     outline: none !important;
                                  //     border:1px solid darkgray;
                                  //   }
                                  //   &:hover {
                                  //     outline: solid 1px gray;
                                  //   }
                                  // `}
                                  style={{
                                    backgroundColor:
                                      defaultTheme.colors.topBannerBackground,
                                    textAlign: 'center',
                                    border: 'none',
                                    fontSize: 16,
                                    boxSizing: 'border-box',
                                    padding: '0.25rem', // Padding must be same on this and above
                                    width: '100%',
                                  }}
                                ></input>
                              </div>
                            </td>
                          )
                        })}
                    </TableRow>

                    {/* Quarterly sums */}
                    <TableRow>
                      <td colSpan={numColsBeforeMonths}>
                        <StyledText variant="headline3">Subtotal</StyledText>
                      </td>
                      {new Array(Math.floor(NUM_REPORTING_MONTHS / 3))
                        .fill(undefined)
                        .map((_, index) => {
                          return (
                            <td
                              key={index}
                              colSpan={3}
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              {/* {createField(
                              register,
                              `quarter${index} sum`,
                              onUncontrolledInputChange,
                              true,
                              "calc(3.5rem * 3)"
                            )} */}
                              <div style={{ padding: '0.1rem', margin: 0 }}>
                                <input
                                  name={`quarter${index} sum`}
                                  //value={fields[name]}
                                  ref={register}
                                  readOnly={true}
                                  // // @ts-ignore
                                  // css={css`
                                  //   font-size: 13px;
                                  //   padding-left: 0.25rem;
                                  //   margin-right: 0.05rem;
                                  //   height: 24px;
                                  //   width: 100%;
                                  //   font-family: Montserrat;
                                  //   line-height: 120%;
                                  //   &:focus {
                                  //     outline: none !important;
                                  //     border:1px solid darkgray;
                                  //   }
                                  //   &:hover {
                                  //     outline: solid 1px gray;
                                  //   }
                                  // `}
                                  style={{
                                    backgroundColor:
                                      defaultTheme.colors.topBannerBackground,
                                    textAlign: 'center',
                                    border: 'none',
                                    fontSize: 16,
                                    boxSizing: 'border-box',
                                    padding: '0.25rem', // Padding must be same on this and above
                                    width: '100%',
                                  }}
                                ></input>
                              </div>
                            </td>
                          )
                        })}
                    </TableRow>
                  </Table>
                </Grid>
                <Grid item>
                  {/* 
                //@ts-ignore */}
                  {/* <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.tableHeaderCell}
                        hidden={true}
                        colSpan={1}
                      ></TableCell>
                      <TableCell
                        className={classes.tableHeaderCell}
                        colSpan={3}
                        align="center"
                      >
                        <StyledText variant="headline3" color="primary">
                          {tr("_Actuals")}
                        </StyledText>
                      </TableCell>
                      <TableCell
                        className={classes.tableHeaderCell}
                        colSpan={9}
                        align="center"
                      >
                        <Typography variant="h6">{tr("_Estimates")}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={classes.tableHeaderCell}
                        hidden={true}
                        colSpan={1}
                      ></TableCell>
                      <TableCell
                        className={classes.headerCellActual}
                        colSpan={3}
                        align="center"
                      >
                        <StyledText variant="body" color="primary">
                          {quarterLabels[0]}
                        </StyledText>
                      </TableCell>
                      <TableCell
                        className={classes.headerCellEstimate}
                        colSpan={3}
                        align="center"
                      >
                        <StyledText variant="body" color="primary">
                          {quarterLabels[1]}
                        </StyledText>
                      </TableCell>
                      <TableCell
                        className={classes.headerCellEstimate}
                        colSpan={3}
                        align="center"
                      >
                        <StyledText variant="body" color="primary">
                          {quarterLabels[2]}
                        </StyledText>
                      </TableCell>
                      <TableCell
                        className={classes.headerCellEstimate}
                        colSpan={3}
                        align="center"
                      >
                        <StyledText variant="body" color="primary">
                          {quarterLabels[3]}
                        </StyledText>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{tr("_project")}</TableCell>
                      {new Array(12).fill(undefined).map((_, monthIndex) => {
                        return (
                          <TableCell
                            className={
                              monthIndex < 3
                                ? classes.headerCellActual
                                : classes.headerCellEstimate
                            }
                            style={{textAlign: "center"}}
                          >
                            <StyledText variant="headline3" color="primary">
                            {months[monthIndex]}
                            </StyledText>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {new Array(NUM_ROWS).fill(undefined).map((_, index) => {
                      const rowId = `row ${index} `;
                      return (
                        <TableRow key={index}>
                          <TableCell className={classes.tableCell}>
                            {createField(
                              register,
                              rowId + "projectName",
                              onUncontrolledInputChange,
                              !Boolean(isEditingAllowed),
                              "15rem"
                            )}
                          </TableCell>
                          {new Array(12)
                            .fill(undefined)
                            .map((_, colIndex: number) => {
                              return (
                                <TableCell
                                  className={classes.tableCell}
                                  key={colIndex}
                                  style={{ margin: "0", padding: "0" }}
                                >
                                  {createField(
                                    register,
                                    rowId + "month " + colIndex,
                                    onUncontrolledInputChange,
                                    !Boolean(isEditingAllowed),
                                    "5rem"
                                  )}
                                </TableCell>
                              );
                            })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table> */}
                </Grid>
                <div style={{ height: '1rem' }} />
                <Grid item>
                  <TextareaAutosize
                    css={css`
                      border: 1px solid lightgray;
                      resize: none;
                      &:focus {
                        outline: none !important;
                        border: 1px solid;
                      }
                      ,
                      &:disabled {
                        background-color: white;
                      }
                    `}
                    disabled={!Boolean(isEditingAllowed)}
                    className={classes.commentField}
                    placeholder={
                      isEditingAllowed ? 'Enter comments here...' : ''
                    }
                    ref={register}
                    name="commentsField"
                  ></TextareaAutosize>
                </Grid>
                <div style={{ marginBottom: '110px' }} />
                {true && (
                  <Paper
                    style={{
                      position: 'fixed',
                      left: 0,
                      bottom: 0,
                      width: '100%',
                      padding: 0,
                      height: '100px',
                      alignItems: 'center',
                    }}
                    elevation={5}
                  >
                    <Container style={{ height: '100%' }}>
                      {userRole === 'admin' && !adminOverride && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                          }}
                        >
                          <Button
                            disableElevation
                            color="primary"
                            variant="contained"
                            onClick={handleEnableAdminEditing}
                            style={{
                              backgroundColor:
                                wirepasTheme.colors.notificationError,
                              marginRight: '1rem',
                            }}
                          >
                            <StyledText variant="headline3" color="secondary">
                              Enable admin editing
                            </StyledText>
                          </Button>
                        </div>
                      )}
                      {/* Cancel/Save/Submit controls */}
                      {(isEditingAllowed ||
                        (userRole === 'admin' && adminOverride)) && (
                        <div
                          style={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Button
                            disableElevation
                            color="primary"
                            variant="contained"
                            onClick={handleCancel}
                            style={{
                              backgroundColor: 'rgb(187, 188, 188)',
                              marginRight: '1rem',
                            }}
                            disabled={!Boolean(isEditingAllowed)}
                          >
                            <StyledText variant="headline3" color="primary">
                              Cancel
                            </StyledText>
                          </Button>
                          <Button
                            disableElevation
                            color="primary"
                            variant="contained"
                            onClick={handleSave}
                            style={{
                              backgroundColor: formHasUnsavedChanges
                                ? 'rgb(28, 149, 133)'
                                : wirepasTheme.colors.buttonDisabled,
                            }}
                            disabled={
                              !Boolean(isEditingAllowed) ||
                              !Boolean(
                                isEditingAllowed && formHasUnsavedChanges
                              )
                            }
                          >
                            <StyledText variant="headline3" color="secondary">
                              Save
                            </StyledText>
                          </Button>
                          <div style={{ flexGrow: 1 }} />
                          {showSubmit && (
                            <Button
                              disableElevation
                              color="primary"
                              variant="contained"
                              onClick={() => handleSubmit(true)}
                              style={{ backgroundColor: 'rgb(28, 149, 133)' }}
                              disabled={!Boolean(isEditingAllowed)}
                            >
                              <StyledText variant="headline3" color="secondary">
                                Submit
                              </StyledText>
                            </Button>
                          )}

                          {userRole === 'admin' &&
                            reportingStatus === 'received' && (
                              <Button
                                disableElevation
                                color="primary"
                                variant="contained"
                                onClick={() => handleRevert()}
                                style={{
                                  backgroundColor:
                                    wirepasTheme.colors.notificationError,
                                }}
                              >
                                <StyledText
                                  variant="headline3"
                                  color="secondary"
                                >
                                  Revert submit
                                </StyledText>
                              </Button>
                            )}
                        </div>
                      )}
                    </Container>
                  </Paper>
                )}
              </div>
            </Container>
          </React.Fragment>
        )}
      </Grid>
    </div>
  )
}
