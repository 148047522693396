import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from './../reducers'
import ReportOverviewAdmin from './ReportOverviewAdmin'
import ReportOverviewUser from './ReportOverviewUser'
import { dispatch } from 'rxjs/internal/observable/range'
import { fetchUserSelf } from '../actions/user'
import { showNotification, closeNotification } from '../actions/app'
export default function ReportOverview() {
  const dispatch = useDispatch()
  const error = useSelector((state: RootState) => state.reports.error)
  useEffect(() => {
    dispatch(fetchUserSelf())
  }, [])
  const accessLevel = useSelector((state: RootState) => state.user.role)
  useEffect(() => {
    if (error) {
      dispatch(showNotification(error, 'error'))
    } else {
      dispatch(closeNotification())
    }
  }, [error])
  return accessLevel === 'admin' || accessLevel === 'sales' ? (
    <ReportOverviewAdmin />
  ) : (
    <ReportOverviewUser />
  )
}
