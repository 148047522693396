import React, { useState } from 'react'
import { StyledText, ReportStatus, StyledButton } from './Elements'
import { Button, Grid, makeStyles } from '@material-ui/core'
import tr from './../translations'
import { useSelector } from 'react-redux'
import { RootState } from '../reducers'
import { useTheme } from './../wirepas_theme'
import ChangeLogModal from './ChangeLogModal'
import { DealChangeLog } from '../actions/deal'
const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(3),
  },
}))
interface RoyaltyFormHeaderAdminProps {
  organization: string
  dealTitle: string
  reportReminderCount: any
  dealContactName: string
  dealContactEmail: string
  reportingStatus: string
  year: number
  quarter: number
  handleGenerateInvoice: () => void
  handleSkipInvoicing: () => void
  showInvoicingControls: boolean
  changeLog: Array<DealChangeLog>
}
export default function RoyaltyFormHeaderAdmin(
  props: RoyaltyFormHeaderAdminProps
) {
  const {
    changeLog,
    organization,
    dealTitle,
    reportReminderCount,
    dealContactName,
    dealContactEmail,
    reportingStatus,
    year,
    quarter,
    handleGenerateInvoice,
    handleSkipInvoicing,
    showInvoicingControls,
  } = props
  const supportContactEmail = useSelector(
    (state: RootState) => state.settings.supportContactEmail
  )
  const supportContactPhone = useSelector(
    (state: RootState) => state.settings.supportContactPhone
  )
  const [isLogOpen, setIsLogOpen] = useState<boolean>(false)
  const classes = useStyles()
  const theme = useTheme()
  const openChangelog = () => {
    setIsLogOpen(true)
  }
  const closeChangelog = () => {
    isLogOpen && setIsLogOpen(false)
  }
  const changeLogVisible = changeLog && changeLog.length ? true : false
  return (
    <Grid container direction="column">
      <Grid item className={classes.header}>
        <StyledText
          variant="headline3"
          color="ongrey"
          style={{ fontSize: '1.5rem' }}
        >
          {tr('_Royalty_report_for')} {year}/Q{quarter}
        </StyledText>
      </Grid>
      <div style={{ height: '1rem' }} />
      <Grid item className="classes.header">
        <StyledText variant="body" color="ongrey">
          Please note, it is highly recommended to use the tool on a PC rather
          than on a mobile.
        </StyledText>
      </Grid>
      <Grid item className={classes.header}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {/* Contact person details */}
          <Grid container direction="column">
            {[
              ['Organization name:', organization],
              ['Deal title:', dealTitle],
              ['Contact name:', dealContactName],
              ['Contact email:', dealContactEmail],
              ['Reminders', reportReminderCount],
              [
                <React.Fragment>
                  <div style={{ height: '1rem' }} />
                  Report status
                </React.Fragment>,
                <React.Fragment>
                  <div style={{ height: '1rem' }} />
                  <ReportStatus chip={true} status={reportingStatus} />
                </React.Fragment>,
              ],
              changeLogVisible
                ? [
                    <React.Fragment>
                      <div style={{ height: '1rem' }} />
                      Prepaid Royalty Changelog
                    </React.Fragment>,
                    <React.Fragment>
                      <div style={{ height: '1rem' }} />
                      <Button onClick={openChangelog}>
                        <StyledText variant="button">Open changelog</StyledText>
                      </Button>
                    </React.Fragment>,
                  ]
                : [],
            ].map((field) => {
              return (
                <Grid item>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      style={{
                        padding: '0.05rem',
                        width: '175px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <StyledText variant="body" color="ongrey">
                        {field[0]}
                      </StyledText>
                    </div>
                    <div
                      style={{
                        padding: '0.05rem',
                        marginLeft: '50px',
                        width: '15rem',
                      }}
                    >
                      <StyledText variant="body" color="ongrey">
                        {field[1]}
                      </StyledText>
                    </div>
                  </div>
                </Grid>
              )
            })}
            {/* Invoicing controls */}
            {showInvoicingControls && (
              <React.Fragment>
                <div style={{ height: '1rem' }} />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <StyledButton
                    variant="onheader"
                    onClick={handleGenerateInvoice}
                  >
                    <StyledText
                      variant="body"
                      color="secondary"
                      style={{ color: theme.colors.alternateButton }}
                    >
                      Generate invoice template
                    </StyledText>
                  </StyledButton>
                  <StyledText
                    variant="body"
                    color="secondary"
                    style={{
                      color: theme.colors.alternateButton,
                      marginLeft: '1rem',
                      marginRight: '1rem',
                    }}
                  >
                    or
                  </StyledText>
                  <StyledButton
                    variant="onheader"
                    onClick={handleSkipInvoicing}
                  >
                    <StyledText
                      variant="body"
                      color="secondary"
                      style={{ color: theme.colors.alternateButton }}
                    >
                      Skip invoicing for this report
                    </StyledText>
                  </StyledButton>
                </div>
              </React.Fragment>
            )}
          </Grid>
        </div>
      </Grid>
      <Grid item style={{ height: '1rem' }} />
      {isLogOpen && <ChangeLogModal onClose={closeChangelog} />}
    </Grid>
  )
}
