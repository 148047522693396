/** @jsx jsx */
import React, {
  PropsWithChildren,
  CSSProperties,
  useEffect,
  useState,
  useRef,
  MutableRefObject,
} from 'react'
import { defaultTheme, defaultTheme as theme } from './../wirepas_theme'
import Button from '@material-ui/core/Button'
import { ReportSendStatus } from '../lib/report'
import Chip from '@material-ui/core/Chip'
import IconOk from '@material-ui/icons/CheckCircleOutline'
import IconError from '@material-ui/icons/ErrorOutline'
import IconClose from '@material-ui/icons/CloseOutlined'
import { css, jsx } from '@emotion/core'
import {
  Input,
  Menu,
  MenuItem,
  Checkbox,
  Tooltip,
  withStyles,
  TableRow,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import CheckedIcon from '@material-ui/icons/CheckBoxOutlined'
import { Map as ImmutableMap } from 'immutable'
import ArrowUp from '@material-ui/icons/ArrowUpward'
import ArrowDown from '@material-ui/icons/ArrowDownward'
import { Logger as logger } from './../lib/logger'
type StyledTextVariant =
  | 'headline1'
  | 'headline2'
  | 'headline3'
  | 'body'
  | 'display'
  | 'body2'
  | 'button'
type StyledTextColor = 'primary' | 'secondary' | 'highlight' | 'grey' | 'ongrey'
export const StyledText: React.SFC<
  PropsWithChildren<{
    variant: StyledTextVariant
    color?: StyledTextColor
    size?: string | number
    underline?: boolean
    style?: CSSProperties
    unselectable?: boolean
  }>
> = (props) => {
  const { variant, children, color, size, underline, unselectable } = props
  const style: React.CSSProperties = {
    fontFamily: defaultTheme.fonts.default.regular,
    letterSpacing: '0.05em', // tracking 50
    color:
      color === 'primary'
        ? theme.colors.primary
        : color === 'secondary'
        ? theme.colors.secondary
        : color === 'highlight'
        ? theme.colors.highlight
        : color === 'ongrey'
        ? theme.colors.textOnGrey
        : theme.colors.textGrey,
  }
  if (unselectable) {
    style.MozUserSelect = 'none'
    style.WebkitUserSelect = 'none'
    style.msUserSelect = 'none'
  }
  if (variant === 'headline1' || variant === 'headline2') {
    style.fontWeight = 'bold'
    style.fontSize = variant === 'headline1' ? 38 : 24
    style.textTransform = 'uppercase'
    style.userSelect = 'none'
  } else if (variant === 'headline3') {
    style.fontFamily = defaultTheme.fonts.default.semiBold // 'Montserrat SemiBold'
    style.fontSize = 16
    style.userSelect = 'none'
  } else if (variant === 'body') {
    style.fontWeight = 'normal'
    style.lineHeight = '125%' // leading 120%
    style.fontSize = 14
  } else if (variant === 'button') {
    style.fontFamily = defaultTheme.fonts.default.semiBold
    style.fontSize = 14
  } else if (variant === 'body2') {
    style.fontWeight = 'normal'
    style.lineHeight = '125%' // leading 120%
    style.fontSize = 16
  }
  if (underline) {
    style.borderBottom = 'solid 2px'
  }
  if (size !== undefined) {
    style.fontSize = size
  }
  return <div style={{ ...style, ...props.style }}>{children}</div>
}
StyledText.defaultProps = {
  variant: 'body',
  color: 'primary',
  underline: false,
}
type StyledButtonVariant = 'primary' | 'onheader'
export const StyledButton: React.FunctionComponent<
  PropsWithChildren<{
    variant: StyledButtonVariant
    onClick: () => void
    styleOverride?: CSSProperties
    disabled?: boolean
  }>
> = (props) => {
  const { variant, children, onClick, disabled, styleOverride } = props
  let style: CSSProperties = {}
  if (variant === 'primary') {
    style = { backgroundColor: theme.colors.primary }
  } else if (variant === 'onheader') {
    //style = {backgroundColor: theme.colors.textOnGrey}
  }
  if (disabled) {
    style.opacity = 0.25
  }
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant={variant === 'onheader' ? 'outlined' : 'contained'}
      style={{ ...style, ...styleOverride }}
    >
      {children}
    </Button>
  )
}
StyledButton.defaultProps = {
  disabled: false,
  styleOverride: {},
}
export const ReportStatus: React.SFC<{
  status: string
  chip?: boolean
}> = (props) => {
  const { status, chip } = props
  let reportStatusText = ''
  let reportStatusColor = 'red'
  switch (status) {
    case 'not_started':
      reportStatusText = 'Not started'
      reportStatusColor = '#FF3416' // "red";
      break
    case 'started':
      reportStatusText = 'Started'
      reportStatusColor = '#CD830E' //"rgb(255,207, 101)";
      break
    case 'received':
      reportStatusText = 'Received'
      reportStatusColor = '#437D19' //rgb(55, 207, 126)";
      break
    case 'invoiced':
      reportStatusText = 'Invoiced'
      reportStatusColor = '#414141'
      break
    case 'not_invoiced':
      reportStatusText = 'No invoicing'
      reportStatusColor = '#414141'
      break
    default:
      reportStatusText = `Unknown (${status})`
      break
  }
  if (chip) {
    return (
      <Chip
        variant="outlined"
        style={{
          borderColor: reportStatusColor,
          backgroundColor: 'white',
        }}
        label={
          <StyledText
            variant="body"
            color="primary"
            style={{ color: reportStatusColor }}
          >
            {reportStatusText}
          </StyledText>
        }
      ></Chip>
    )
  } else {
    return (
      <StyledText
        variant="body"
        color="primary"
        style={{ color: reportStatusColor }}
      >
        {reportStatusText}
      </StyledText>
    )
  }
}
ReportStatus.defaultProps = {
  chip: true,
}
export type NotificationBarVariant = 'success' | 'error'
export const NotificationBar: React.SFC<{
  variant: NotificationBarVariant
  onClose: () => void
  relative: boolean
}> = (props) => {
  const { variant, onClose, relative } = props
  let color
  let icon
  switch (variant) {
    case 'success':
      color = theme.colors.notificationOk
      icon = <IconOk style={{ color: theme.colors.secondary }}></IconOk>
      break
    case 'error':
      color = theme.colors.notificationError
      icon = <IconError style={{ color: theme.colors.secondary }}></IconError>
      break
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        top: 0,
        left: 0,
        boxSizing: 'border-box',
        position: relative ? 'relative' : 'fixed',
        width: '100%',
        padding: '1rem',
        zIndex: 100,
        backgroundColor: color,
      }}
    >
      <div />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {icon}
        <div style={{ width: '0.5rem' }} />
        <StyledText variant="headline3" color="secondary">
          {props.children}
        </StyledText>
      </div>
      <div>
        <Button onClick={onClose}>
          <IconClose
            style={{
              justifySelf: 'flex-end',
              color: defaultTheme.colors.brandWhite,
            }}
          ></IconClose>
        </Button>
      </div>
    </div>
  )
}
export const PlainTextInput: React.SFC<{
  value: any
  onChange: (value: any) => void
}> = (props) => {
  const { value, onChange } = props
  return (
    <Input
      onChange={onChange}
      type="text"
      value={value}
      placeholder="Search"
      css={css`
        background: transparent;
        border: none;
        color: ${value === undefined ? 'red' : theme.colors.primary};
        font-family: Montserrat;
        :active {
          background: transparent;
          border: none;
        }
        :focus {
          outline: none !important;
          background: transparent;
          border: none;
        }
      `}
    ></Input>
  )
}
function safeGetMapField(map: any, key: any) {
  return typeof map.get === 'function' ? map.get(key) : map[key]
}
export const CheckboxDropdown: React.SFC<{
  name: string
  items: any[]
  checkedValues: Record<any, boolean>
  onChange: (value: any, checked: boolean) => void
}> = (props) => {
  const { name, items, checkedValues, onChange } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [menuAncholElement, setMenuAnchorElement] = useState<any>(null)
  const handleChangeOpen = (e: any) => {
    setMenuAnchorElement(e.currentTarget)
    setIsOpen(!isOpen)
  }
  const handleCloseMenu = () => {
    setMenuAnchorElement(null)
    setIsOpen(false)
  }
  const handleClick = (e: any, item: any) => {
    onChange(item, !(safeGetMapField(checkedValues, item) === true))
    e.stopPropagation() // Prevent menu from closing
  }
  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      onClick={handleChangeOpen}
    >
      <Menu
        open={isOpen}
        onClose={handleCloseMenu}
        anchorEl={menuAncholElement}
        style={{ padding: 0 }}
      >
        {items.map((item: any) => {
          return (
            <MenuItem
              style={{ padding: 0, margin: 0 }}
              onClick={(e: any) => handleClick(e, item)}
            >
              <Checkbox
                checkedIcon={
                  <CheckedIcon css={{ color: 'rgb(117,209,146)' }} />
                }
                checked={safeGetMapField(checkedValues, item) === true}
              ></Checkbox>
              <StyledText variant="body" color="primary">
                {item}
              </StyledText>
              <div style={{ width: '1rem' }} />
            </MenuItem>
          )
        })}
      </Menu>
      <StyledText variant="body" color="primary">
        {name}
      </StyledText>
      <div style={{ width: '0.5rem' }} />
      <ExpandMoreIcon />
    </div>
  )
}
export const SortDirectionArrow: React.SFC<{
  direction: 'ascending' | 'descending'
  enabled: boolean
}> = (props) => {
  const { direction, enabled } = props
  if (!enabled) {
    return (
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        {/* <ArrowUp css={{color: theme.colors.textGrey}}/>
      <ArrowDown css={{color: theme.colors.textGrey}}/> */}
      </div>
    )
  }
  if (direction === 'ascending') {
    return <ArrowDown />
  } else {
    return <ArrowUp />
  }
}
export const OptionalTooltip: React.SFC<
  PropsWithChildren<{
    show: boolean
    text: string
  }>
> = (props) => {
  const { children, show, text } = props
  if (show) {
    return (
      <Tooltip
        title={
          <StyledText variant="body" color="secondary">
            {text}
          </StyledText>
        }
        arrow
      >
        <div>{children}</div>
      </Tooltip>
    )
  } else {
    return <React.Fragment>{children}</React.Fragment>
  }
}
export const AlternatingTableRow = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: defaultTheme.colors.tableCellBackgroundHighlight,
    },
    '&:nth-of-type(odd)': {
      backgroundColor: defaultTheme.colors.tableCellBackgroundOdd,
      '&:hover': {
        backgroundColor: defaultTheme.colors.tableCellBackgroundHighlight,
      },
    },
    backgroundColor: defaultTheme.colors.tableCellBackgroundDefault,
  },
}))(TableRow)
interface AutoresizingSelectProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  innerRegister: any
}
export const AutoresizingSelect: React.FC<AutoresizingSelectProps> = (
  props
) => {
  const { innerRegister, ...rest } = props
  const inputRef = useRef(
    null
  ) as React.MutableRefObject<HTMLSelectElement | null>
  useEffect(() => {
    const inputBox = inputRef.current
    if (!inputBox || inputBox.options.length < 1) return
    let longestOption = ''
    if (inputRef.current) {
      for (let iitem = 0; iitem < inputRef.current.options.length; ++iitem) {
        const o = inputRef.current.options.item(iitem)
        if (o && o.text.length > longestOption.length) {
          longestOption = o.text
        }
      }
    }
    const firstOption = inputBox.options.item(0)
    if (!firstOption) return
    let testSpan = document.createElement('span')
    testSpan.style.cssText = inputBox.style.cssText
    console.log('debug width, style', testSpan.style.cssText)
    document.body.append(testSpan)
    testSpan.innerHTML = longestOption
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
    inputBox.style.minWidth = testSpan.getBoundingClientRect().width + 21 + 'px'
    document.body.removeChild(testSpan)
  }, [inputRef])
  return (
    <select
      {...rest}
      ref={(e) => {
        innerRegister.register(e)
        inputRef.current = e // you can still assign to ref
      }}
    ></select>
  )
}
interface AutoresizingInputFieldProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  innerRegister: any
}
export const AutoresizingInputField: React.FC<AutoresizingInputFieldProps> = (
  props
) => {
  const { innerRegister, ...rest } = props
  const inputRef = useRef(
    null
  ) as React.MutableRefObject<HTMLInputElement | null>
  useEffect(() => {
    const inputBox = inputRef.current
    if (!inputBox) return
    function handleInputChanged(this: HTMLInputElement, event: Event) {
      if (!inputBox) return
      let testSpan = document.createElement('span')
      testSpan.style.cssText = inputBox.style.cssText + ';white-space:pre'
      document.body.append(testSpan)
      testSpan.innerHTML = this.value
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
      inputBox.style.minWidth =
        testSpan.getBoundingClientRect().width + 5 + 'px'
      document.body.removeChild(testSpan)
    }
    inputBox.addEventListener('input', handleInputChanged)
    return () => {
      inputBox.removeEventListener('input', handleInputChanged)
    }
  }, [inputRef])
  return (
    <input
      {...rest}
      ref={(e) => {
        innerRegister.register(e)
        inputRef.current = e // you can still assign to ref
      }}
    ></input>
  )
}
