import React from 'react'
interface WirepasLogoProps {
  fill: string
  width?: string
  height?: string
}
const WirepasLogo: React.SFC<WirepasLogoProps> = (props) => {
  const { fill, width, height } = props
  const style0 = { fill }
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1200 419"
      width={width}
    >
      <g>
        <path
          style={style0}
          d="M638,153.7c-7.8,0-13.8,6-13.8,13.7S630.2,181,638,181c7.6,0,13.8-6.1,13.8-13.7S645.6,153.7,638,153.7z"
        />
        <rect x="626.6" y="189" style={style0} width="23" height="90" />
        <path
          style={style0}
          d="M768.9,186.4c-26.3,0-45.4,19.9-45.4,47.3c0,28.3,19.2,47.3,47.9,47.3c15.2,0,27.7-4.3,35.3-11.8l0.1-0.1V248
		l-0.8,0.7c-9.2,8-20.4,12.6-30.8,12.6c-17.4,0-29-8.7-31.1-23.3h65.5l0.1-0.4c1.9-15.2-1.7-28.7-10-38.2
		C792.2,190.9,781.6,186.4,768.9,186.4z M744.8,222.5c2.8-11.6,11.1-18.6,22.3-18.6c10.9,0,19,7.6,19.9,18.6H744.8z"
        />
        <path
          style={style0}
          d="M870.8,186.4c-13.2,0-24,5.8-29.8,16V189h-23v125h23v-48.9c5.8,10.2,16.6,16,29.8,16
		c25.3,0,44.3-20.4,44.3-47.3S896.1,186.4,870.8,186.4z M891.7,233.8c0,15-10.9,26.3-25.4,26.3S841,248.7,841,233.8
		s10.9-26.3,25.4-26.3S891.7,218.8,891.7,233.8z"
        />
        <path
          style={style0}
          d="M956,186.4c-11.6,0-23.1,4.1-30.9,10.7l-0.2,0.1v20.4l0.8-0.7c7.7-7,17.1-11.2,26.6-11.2
		c14.3,0,23.3,7.4,23.3,19.4v5.1c-6.1-4.4-14.7-7-23.3-7c-19,0-32.3,11.7-32.3,28.4c0,17.1,13.1,29.5,31.1,29.5
		c10.4,0,19.8-5.3,24.5-13.6v11.5h23V224C998.5,201.5,981.4,186.4,956,186.4z M975.5,244.7v0.5c-0.3,10.4-8.1,18.3-17.9,18.3
		c-9,0-15.5-5.2-15.5-12.5c0-7.1,6.5-12.3,15.5-12.3C963.8,238.8,970.4,241,975.5,244.7z"
        />
        <path
          style={style0}
          d="M1072.3,194.8v21.6c-7.3-5.8-19.3-9.6-30.4-9.6c-7.4,0-12.1,3-12.1,7.8c0,3.9,2.7,6.4,9.8,8.1l11.5,2.7
		c18.9,4.2,25.5,14.3,25.5,27.4c0,17.5-13.1,27.8-36.8,27.8c-10.6,0-22.8-4.1-32.9-10.6l0.2-21.8c9.6,7.8,23.2,12.7,34.9,12.7
		c8,0,12.5-3.4,12.5-8.5c0-4.4-2.9-7.1-10-8.8l-10.6-2.5c-19.5-4.4-25.8-14.5-25.8-26c0-20.9,16.8-28.3,34.9-28.3
		C1054.1,186.9,1065.2,190.1,1072.3,194.8z"
        />
        <path
          style={style0}
          d="M683.9,204.8V189h-23v90h23v-41.6c0-32,30.4-31.5,38.9-25.6v-21.9C717.6,186,692.1,181.8,683.9,204.8z"
        />
        <polygon
          style={style0}
          points="596.1,155.7 570.4,238.3 544.2,155.7 519,155.7 492.9,238.3 467.1,155.7 441.6,155.7 481.1,278.8 
		481.2,278.9 504.9,278.9 531.6,195.3 558.3,278.9 582,278.9 621.6,155.7 	"
        />
      </g>
      <path
        style={style0}
        d="M318.6,278.9c-0.9-14.6,2.5-29.1,10-42.1l46.9-81.2l-56.8-32.8l-46.9,81.2c-16.3,28.2-22.2,60.3-17.2,92
	L318.6,278.9z"
      />
      <path
        style={style0}
        d="M179.7,278.9c0.9-14.6-2.5-29.1-10-42.1l-46.9-81.2l56.8-32.8l46.9,81.2c16.3,28.2,22.2,60.3,17.2,92
	L179.7,278.9z"
      />
    </svg>
  )
}
export default WirepasLogo
