let g_language = process.env.DEFAULT_LANGUAGE || 'EN'
const translations: any = {
  _wirepas_royalty_reporting_tool: {
    FI: 'Wirepas royalty reporting tool',
    EN: 'Wirepas royalty reporting tool',
  },
  _email: { FI: 'Sähköposti', EN: 'Email' },
  _log_in: { FI: 'Kirjaudu sisään', EN: 'Log in' },
  _password: { FI: 'Salasana', EN: 'Password' },
  _forgot_password: { FI: 'Salasana unohtunut?', EN: 'Forgot password?' },
  _login_failed: { FI: 'Kirjautuminen epäonnistui', EN: 'Login failed' },
  // Royalty report form
  _project: { FI: 'Asiakas / Projekti', EN: 'End customer / Project' },
  _royaltyType: { FI: 'Royalty', EN: 'Royalty type' },
  _unit_price_opt: {
    FI: 'Yksikköhinta (valinnainen)',
    EN: 'Unit price (optional)',
  },
  _jan: { FI: 'Tam', EN: 'Jan' },
  _feb: { FI: 'Hel', EN: 'Feb' },
  _mar: { FI: 'Maa', EN: 'Mar' },
  _apr: { FI: 'Huh', EN: 'Apr' },
  _may: { FI: 'Tou', EN: 'May' },
  _jun: { FI: 'Kes', EN: 'Jun' },
  _jul: { FI: 'Hei', EN: 'Jul' },
  _aug: { FI: 'Elo', EN: 'Aug' },
  _sep: { FI: 'Syy', EN: 'Sep' },
  _oct: { FI: 'Lok', EN: 'Oct' },
  _nov: { FI: 'Mar', EN: 'Nov' },
  _dec: { FI: 'Jou', EN: 'Dec' },
  _est: { FI: 'Est', EN: 'Est' },
  _act: { FI: 'Act', EN: 'Act' },
  _Actuals: { FI: 'Aktuaalit', EN: 'Actuals' },
  _Estimates: { FI: 'Ennuste', EN: 'Forecast' },
  _Royalty_report_for: { FI: 'Royalty raportointi', EN: 'Royalty report for' },
  _Status: { FI: 'Tilanne', EN: 'Status' },
  _submitted: { FI: 'palautettu', EN: 'submitted' },
  _draft: { FI: 'ei palautettu', EN: 'draft' },
  _repeat_password: { FI: 'Toista salasana', EN: 'Repeat password' },
  _update_password: { FI: 'Päivitä salasana', EN: 'Update password' },
  _create_password: { FI: 'Aseta salasana', EN: 'Set password' },
  _Royalty_report_overview: {
    FI: 'Yleiskatsaus vastauksiin',
    EN: 'Royalty report overview',
  },
  _Royalty_reports: { FI: 'Raportit', EN: 'Royalty reports' },
  _LinksToAnswers: { FI: 'Yleiskatsaus vastauksiin', EN: 'Reports' },
  _Projections: { FI: 'Ennusteet', EN: 'Projections' },
}
export function setLanguage(language: string) {
  g_language = language
}
export default function (expression: string): string {
  try {
    return translations[expression][g_language]
  } catch (ex) {
    return '###' + expression + '###'
  }
}
