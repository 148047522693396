/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../reducers'
import {
  makeStyles,
  Grid,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button,
  Container,
  Input,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
  Tooltip,
} from '@material-ui/core'
import {
  RoyaltyReport,
  fetchReports,
  updateReportFilters,
  clearReportFilters,
} from '../actions/reports'
import CircularProgress from '@material-ui/core/CircularProgress'
import tr from '../translations'
import { Link, useHistory } from 'react-router-dom'
import {
  calculateYearlyEstimate,
  calculateQuarterVolume,
  calculateReportingPeriodStatistics,
  getLastSubmitTimestamp,
  getLastSubmitAccessLevel,
  QuarterReportData,
  buildReportingPeriodId,
  buildEmptyReportData,
  summaryCalculate,
} from '../lib/report'
import { defaultTheme } from '../wirepas_theme'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import {
  StyledText,
  NotificationBar,
  PlainTextInput,
  CheckboxDropdown,
  ReportStatus,
  SortDirectionArrow,
  AlternatingTableRow,
} from './Elements'
import { defaultTheme as wirepasTheme } from '../wirepas_theme'
import { css, jsx } from '@emotion/core'
import Chip from '@material-ui/core/Chip'
import { fetchSettings } from '../actions/settings'
import { Logger } from '../lib/logger'
import IconX from '@material-ui/icons/Remove'
import Select from '@material-ui/core/Select'
import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable'
import { report_testdata } from './report_testdata'
import {
  formatDate as formatSubmitTimestamp,
  objectSize,
} from './../lib/common'
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxSizing: 'border-box',
  },
  title: {
    padding: theme.spacing(3),
  },
}))
const HeaderCell = withStyles((theme) => ({
  head: {
    backgroundColor: wirepasTheme.colors.tableHeaderAdmin1,
    color: wirepasTheme.colors.secondary,
    fontWeight: 'bold',
  },
  body: {},
}))(TableCell)
export default function ReportOverview(props: any) {
  const dispatch = useDispatch()
  const [showNotification, setShowNotification] = useState<boolean>(false)
  const reports = useSelector((state: RootState) => state.reports.reports) // report_testdata
  const reportingSettings = useSelector(
    (state: RootState) => state.reports.reportingSettings
  )
  const projections = useSelector(
    (state: RootState) => state.reports.projections
  )
  const detailedProjections = useSelector(
    (state: RootState) => state.reports.detailedProjections
  )
  const isFetching = useSelector((state: RootState) => state.reports.isFetching)
  const error = useSelector((state: RootState) => state.reports.error)
  const userRole = useSelector((state: RootState) => state.auth.userRole)
  const [sortedReports, setSortedReports] = useState<RoyaltyReport[]>([])
  const classes = useStyles()
  const supportContactEmail = useSelector(
    (state: RootState) => state.settings.supportContactEmail
  )
  const supportContactPhone = useSelector(
    (state: RootState) => state.settings.supportContactPhone
  )
  useEffect(() => {
    if (error) {
      setShowNotification(true)
    } else {
      setShowNotification(false)
    }
  }, [error])
  useEffect(() => {
    dispatch(fetchSettings())
    dispatch(fetchReports())
  }, [dispatch])
  const helper_sortReports = (
    getComparison: (report: RoyaltyReport) => any
  ) => {
    return reports.sort((a, b) =>
      getComparison(a) < getComparison(b)
        ? -1
        : getComparison(a) > getComparison(b)
        ? 1
        : 0
    )
  }
  const sortReports = (
    reports: RoyaltyReport[],
    getComparison: (report: RoyaltyReport) => any,
    sortDirection: 'ascending' | 'descending' = 'ascending'
  ) => {
    let comp1 = -1
    let comp2 = 1
    if (sortDirection === 'descending') {
      comp1 = 1
      comp2 = -1
    }
    setSortedReports(
      reports.sort((a, b) =>
        getComparison(a) < getComparison(b)
          ? comp1
          : getComparison(a) > getComparison(b)
          ? comp2
          : 0
      )
    )
  }
  const history = useHistory()
  const handleLink = (path: string) => {
    history.push(path)
  }
  const handleChangeSearchString = (e: any) => {
    dispatch(
      updateReportFilters({
        searchString: e.target.value,
      })
    )
  }
  const reportFilters = useSelector(
    (state: RootState) => state.reports.reportFilters
  )
  const handleQuarterDropdownChange = (value: any, checked: boolean) => {
    dispatch(
      updateReportFilters({
        checkedQuarters: {
          [value]: checked,
        },
      })
    )
  }
  const handleYearDropdownChange = (value: any, checked: boolean) => {
    dispatch(
      updateReportFilters({
        checkedYears: {
          [value]: checked,
        },
      })
    )
  }
  const handleStatusDropdownChange = (value: any, checked: boolean) => {
    dispatch(
      updateReportFilters({
        checkedStatuses: {
          [value]: checked,
        },
      })
    )
  }
  const statusMap: Record<string, string> = {
    started: 'Started',
    not_started: 'Not started',
    received: 'Received',
  }
  const quarterMap: Record<string, string> = {
    1: 'Q1',
    2: 'Q2',
    3: 'Q3',
    4: 'Q4',
  }
  const handleClearFilters = () => {
    dispatch(clearReportFilters())
  }
  const handleSortingChange = (newFieldName: string) => {
    dispatch(
      updateReportFilters({
        sortField: newFieldName,
        sortDirection:
          reportFilters.sortField === newFieldName
            ? reportFilters.sortDirection === 'ascending'
              ? 'descending'
              : 'ascending'
            : reportFilters.sortDirection,
      })
    )
  }

  useEffect(() => {
    // Handle filter changes
    const {
      checkedQuarters,
      checkedYears,
      checkedStatuses,
      sortField,
      sortDirection,
      searchString,
    } = reportFilters
    const countChecked = (map: any) =>
      Object.values(map).reduce<number>(
        (acc, next) => (next === true ? acc + 1 : acc),
        0
      )
    const filteredReports = reports.filter((rep: RoyaltyReport) => {
      if (countChecked(checkedQuarters) > 0) {
        if (reportFilters.checkedQuarters[quarterMap[rep.quarter]] !== true) {
          return false
        }
      }
      if (countChecked(checkedYears) > 0) {
        if (reportFilters.checkedYears[rep.year] !== true) {
          return false
        }
      }
      if (countChecked(checkedStatuses) > 0) {
        if (
          reportFilters.checkedStatuses[statusMap[rep.reportingStatus]] !== true
        ) {
          return false
        }
      }
      if (
        reportFilters.searchString !== undefined &&
        reportFilters.searchString.length > 0
      ) {
        const searchStringLower = reportFilters.searchString.toLowerCase()
        if (
          !(
            rep.organizationName.toLowerCase().includes(searchStringLower) ||
            rep.dealTitle.toLowerCase().includes(searchStringLower)
          )
        ) {
          return false
        }
      }
      return true
    })
    sortReports(
      filteredReports,
      (report: RoyaltyReport) => {
        // Temp hack: handle dynamically calculated values
        if (sortField === 'actuals') {
          return calculateQuarterVolume(report.entries, 1)
        } else if (sortField === 'n1') {
          return calculateQuarterVolume(report.entries, 2)
        } else if (sortField === 'n2') {
          return calculateQuarterVolume(report.entries, 3)
        } else if (sortField === 'n3') {
          return calculateQuarterVolume(report.entries, 4)
        } else if (sortField === 'n4') {
          return calculateQuarterVolume(report.entries, 5)
        } else if (sortField === 'n5') {
          return calculateQuarterVolume(report.entries, 6)
        } else if (sortField === 'submitTimestamp') {
          return getLastSubmitTimestamp(report) || 0
        }
        //@ts-ignore
        return report[sortField]
      },
      reportFilters.sortDirection
    )
  }, [reportFilters, reports])
  const [reportingPeriodStats, setReportingPeriodStats] = useState<
    QuarterReportData[]
  >([])
  // Ongoing -table
  const [dataSetYears, setDataSetYears] = useState<number[]>([])
  useEffect(() => {
    let allYears = new Set<number>()
    reports.forEach((rep) => {
      allYears.add(rep.year)
    })
    setDataSetYears(Array.from(allYears))
    const reportPeriodMap = calculateReportingPeriodStatistics(reports)
    if (reportingSettings) {
      const { reportingYear, reportingQuarter } = reportingSettings
      const numberYear = Number(reportingYear)
      const numberQuarter = Number(reportingQuarter)
      if (
        !Number.isNaN(numberYear) &&
        Number.isInteger(numberYear) &&
        !Number.isNaN(numberQuarter) &&
        Number.isInteger(numberQuarter) &&
        numberQuarter >= 1 &&
        numberQuarter <= 4
      ) {
        const currentReportingPeriodId = buildReportingPeriodId(
          numberYear,
          numberQuarter
        )
        if (!reportPeriodMap.get(currentReportingPeriodId)) {
          // Uncomment to add current reporting period to list even without any reports
          //reportPeriodMap.set(currentReportingPeriodId, buildEmptyReportData(numberYear, numberQuarter))
        }
      }
    }
    const reportingStatisticsList = Array.from(reportPeriodMap.values()).sort(
      (a: QuarterReportData, b: QuarterReportData) => {
        if (a.year < b.year) {
          return 1
        }
        if (a.year > b.year) {
          return -1
        }
        if (a.quarter < b.quarter) {
          return 1
        }
        if (a.quarter > b.quarter) {
          return -1
        }
        return 0
      }
    )
    setReportingPeriodStats(reportingStatisticsList)
  }, [reports])
  const {
    checkedQuarters,
    checkedYears,
    checkedStatuses,
    sortField,
    sortDirection,
    searchString,
  } = reportFilters
  return (
    <div className={classes.root}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingLeft: '1rem',
          paddingRight: '1rem',
        }}
      >
        <Grid container direction="column" justify="center">
          <Grid item>
            <div style={{ height: '1rem' }} />
          </Grid>
          {!(userRole === 'admin' || userRole === 'sales') && (
            <Grid item>
              <div style={{ marginLeft: '1.5rem' }}>
                <StyledText variant="body" color="grey">
                  Contact us by mail {supportContactEmail} or by phone{' '}
                  {supportContactPhone}
                </StyledText>
              </div>
            </Grid>
          )}

          <StyledText
            variant="headline3"
            color="primary"
            style={{ fontSize: 24, padding: '1rem' }}
          >
            Summary
          </StyledText>
          {/* Ongoing table */}
          <Table>
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: wirepasTheme.colors.ongoingTableHeader,
                }}
              >
                <TableCell>
                  <StyledText variant="headline3" color="secondary">
                    Quarter
                  </StyledText>
                </TableCell>
                <TableCell>
                  <StyledText variant="headline3" color="secondary">
                    Not started
                  </StyledText>
                </TableCell>
                <TableCell>
                  <StyledText variant="headline3" color="secondary">
                    Started
                  </StyledText>
                </TableCell>
                <TableCell>
                  <StyledText variant="headline3" color="secondary">
                    Received
                  </StyledText>
                </TableCell>
                <TableCell>
                  <StyledText variant="headline3" color="secondary">
                    Invoiced
                  </StyledText>
                </TableCell>
                <TableCell>
                  <StyledText variant="headline3" color="secondary">
                    No invoicing
                  </StyledText>
                </TableCell>
                <TableCell>
                  <StyledText variant="headline3" color="secondary">
                    Actuals total
                  </StyledText>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportingPeriodStats.map((data: QuarterReportData) => {
                return (
                  <TableRow
                    style={{
                      backgroundColor:
                        defaultTheme.colors.tableCellBackgroundDefault,
                    }}
                  >
                    <TableCell>
                      <StyledText variant="body" color="primary">
                        {data.year}/Q{data.quarter}
                      </StyledText>
                    </TableCell>
                    <TableCell>
                      <StyledText variant="body" color="primary">
                        {data.reportStatusCountMap.get('not_started')}
                      </StyledText>
                    </TableCell>
                    <TableCell>
                      <StyledText variant="body" color="primary">
                        {data.reportStatusCountMap.get('started')}
                      </StyledText>
                    </TableCell>
                    <TableCell>
                      <StyledText variant="body" color="primary">
                        {data.reportStatusCountMap.get('received')}
                      </StyledText>
                    </TableCell>
                    <TableCell>
                      <StyledText variant="body" color="primary">
                        {data.reportStatusCountMap.get('invoiced')}
                      </StyledText>
                    </TableCell>
                    <TableCell>
                      <StyledText variant="body" color="primary">
                        {data.reportStatusCountMap.get('not_invoiced')}
                      </StyledText>
                    </TableCell>
                    <TableCell>
                      <StyledText variant="body" color="primary">
                        {data.actualsTotal}
                      </StyledText>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <StyledText
            variant="headline3"
            color="primary"
            style={{ fontSize: 24, padding: '1rem' }}
          >
            Reports
          </StyledText>
          {/* Report table */}
          <Grid item>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '3.5rem',
                backgroundColor: wirepasTheme.colors.tableHeaderAdminFilters,
              }}
            >
              <div style={{ padding: '1rem', flexGrow: 1 }}>
                <PlainTextInput
                  value={searchString}
                  onChange={handleChangeSearchString}
                />
              </div>
              <div style={{ padding: '1rem' }}>
                <CheckboxDropdown
                  name="Year"
                  items={dataSetYears}
                  onChange={handleYearDropdownChange}
                  checkedValues={checkedYears}
                ></CheckboxDropdown>
              </div>
              <div style={{ padding: '1rem' }}>
                <CheckboxDropdown
                  name="Quarter"
                  items={['Q1', 'Q2', 'Q3', 'Q4']}
                  onChange={handleQuarterDropdownChange}
                  checkedValues={checkedQuarters}
                ></CheckboxDropdown>
              </div>
              <div style={{ padding: '1rem' }}>
                <CheckboxDropdown
                  name="Status"
                  items={['Started', 'Not started', 'Received']}
                  onChange={handleStatusDropdownChange}
                  checkedValues={checkedStatuses}
                ></CheckboxDropdown>
              </div>
              <div style={{ padding: '1rem' }}>
                <Button variant="text" onClick={handleClearFilters}>
                  <StyledText variant="body" color="primary">
                    Clear filters
                  </StyledText>
                  <div style={{ width: '0.5rem' }} />
                  <IconX />
                </Button>
              </div>
            </div>
            <Table>
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: wirepasTheme.colors.tableHeaderAdmin1,
                  }}
                >
                  <HeaderCell
                    onClick={() => handleSortingChange('organizationName')}
                  >
                    <div style={{ display: 'flex' }}>
                      <StyledText
                        variant="headline3"
                        color="secondary"
                        unselectable
                      >
                        Organization
                      </StyledText>
                      <div style={{ width: '0.5rem' }}></div>
                      <SortDirectionArrow
                        direction={
                          sortField === 'organizationName'
                            ? sortDirection
                            : 'ascending'
                        }
                        enabled={sortField === 'organizationName'}
                      />
                    </div>
                  </HeaderCell>
                  <HeaderCell onClick={() => handleSortingChange('dealTitle')}>
                    <div style={{ display: 'flex' }}>
                      <StyledText
                        variant="headline3"
                        color="secondary"
                        unselectable
                      >
                        Deal
                      </StyledText>
                      <div style={{ width: '0.5rem' }}></div>
                      <SortDirectionArrow
                        direction={
                          sortField === 'dealTitle'
                            ? sortDirection
                            : 'ascending'
                        }
                        enabled={sortField === 'dealTitle'}
                      />
                    </div>
                  </HeaderCell>
                  <HeaderCell onClick={() => handleSortingChange('year')}>
                    <div style={{ display: 'flex' }}>
                      <StyledText
                        variant="headline3"
                        color="secondary"
                        unselectable
                      >
                        Year
                      </StyledText>
                      <div style={{ width: '0.5rem' }}></div>
                      <SortDirectionArrow
                        direction={
                          sortField === 'year' ? sortDirection : 'ascending'
                        }
                        enabled={sortField === 'year'}
                      />
                    </div>
                  </HeaderCell>
                  <HeaderCell onClick={() => handleSortingChange('quarter')}>
                    <div style={{ display: 'flex' }}>
                      <StyledText
                        variant="headline3"
                        color="secondary"
                        unselectable
                      >
                        Quarter
                      </StyledText>
                      <div style={{ width: '0.5rem' }}></div>
                      <SortDirectionArrow
                        direction={
                          sortField === 'quarter' ? sortDirection : 'ascending'
                        }
                        enabled={sortField === 'quarter'}
                      />
                    </div>
                  </HeaderCell>
                  <HeaderCell
                    onClick={() => handleSortingChange('reminderCount')}
                  >
                    <div style={{ display: 'flex' }}>
                      <StyledText
                        variant="headline3"
                        color="secondary"
                        unselectable
                      >
                        Reminders
                      </StyledText>
                      <div style={{ width: '0.5rem' }}></div>
                      <SortDirectionArrow
                        direction={
                          sortField === 'reminderCount'
                            ? sortDirection
                            : 'ascending'
                        }
                        enabled={sortField === 'reminderCount'}
                      />
                    </div>
                  </HeaderCell>
                  <HeaderCell
                    onClick={() => handleSortingChange('reportingStatus')}
                  >
                    <div style={{ display: 'flex' }}>
                      <StyledText
                        variant="headline3"
                        color="secondary"
                        unselectable
                      >
                        Status
                      </StyledText>
                      <div style={{ width: '0.5rem' }}></div>
                      <SortDirectionArrow
                        direction={
                          sortField === 'reportingStatus'
                            ? sortDirection
                            : 'ascending'
                        }
                        enabled={sortField === 'reportingStatus'}
                      />
                    </div>
                  </HeaderCell>
                  <HeaderCell
                    onClick={() => handleSortingChange('submitTimestamp')}
                  >
                    <div style={{ display: 'flex' }}>
                      <StyledText
                        variant="headline3"
                        color="secondary"
                        unselectable
                      >
                        Submitted
                      </StyledText>
                      <div style={{ width: '0.5rem' }}></div>
                      <SortDirectionArrow
                        direction={
                          sortField === 'submitTimestamp'
                            ? sortDirection
                            : 'ascending'
                        }
                        enabled={sortField === 'submitTimestamp'}
                      />
                    </div>
                  </HeaderCell>
                  <HeaderCell onClick={() => handleSortingChange('actuals')}>
                    <div style={{ display: 'flex' }}>
                      <StyledText
                        variant="headline3"
                        color="secondary"
                        unselectable
                      >
                        Actuals
                      </StyledText>
                      <div style={{ width: '0.5rem' }}></div>
                      <SortDirectionArrow
                        direction={
                          sortField === 'actuals' ? sortDirection : 'ascending'
                        }
                        enabled={sortField === 'actuals'}
                      />
                    </div>
                  </HeaderCell>
                  <HeaderCell onClick={() => handleSortingChange('n1')}>
                    <div style={{ display: 'flex' }}>
                      <StyledText
                        variant="headline3"
                        color="secondary"
                        unselectable
                      >
                        N+1
                      </StyledText>
                      <div style={{ width: '0.5rem' }}></div>
                      <SortDirectionArrow
                        direction={
                          sortField === 'n1' ? sortDirection : 'ascending'
                        }
                        enabled={sortField === 'n1'}
                      />
                    </div>
                  </HeaderCell>
                  <HeaderCell onClick={() => handleSortingChange('n2')}>
                    <div style={{ display: 'flex' }}>
                      <StyledText
                        variant="headline3"
                        color="secondary"
                        unselectable
                      >
                        N+2
                      </StyledText>
                      <div style={{ width: '0.5rem' }}></div>
                      <SortDirectionArrow
                        direction={
                          sortField === 'n2' ? sortDirection : 'ascending'
                        }
                        enabled={sortField === 'n2'}
                      />
                    </div>
                  </HeaderCell>
                  <HeaderCell onClick={() => handleSortingChange('n3')}>
                    <div style={{ display: 'flex' }}>
                      <StyledText
                        variant="headline3"
                        color="secondary"
                        unselectable
                      >
                        N+3
                      </StyledText>
                      <div style={{ width: '0.5rem' }}></div>
                      <SortDirectionArrow
                        direction={
                          sortField === 'n3' ? sortDirection : 'ascending'
                        }
                        enabled={sortField === 'n3'}
                      />
                    </div>
                  </HeaderCell>
                  <HeaderCell onClick={() => handleSortingChange('n4')}>
                    <div style={{ display: 'flex' }}>
                      <StyledText
                        variant="headline3"
                        color="secondary"
                        unselectable
                      >
                        N+4
                      </StyledText>
                      <div style={{ width: '0.5rem' }}></div>
                      <SortDirectionArrow
                        direction={
                          sortField === 'n4' ? sortDirection : 'ascending'
                        }
                        enabled={sortField === 'n4'}
                      />
                    </div>
                  </HeaderCell>
                  <HeaderCell onClick={() => handleSortingChange('n5')}>
                    <div style={{ display: 'flex' }}>
                      <StyledText
                        variant="headline3"
                        color="secondary"
                        unselectable
                      >
                        N+5
                      </StyledText>
                      <div style={{ width: '0.5rem' }}></div>
                      <SortDirectionArrow
                        direction={
                          sortField === 'n5' ? sortDirection : 'ascending'
                        }
                        enabled={sortField === 'n5'}
                      />
                    </div>
                  </HeaderCell>
                </TableRow>
              </TableHead>
              {!isFetching && !error && (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <StyledText variant="body" color="primary">
                        Summary (all received/started)
                      </StyledText>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      {summaryCalculate(sortedReports, 'actuals')}
                    </TableCell>
                    <TableCell>
                      {summaryCalculate(sortedReports, 'n1')}
                    </TableCell>
                    <TableCell>
                      {summaryCalculate(sortedReports, 'n2')}
                    </TableCell>
                    <TableCell>
                      {summaryCalculate(sortedReports, 'n3')}
                    </TableCell>
                    <TableCell>
                      {summaryCalculate(sortedReports, 'n4')}
                    </TableCell>
                    <TableCell>
                      {summaryCalculate(sortedReports, 'n5')}
                    </TableCell>
                  </TableRow>
                  {sortedReports &&
                    sortedReports.map((report: RoyaltyReport) => {
                      let reportStatusText = ''
                      let reportStatusColor = 'red'
                      const adminSubmitColor = '#A5A7A6'
                      switch (report.reportingStatus) {
                        case 'not_started':
                          reportStatusText = 'Not started'
                          reportStatusColor = 'red'
                          break
                        case 'started':
                          reportStatusText = 'Started'
                          reportStatusColor = 'rgb(255,207, 101)'
                          break
                        case 'received':
                          reportStatusText = 'Sent'
                          reportStatusColor = 'rgb(55, 207, 126)'
                          break
                      }
                      const linkPath = `/forms/${report.pipedriveDealId}/${report.year}/${report.quarter}`
                      return (
                        <React.Fragment>
                          <AlternatingTableRow>
                            <TableCell onClick={() => handleLink(linkPath)}>
                              <StyledText variant="body" color="primary">
                                {report.organizationName}
                              </StyledText>
                            </TableCell>
                            <TableCell onClick={() => handleLink(linkPath)}>
                              <StyledText variant="body" color="primary">
                                {report.dealTitle}
                              </StyledText>
                            </TableCell>
                            <TableCell onClick={() => handleLink(linkPath)}>
                              <StyledText variant="body" color="primary">
                                {report.year}
                              </StyledText>
                            </TableCell>
                            <TableCell onClick={() => handleLink(linkPath)}>
                              <StyledText variant="body" color="primary">
                                Q{report.quarter}
                              </StyledText>
                            </TableCell>
                            <TableCell onClick={() => handleLink(linkPath)}>
                              <StyledText variant="body" color="primary">
                                {report.reminderCount}
                              </StyledText>
                            </TableCell>
                            <TableCell onClick={() => handleLink(linkPath)}>
                              <ReportStatus
                                status={report.reportingStatus}
                                chip={true}
                              />
                              {/* <StyledText variant="headline3" color="primary" style={{color: reportStatusColor}}>{reportStatusText}</StyledText> */}
                            </TableCell>
                            <TableCell onClick={() => handleLink(linkPath)}>
                              <div
                                style={{
                                  height: '100%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}
                              >
                                <StyledText variant="body" color="primary">
                                  {formatSubmitTimestamp(
                                    getLastSubmitTimestamp(report)
                                  )}
                                </StyledText>
                                {getLastSubmitAccessLevel(report) ===
                                  'admin' && (
                                  <React.Fragment>
                                    <div style={{ width: '0.5rem' }}></div>
                                    <Tooltip
                                      title={
                                        <StyledText
                                          variant="body"
                                          color="secondary"
                                        >
                                          This report was submitted by admin
                                        </StyledText>
                                      }
                                      arrow
                                    >
                                      <Chip
                                        variant="outlined"
                                        style={{
                                          borderColor: adminSubmitColor,
                                          backgroundColor: 'white',
                                        }}
                                        label={
                                          <StyledText
                                            variant="body"
                                            color="primary"
                                            style={{ color: adminSubmitColor }}
                                          >
                                            admin
                                          </StyledText>
                                        }
                                      ></Chip>
                                    </Tooltip>
                                  </React.Fragment>
                                )}
                              </div>

                              {/* <StyledText variant="headline3" color="primary" style={{color: reportStatusColor}}>{reportStatusText}</StyledText> */}
                            </TableCell>
                            <TableCell onClick={() => handleLink(linkPath)}>
                              <StyledText variant="body" color="primary">
                                {calculateQuarterVolume(report.entries, 1)}
                              </StyledText>
                            </TableCell>
                            <TableCell onClick={() => handleLink(linkPath)}>
                              <StyledText variant="body" color="primary">
                                {calculateQuarterVolume(report.entries, 2)}
                              </StyledText>
                            </TableCell>
                            <TableCell onClick={() => handleLink(linkPath)}>
                              <StyledText variant="body" color="primary">
                                {calculateQuarterVolume(report.entries, 3)}
                              </StyledText>
                            </TableCell>
                            <TableCell onClick={() => handleLink(linkPath)}>
                              <StyledText variant="body" color="primary">
                                {calculateQuarterVolume(report.entries, 4)}
                              </StyledText>
                            </TableCell>
                            {/* N+4 */}
                            <TableCell onClick={() => handleLink(linkPath)}>
                              <StyledText variant="body" color="primary">
                                {calculateQuarterVolume(report.entries, 5)}
                              </StyledText>
                            </TableCell>
                            {/* N+5 */}
                            <TableCell onClick={() => handleLink(linkPath)}>
                              <StyledText variant="body" color="primary">
                                {calculateQuarterVolume(report.entries, 6)}
                              </StyledText>
                            </TableCell>
                          </AlternatingTableRow>
                          {/* <DetailsTableRow>
                        <TableCell colSpan={5}>
                          <Typography>Details</Typography>
                        </TableCell>
                      </DetailsTableRow> */}
                        </React.Fragment>
                      )
                    })}
                </TableBody>
              )}
            </Table>
          </Grid>
          {isFetching && (
            <Grid item style={{ height: '100vh' }}>
              <Grid container justify="center">
                <CircularProgress
                  color="primary"
                  style={{
                    marginTop: 'auto',
                    alignSelf: 'center',
                    padding: '1rem',
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  )
}
