import React, { useState, useEffect } from 'react'
import {
  AppBar,
  makeStyles,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Link,
} from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { useDispatch, useSelector } from 'react-redux'
import { requestLogout } from './../actions/auth'
import { RootState } from '../reducers'
import WirepasLogoHorizontal from './../wirepas-logo-hor'
import { useHistory } from 'react-router-dom'
import { defaultTheme, defaultTheme as wirepasTheme } from './../wirepas_theme'
import { StyledText, StyledButton, NotificationBar } from './Elements'
import { closeNotification } from '../actions/app'
const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: wirepasTheme.colors.primary,
    height: '5rem',
  },
}))
export default function MainAppBar() {
  const classes = useStyles()
  const [menuAnchorElement, setMenuAnchorElement] = useState<Element | null>(
    null
  )
  const userEmail = useSelector<RootState, string | undefined>(
    (state) => state.auth.userEmail
  )
  const userRole = useSelector<RootState>((state) => state.auth.userRole)
  const token = useSelector<RootState, string | undefined>(
    (state) => state.auth.token
  )
  const handleCloseMenu = () => {
    setMenuAnchorElement(null)
  }
  const handleClickAccount = (event: React.MouseEvent) => {
    setMenuAnchorElement(event.currentTarget)
  }
  const grantUrl: string = process.env.REACT_APP_GRANT_URL
    ? process.env.REACT_APP_GRANT_URL
    : '#'
  const handleClickGrant = () => {
    setMenuAnchorElement(null)
    window.location.assign(grantUrl)
  }
  const handleClickOutsideMenu = (event: React.MouseEvent) => {
    setMenuAnchorElement(null)
  }
  const handleClickLogout = () => {
    setMenuAnchorElement(null)
    dispatch(requestLogout())
  }
  const history = useHistory()
  const handleClickReports = () => {
    setMenuAnchorElement(null)
    history.push('/forms')
  }
  const dispatch = useDispatch()
  const currentPath = '' + history.location.pathname
  const showNotification = useSelector(
    (state: RootState) => state.app.showNotification
  )
  const notificationMessage = useSelector(
    (state: RootState) => state.app.notificationMessage
  )
  const notificationType = useSelector(
    (state: RootState) => state.app.notificationType
  )
  const handleCloseNotification = () => {
    dispatch(closeNotification())
  }
  const [notificationPositionRelative, setNotificationPositionRelative] =
    useState<boolean>(true)
  useEffect(() => {
    const handleScroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop
      if (winScroll > 65) {
        setNotificationPositionRelative(false)
      } else {
        setNotificationPositionRelative(true)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <AppBar className={classes.toolbar} position="fixed">
      <React.Fragment>
        <Toolbar>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <WirepasLogoHorizontal
              width="300px"
              height="80px"
              fill={defaultTheme.colors.brandWhite}
            />
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AccountCircle
                  style={{ fill: wirepasTheme.colors.highlight }}
                />
                <div style={{ width: '0.5rem' }} />
                <StyledText
                  variant="headline3"
                  color="highlight"
                  css={{ cursor: 'pointer' }}
                >
                  {userEmail}
                </StyledText>
              </div>
              {/* {userRole === 'admin' && <StyledButton variant="primary" onClick={handleClickGrant}>
                <StyledText
                  underline={currentPath === "/forms"}
                  variant="headline3"
                  color={currentPath === "/forms" ? "secondary" : "highlight"}
                >
                  Grant access to procountor
                </StyledText>
              </StyledButton> } */}
              <div style={{ width: '5rem' }} />
              <StyledButton variant="primary" onClick={handleClickReports}>
                <StyledText
                  underline={currentPath === '/forms'}
                  variant="headline3"
                  color={currentPath === '/forms' ? 'secondary' : 'highlight'}
                >
                  Reports
                </StyledText>
              </StyledButton>
              <div style={{ width: '2rem' }} />
              {userRole === 'admin' && (
                <StyledButton
                  variant="primary"
                  onClick={() => history.push('/prepaid_management')}
                >
                  <StyledText variant="headline3" color="highlight">
                    Prepaid licenses
                  </StyledText>
                </StyledButton>
              )}
              <StyledButton variant="primary" onClick={handleClickLogout}>
                <StyledText variant="headline3" color="highlight">
                  Logout
                </StyledText>
              </StyledButton>
            </div>
          </div>
          <Menu
            id="profile-menu"
            anchorEl={menuAnchorElement}
            open={menuAnchorElement != null}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={handleClickReports}>
              {userRole === 'admin' ? 'Reports / Projections' : 'Reports'}
            </MenuItem>
            {userRole === 'admin' && (
              <MenuItem onClick={handleClickGrant}>
                Grant access to procountor
              </MenuItem>
            )}
            <MenuItem onClick={handleClickLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
        {showNotification && (
          <NotificationBar
            css={{ marginTop: '5rem', boxSizing: 'border-box' }}
            relative={notificationPositionRelative}
            variant={notificationType}
            onClose={handleCloseNotification}
          >
            {notificationMessage}
          </NotificationBar>
        )}
      </React.Fragment>
    </AppBar>
  )
}
