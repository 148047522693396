import {
  ReportsActionTypes,
  REPORTS_REQUESTED,
  REPORTS_RECEIVED,
  REPORTS_ERROR,
  Projection,
  IDealLicenseProjection,
  ReportFilters,
  UPDATE_REPORT_FILTERS,
  CLEAR_REPORT_FILTERS,
  ReportingSettings,
} from '../actions/reports'
import { RoyaltyReport } from './../actions/reports'
import { produce } from 'immer'
import { Logger } from '../lib/logger'
interface ReportsState {
  isFetching: boolean
  error: string | null
  reports: RoyaltyReport[]
  projections: Projection[]
  detailedProjections: Map<string, IDealLicenseProjection[]>
  reportFilters: ReportFilters
  reportingSettings?: ReportingSettings
}
const initialFilters: ReportFilters = {
  checkedQuarters: {},
  checkedYears: {},
  checkedStatuses: {},
  sortField: '',
  searchString: '',
  sortDirection: 'descending',
}
const initialState: ReportsState = {
  isFetching: false,
  reports: [],
  projections: [],
  detailedProjections: new Map(),
  error: null,
  // Report page filters
  reportFilters: {
    ...initialFilters,
  },
}
export default function (
  state: ReportsState = initialState,
  action: ReportsActionTypes
) {
  switch (action.type) {
    case REPORTS_REQUESTED: {
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    }
    case REPORTS_RECEIVED: {
      return {
        ...state,
        isFetching: false,
        reports: action.reports,
        projections: action.projections,
        detailedProjections: action.detailedProjections,
        reportingSettings: action.reportingSettings,
        error: null,
      }
    }
    case REPORTS_ERROR:
      return {
        ...state,
        isFetching: false,
        reports: [],
        projections: [],
        reportingSettings: undefined,
        error: action.error,
      }
    case UPDATE_REPORT_FILTERS:
      const next = {
        ...state,
        reportFilters: {
          checkedQuarters: {
            ...state.reportFilters.checkedQuarters,
            ...(action.reportFilters.checkedQuarters || {}),
          },
          checkedYears: {
            ...state.reportFilters.checkedYears,
            ...(action.reportFilters.checkedYears || {}),
          },
          checkedStatuses: {
            ...state.reportFilters.checkedStatuses,
            ...(action.reportFilters.checkedStatuses || {}),
          },
          sortField:
            action.reportFilters.sortField !== undefined
              ? action.reportFilters.sortField
              : state.reportFilters.sortField,
          sortDirection:
            action.reportFilters.sortDirection !== undefined
              ? action.reportFilters.sortDirection
              : state.reportFilters.sortDirection,
          searchString:
            action.reportFilters.searchString !== undefined
              ? action.reportFilters.searchString
              : state.reportFilters.searchString,
        },
      }
      return next
    case CLEAR_REPORT_FILTERS:
      return {
        ...state,
        reportFilters: { ...initialFilters },
      }
    default:
      return state
  }
}
