/** @jsx jsx */
import React, { useState, useEffect, useContext } from 'react'
import {
  Typography,
  makeStyles,
  Grid,
  FormControl,
  TextField,
  Paper,
  Button,
  Input,
  InputLabel,
  FormHelperText,
  Toolbar,
  AppBar,
  useTheme,
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import tr from './../translations'
import { requestToken } from './../actions/auth'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { RootState } from './../reducers'
import { css, jsx } from '@emotion/core'
import WirepasLogo from '../wirepas-logo'
import * as request from './../lib/request'
import { defaultTheme } from './../wirepas_theme'
import { StyledText } from './Elements'
import { clearReportFilters } from '../actions/reports'
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    backgroundColor: defaultTheme.colors.background,
    //backgroundImage: `url(${defaultTheme.backgroundImage})`,
    //backgroundSize: 'cover
    resize: 'both',
  },
  centered: {
    margin: 'auto',
  },
  loginPaper: {
    width: '22rem',
    margin: 'auto',
    padding: theme.spacing(8),
    backgroundColor: defaultTheme.colors.paper,
  },
  formField: {},
}))
type LoginFormData = {
  email: string
  password: string
}
export default function Login() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const token = useSelector((state: RootState) => state.auth.token)
  const loginError = useSelector((state: RootState) => state.auth.error)
  const { register, handleSubmit, control, getValues } =
    useForm<LoginFormData>()
  const onSubmit = (data: LoginFormData) => {
    dispatch(requestToken(data.email, data.password))
    dispatch(clearReportFilters())
  }
  const history = useHistory()
  const handleForgotPassword = async () => {
    history.push('/forgot')
  }
  const theme = defaultTheme
  return (
    <div className={classes.root}>
      <Paper className={classes.loginPaper}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" spacing={1}>
            <Grid item style={{ textAlign: 'center' }}>
              <WirepasLogo width="300px" fill={theme.colors.mainLogo} />
            </Grid>
            <Grid item>
              <StyledText variant="headline3" color="primary">
                Royalty reporting tool
              </StyledText>
            </Grid>
            <Grid item>
              <Controller
                as={
                  <TextField
                    label={tr('_email')}
                    placeholder={tr('_email')}
                    type="text"
                    fullWidth
                  ></TextField>
                }
                name="email"
                control={control}
              />
            </Grid>
            <Grid item>
              <Controller
                as={
                  <TextField
                    label={tr('_password')}
                    placeholder={tr('_password')}
                    type="password"
                    fullWidth
                    error={Boolean(loginError)}
                    helperText={loginError ? loginError : ''}
                  ></TextField>
                }
                name="password"
                control={control}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                style={{
                  backgroundColor: theme.colors.button,
                  color: theme.colors.highlight,
                }}
              >
                <StyledText variant="button" color="highlight">
                  {tr('_log_in')}
                </StyledText>
              </Button>
            </Grid>
          </Grid>
        </form>
        <Grid item>
          <Button
            style={{
              fontFamily: theme.fonts.default.regular,
            }}
            onClick={handleForgotPassword}
          >
            {tr('_forgot_password')}
          </Button>
        </Grid>
      </Paper>
    </div>
  )
}
