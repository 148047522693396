import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { StyledText } from './Elements'
import {
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withWidth,
} from '@material-ui/core'
import { PrepaidEditObject } from './EditModal'
import { getDealChangeLog } from '../actions/deal'
import { RootState } from '../reducers'

interface ChangeLogModalProps {
  onClose: () => void
  disableBackgroundScroll?: boolean
}

const overlayStyle: React.CSSProperties = {
  position: 'fixed',
  display: 'flex',
  left: 0,
  height: '100vh',
  width: '100vw',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  justifyContent: 'center',
  zIndex: 2,
}

const modalContentStyle: React.CSSProperties = {
  position: 'relative',
  maxHeight: '80%',
  height: 'fit-content',
  width: '60%',
  top: '1rem',
  backgroundColor: 'white',
  padding: '2rem',
  borderRadius: '5px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  overflow: 'auto',
}

const ChangeLogModal: React.FC<ChangeLogModalProps> = ({
  onClose,
  disableBackgroundScroll = false,
}) => {
  const { dealId } = useParams<{ dealId: string }>()
  const dispatch = useDispatch()
  const changeLog = useSelector((state: RootState) => state.deals.changeLog)
  const isFetching = useSelector((state: RootState) => state.deals.isFetching)

  useEffect(() => {
    dispatch(getDealChangeLog(Number(dealId)))

    if (disableBackgroundScroll) {
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  const formatDate = (updateTime: number): string => {
    const date = new Date(updateTime)
    const day = date.getDate().toString().padStart(2, '0')
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const format = `${day}/${month}/${year} - ${hours}:${minutes}`

    return format
  }

  return (
    <div style={overlayStyle}>
      <div style={modalContentStyle}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <StyledText variant="headline2">
            {`Royalty prepaid changelog for deal #${dealId}`}
          </StyledText>
          <Button onClick={onClose}>
            <StyledText
              variant="button"
              style={{
                cursor: 'pointer',
              }}
            >
              X
            </StyledText>
          </Button>
        </div>
        <StyledText variant="body2">
          {'Prepaids have a format of (old amount -> new amount)'}
        </StyledText>
        {isFetching && (
          <Grid item style={{ height: '100%' }}>
            <Grid container justify="center" alignContent="center">
              <CircularProgress
                color="primary"
                style={{
                  width: '100px',
                  height: '100px',
                  alignSelf: 'center',
                }}
              />
            </Grid>
          </Grid>
        )}
        {!isFetching && (
          <React.Fragment>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Prepaids #</TableCell>
                  <TableCell>Comment</TableCell>
                  <TableCell>Invoice number</TableCell>
                  <TableCell>Updated by</TableCell>
                  <TableCell>Updated at</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {changeLog &&
                  changeLog.map((item, index) => {
                    return (
                      <TableRow key={`change-log item-${index}`}>
                        <TableCell>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              gap: '0.5rem',
                              fontWeight: 'bold',
                            }}
                          >
                            <span color="red">
                              {item.prevPrepaidRoyalties || 0}
                            </span>
                            <span>&rarr;</span>
                            <span color="green">
                              {item.newPrepaidRoyalties || 0}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>{item.comment}</TableCell>
                        <TableCell>
                          {item.invoiceNumber || 'No invoice number specified'}
                        </TableCell>
                        <TableCell>
                          {item.updatedBy || 'Administrator'}
                        </TableCell>
                        <TableCell>{formatDate(item.updatedAt)}</TableCell>
                      </TableRow>
                    )
                  })}
                {!changeLog.length && (
                  <StyledText
                    variant="body"
                    style={{
                      marginTop: '1rem',
                    }}
                  >
                    No changelog items exist yet!
                  </StyledText>
                )}
              </TableBody>
            </Table>
            <div
              style={{
                display: 'flex',
                flex: 1,
                gap: '1rem',
                marginTop: '1rem',
              }}
            >
              <Button
                style={{
                  margin: '0',
                }}
                onClick={onClose}
              >
                <StyledText variant="button">Close</StyledText>
              </Button>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default ChangeLogModal
