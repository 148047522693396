export const RoyaltyTypeMap = {
  0: 'Wirepas Massive 2.4Ghz',
  1: 'Wirepas Positioning Engine Edge',
}
export const RoyaltyTypes = Object.keys(RoyaltyTypeMap)
function buildDate(date: any) {
  if (!date) {
    return ''
  }
  try {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }
  } catch (err) {
    return ''
  }
  return date
}
function padDate(dateNumber: number) {
  if (dateNumber < 10) {
    return '0' + dateNumber
  } else {
    return '' + dateNumber
  }
}
export function formatDate(dateRep: any) {
  const date = buildDate(dateRep)
  if (!date || (date instanceof Date && isNaN(date.getTime()))) {
    return ''
  }
  const d = new Date(date)
  console.log(
    `${d.getUTCFullYear()}-${padDate(d.getUTCMonth() + 1)}-${padDate(
      d.getUTCDate()
    )} ${d.getUTCHours()}:${padDate(d.getUTCMinutes())} UTC`
  )
  return `${d.getUTCFullYear()}-${padDate(d.getUTCMonth() + 1)}-${padDate(
    d.getUTCDate()
  )} ${d.getUTCHours()}:${padDate(d.getUTCMinutes())} UTC`
}
// From https://stackoverflow.com/questions/5223/length-of-a-javascript-object
export function objectSize(obj: any) {
  var size = 0,
    key
  for (key in obj) {
    if (obj.hasOwnProperty(key)) size++
  }
  return size
}
