import axios from 'axios'
import { Dispatch } from 'redux'
import { RootState } from '../reducers'
import * as request from './../lib/request'
export const REQUESTING_SETTINGS = 'REQUEST_SETTINGS'
export const SETTINGS_RECEIVED = 'SETTINGS_RECEIVED'
export const SETTINGS_ERROR = 'SETTINGS_ERROR'
interface RequestingSettingsAction {
  type: typeof REQUESTING_SETTINGS
}
interface SettingsReceivedAction {
  type: typeof SETTINGS_RECEIVED
  settings: {
    supportContactEmail: string
    supportContactPhone: string
  }
}
interface SettingsErrorAction {
  type: typeof SETTINGS_ERROR
  error: string
}
export type SettingsActionTypes =
  | RequestingSettingsAction
  | SettingsReceivedAction
  | SettingsErrorAction
export const fetchSettings =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({ type: REQUESTING_SETTINGS })
    try {
      const token = getState().auth.token
      const res: any = await request.get('/api/settings', token)
      if (!res) {
        throw Error('Could not fetch settings')
      }
      const { success, data, error } = res
      if (!success) {
        throw Error(error)
      }
      dispatch({ type: SETTINGS_RECEIVED, settings: data })
    } catch (err) {
      dispatch({ type: SETTINGS_ERROR, error: err.message })
    }
  }
